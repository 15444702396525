/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./Certificate.css";
import user from "../../../../assests/User.svg";
import dropDown from "../../../../assests/arrowDown.svg";
import edit from "../../../../assests/edit.svg";
import dropUp from "../../../../assests/arrowUp.svg";
import star from "../../../../assests/star.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import plus from "../../../../assests/plus.svg";
import gallery from "../../../../assests/gallery.svg";
import trash from "../../../../assests/trash-2.svg";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import { FiAward } from "react-icons/fi";
import Select from "react-select";
import Skilllist from "../../../../assests/skillsJSON.json";
import { RxCross1 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoAlertCircle } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import moment from "moment";
import { firebase } from "../../../../database/firebase";

const Certificate = () => {
  const userdata = useSelector((store) => store.userdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);

  const dispatch = useDispatch();
  const [isArrow, setIsArrow] = useState(false);
  const dropDownhandler = () => {
    setIsArrow(!isArrow);
  };

  const [isUpload, setIsUpload] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [addcertificate, setaddcertificate] = useState(true);

  const displayHandler = async () => {
    if (addcertificate == true) {
      if (travelwork.length !== 0) {
        setloading(true);
        var alldata = [];
        for (var i = 0; i < travelwork.length; i++) {
          if (travelwork[i].type === "new") {
            var arrayOf_Values = [];
            if (travelwork[i].skills.length !== 0) {
              arrayOf_Values = travelwork[i].skills.map((obj) => obj.value);
            }
            var newobj = {
              username: userdata[0].username,
              certificate_info: {
                course_name: travelwork[i].course_name,
                date_issued: travelwork[i].date_issued,
                validity_date: travelwork[i].validity_date,
                description: travelwork[i].description,
                url: travelwork[i].url,
                skills: arrayOf_Values,
                certificate_file: travelwork[i].certificate_file,
                issuing_body: travelwork[i].issuing_body,
              },
            };

            var updatedata = await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/getCertifications/${userid}/`,
                newobj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
            if (
              updatedata.message ===
              "User and Associated Info updated successfully"
            ) {
              alldata.push(updatedata.certificate_info);
            }
          } else {
            var arrayOfValues = [];
            if (travelwork[i].skills.length !== 0) {
              arrayOfValues = travelwork[i].skills.map((obj) => obj.value);
            }
            var new_obj = {
              username: userdata[0].username,
              certificate_info: {
                course_name: travelwork[i].course_name,
                date_issued: travelwork[i].date_issued,
                validity_date: travelwork[i].validity_date,
                description: travelwork[i].description,
                url: travelwork[i].url,
                skills: arrayOfValues,
                certificate_file: travelwork[i].certificate_file,
                issuing_body: travelwork[i].issuing_body,
              },
            };
            alldata.push({
              course_name: travelwork[i].course_name,
              date_issued: travelwork[i].date_issued,
              validity_date: travelwork[i].validity_date,
              description: travelwork[i].description,
              url: travelwork[i].url,
              skills: arrayOfValues,
              certificate_file: travelwork[i].certificate_file,
              id: travelwork[i].id,
              issuing_body: travelwork[i].issuing_body,
            });
            await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/getCertifications/${travelwork[i].id}/`,
                new_obj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          }
        }
        getuserdata();
      }
    } else {
      setloading(true);
      var newobj = {
        username: userdata[0].username,
        no_certificate: true,
      };
      var updatedata = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
          newobj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (
        updatedata.message === "User and Associated Info updated successfully"
      ) {
        let updatedObject = {
          ...userdata[0],
          no_certificate: true,
        };
        setloading(false);
        dispatch(storeAction.userdataHander({ userdata: [] }));
        setTimeout(() => {
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        }, 2000);
        getuserdata();
      }
    }
  };

  const getuserdata = async () => {
    var userinfo = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (userinfo !== null) {
      let updatedObject = {
        ...userdata[0],
        certificate_info: userinfo.certificate_info,
        no_certificate: true,
      };
      seteducation_data(userinfo.certificate_info);
      dispatch(storeAction.isPopUpHander());
      setloading(false);
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        getUserinfo();
      }, 2000);
    }
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overLayHandler = () => {
    dispatch(storeAction.isPopUpHander("certificate"));
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [loading, setloading] = useState(false);
  const [education_data, seteducation_data] = useState([]);
  const [travelwork, settravelwork] = useState([
    {
      course_name: "",
      date_issued: "",
      validity_date: "",
      description: "",
      url: "",
      skills: [],
      type: "new",
      certificate_file: [],
      issuing_body: "",
    },
  ]);

  const addcountwork = () => {
    var newobj = {
      course_name: "",
      date_issued: "",
      validity_date: "",
      description: "",
      url: "",
      skills: [],
      type: "new",
      certificate_file: [],
      issuing_body: "",
    };
    settravelwork((prevState) => [...prevState, newobj]);
  };

  const handlechangework = (value, index, name) => {
    travelwork[index][name] = value;
    settravelwork([...travelwork]);
  };

  useEffect(() => {
    getUserinfo();
  }, [userdata.length !== 0]);

  const getUserinfo = async () => {
    if (userdata.length !== 0) {
      var certificatedata = userdata[0].certificate_info;
      if (certificatedata.length !== 0) {
        seteducation_data(certificatedata);
        var filterdata = [];
        for (var i = 0; i < certificatedata.length; i++) {
          const arrayOfObjects = certificatedata[i].skills.map((value) => ({
            value,
            label: value,
          }));
          filterdata.push({
            course_name: certificatedata[i].course_name,
            date_issued: certificatedata[i].date_issued,
            validity_date: certificatedata[i].validity_date,
            description: certificatedata[i].description,
            url: certificatedata[i].url,
            skills: arrayOfObjects,
            type: "edit",
            certificate_file: certificatedata[i].certificate_file,
            id: certificatedata[i].id,
            issuing_body: certificatedata[i].issuing_body,
          });
        }
        settravelwork(filterdata);
      }
      setaddcertificate(userdata[0].no_certificate == true ? false : true);
    }

    var skillarrray = Skilllist;
    const uniqueSkills = Array.from(
      new Set(skillarrray.map((skill) => skill.Skill))
    );
    if (uniqueSkills.length !== 0) {
      var filter1 = [];
      for (var i = 0; i < uniqueSkills.length; i++) {
        filter1.push({
          value: uniqueSkills[i],
          label: uniqueSkills[i],
        });
      }
      setskilloption(filter1);
    }
  };
  const fileInputRef = useRef(null);
  const uploadHandler = (index) => {
    fileInputRef.current.click();
    setindex(index);
  };
  const [index, setindex] = useState(null);
  const [formData] = useState(new FormData());

  const handleFileInputChange = async (e) => {
    formData.append("image", e.target.files[0]);
    const selectedImage = e.target.files[0];
    if (selectedImage.size > 5 * 1024 * 1024) {
      fileInputRef.current.value = "";
      alert("Image size exceeds 5 MB limit.");
    } else {
      formData.append("name", `certificate${userid}`);
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase
          .storage()
          .ref(`certificate${userid}` + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl_new2 = await file13;
      const updatedTravelwork = [...travelwork];
      const updatedObj = {
        ...updatedTravelwork[index],
        certificate_file: [
          ...updatedTravelwork[index].certificate_file,
          imgurl_new2,
        ],
      };
      updatedTravelwork[index] = updatedObj;
      settravelwork(updatedTravelwork);
      fileInputRef.current.value = "";
      setIsUpload(true);
    }
  };

  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [skill_list, setskill_list] = useState([]);

  useEffect(() => {
    getLocationdata();
  }, [selectedOptionskill]);

  const getLocationdata = async () => {
    if (selectedOptionskill !== null) {
      if (selectedOptionskill.length > 5) {
        setSelectedOptionskill(null);
        // setTimeout(() => {
        //   setSelectedOptionskill(selectedOptionskill.slice(0, 5));
        // }, 10);
      } else {
        if (selectedOptionskill !== null) {
          const values_Array = selectedOptionskill.map(
            (country) => country.value
          );
          setskill_list(values_Array);
        }
      }
    }
  };
  const handleSelectChange = (index, selectedOptions) => {
    if (selectedOptions.length <= 5) {
      travelwork[index]["skills"] = selectedOptions;
      settravelwork([...travelwork]);
    }
  };
  const [isComplete, setIsComplete] = useState(false);
  const completeHandler = () => {
    setIsComplete(true);
  };
  const completeHandlerLeave = () => {
    setIsComplete(false);
  };
  const deletebtn = async (outerIndex, innerIndex) => {
    const updatedTravelwork = travelwork.map((item, idx) => {
      if (idx === outerIndex) {
        return {
          ...item,
          certificate_file: item.certificate_file.filter(
            (_, fileIdx) => fileIdx !== innerIndex
          ),
        };
      }
      return item; // Return unchanged items
    });

    settravelwork(updatedTravelwork);
  };
  return (
    <div>
      <div className="certificate">
        <div className="innerCertificate">
          <div
            className={isArrow === true ? "certificateHead" : "bottomBorder"}
          >
            <div className="certificateHeadLeft">
              <span>
                <FiAward />
              </span>
              <h1>Professional Certificate</h1>
            </div>
            <div className="certificateLeftIcon">
              {education_data.length !== 0 ||
              (userdata.length !== 0 && userdata[0].no_certificate == true) ? (
                <IoIosCheckmarkCircle className="profileCompleteIcon" />
              ) : (
                <>
                  <IoAlertCircle
                    onMouseEnter={completeHandler}
                    onMouseLeave={completeHandlerLeave}
                    className="warningIcon"
                  />
                  {isComplete == true && (
                    <div className="InCompleteIndication">
                      <p>Complete Certificate</p>
                    </div>
                  )}
                </>
              )}
              {userdata.length !== 0 ? (
                userdata[0].nottify == false ? (
                  <img
                    className="certificateLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                ) : (
                  <img
                    className="certificateLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                )
              ) : null}

              {isArrow === true ? (
                <img onClick={dropDownhandler} src={dropUp} alt="" />
              ) : (
                <img onClick={dropDownhandler} src={dropDown} alt="" />
              )}
            </div>
          </div>
          {isArrow === true &&
            (education_data.length !== 0 ? (
              <div className="certificateDesc">
                <h1>
                  Add Professional Certificate / Course Details here to enhance
                  your profile
                </h1>
                <div key={index}>
                  {education_data.map((data, index) => (
                    <div className="innerCertificateDesc" key={index}>
                      <h2 className="coursename">{data.course_name}</h2>
                      <div className="certificateDescFlex">
                        <h3>Date Issued:</h3>
                        <p>{moment(data.date_issued).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="certificateDescFlex">
                        <h3>Validity Issued:</h3>
                        <p>{moment(data.validity_date).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="certificateDescFlex">
                        <h3>Issuing Body:</h3>
                        <p>{data.issuing_body}</p>
                      </div>

                      {/* <div className="certificateDescFlex">
                        <h3>Certificate Url (Optional)</h3>
                        {data.url.length !== 0 ? (
                          <p
                            onClick={() => {
                              window.open(`${data.url}`, "_blank");
                            }}
                            className="pointer"
                          >
                            {data.url}
                          </p>
                        ) : (
                          <p>Not Provided</p>
                        )}
                      </div> */}
                      <div className="certificateDescFlexLast">
                        <h4>Key Skills:</h4>
                        <p>{data.skills.join(", ")}</p>
                      </div>
                      <p>{data.description}</p>
                      {data.certificate_file.length !== 0
                        ? data.certificate_file.map((data, index) =>
                            data.length !== 0 ? (
                              <div
                                className="gradeCertificate"
                                onClick={() => {
                                  window.open(`${data}`, "_blank");
                                }}
                                key={index}
                              >
                                <ImAttachment className="attachicon text-4xl" />
                                <div className="gradeCertificateDesc">
                                  <h2>Certificate {index + 1}</h2>
                                </div>
                              </div>
                            ) : null
                          )
                        : null}
                    </div>
                  ))}

                  <div className="flex gap-4"></div>
                </div>
              </div>
            ) : (
              <div className="educationDesc">
                <h1>
                  Add Professional Certificate / Course Details here to enhance
                  your profile
                </h1>
                <button className="touchButtonnew" onClick={overLayHandler}>
                  <h4>Add Professional Certificate</h4>
                </button>
              </div>
            ))}
          {isPopUp === "certificate" && (
            <div
              className={
                addcertificate == true
                  ? "certificateDescOverlay"
                  : "certificateDescOverlay5"
              }
            >
              <div className="innerCertificateOverlay">
                <div
                  className={
                    isArrow === true ? "certificateHead" : "bottomBorder"
                  }
                >
                  <div className="certificateHeadLeft">
                    <img src={user} alt="" />
                    <h1>Professional Certificate</h1>
                  </div>
                  <div
                    onClick={exitOverlayHandler}
                    className="certificateLeftIcon"
                  >
                    <RxCross1 />
                  </div>
                </div>
              </div>
              <div className="outerCertificate">
                {/* <div className="certificateDescUpload">
                  <div className="certificateDescUploadDesc">
                    <h1>Add your personality assessment test result</h1>
                    <div className="uploadCertificate">
                      <h2 className="drop">
                        Drag your fies here to{" "}
                        <span className="browser">Browse</span>
                      </h2>
                      <h3>
                        Maximum size: 5MB MP4,
                        <br /> MOV, AVI and WMV accepted
                      </h3>
                    </div>
                    <div className="vedioNotes">
                      <img src={star} alt="" />
                      <div className="notes">
                        <h4>
                          If you don’t have a personality assessment
                          certificate, you can take one here at{" "}
                          <span className="certificateHighLight">Mettl</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="addCertification">
                <div className="flex justify-between items-center">
                  <h6>
                    Add Professional Certificate / Course Details here to
                    enhance your profile
                  </h6>
                  <div className="flex items-center gap-1 w-[12rem]">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setaddcertificate(!addcertificate);
                      }}
                      checked={addcertificate == false}
                      defaultValue={addcertificate}
                    />
                    <h5 className="w-full text-sm text-blue-800 font-medium cursor-pointer">
                      Certifications Not Available
                    </h5>
                  </div>
                </div>
                {addcertificate == true ? (
                  <>
                    {" "}
                    {travelwork.length !== 0
                      ? travelwork.map((data, index) => (
                          <>
                            <div className="certificateDescOverlayFlex">
                              <div className="certificateDescOverlayFlexLeft">
                                <h2>Course name</h2>
                                <input
                                  type="text"
                                  name="course_name"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "course_name"
                                    );
                                  }}
                                  defaultValue={data.course_name}
                                />
                                <h2>Issuing body</h2>
                                <input
                                  type="text"
                                  name="issuing_body"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "issuing_body"
                                    );
                                  }}
                                  defaultValue={data.issuing_body}
                                />
                                {/* <h2>Certificate URL</h2>
                                <input
                                  type="text"
                                  name="url"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "url"
                                    );
                                  }}
                                  defaultValue={data.url}
                                /> */}
                                <h2>Date Issued</h2>
                                <input
                                  type="date"
                                  min="1000-01-01"
                                  max="9999-12-31"
                                  name="date_issued"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "date_issued"
                                    );
                                  }}
                                  defaultValue={data.date_issued}
                                />
                              </div>
                              <div className="certificateDescOverlayFlexRight">
                                <div className="textDesc">
                                  <h2>Valitity Date</h2>
                                  <div className="flex items-center gap-1 w-[8rem]">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        handlechangework(
                                          data.validity_date == "Life Time"
                                            ? ""
                                            : "Life Time",
                                          index,
                                          "validity_date"
                                        );
                                      }}
                                      checked={
                                        data.validity_date == "Life Time"
                                      }
                                      defaultValue={data.validity_date}
                                    />
                                    <p className="w-full text-xs">Life Time</p>
                                  </div>
                                </div>
                                <input
                                  type="date"
                                  name="validity_date"
                                  min="1000-01-01"
                                  max="9999-12-31"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "validity_date"
                                    );
                                  }}
                                  disabled={data.validity_date == "Life Time"}
                                  defaultValue={data.validity_date}
                                />
                                <h2>Skills</h2>
                                <Select
                                  value={data.skills}
                                  options={skilloption}
                                  isMulti
                                  onChange={(selectedOption) =>
                                    handleSelectChange(index, selectedOption)
                                  }
                                />
                                <h2>Description</h2>
                                <input
                                  type="text"
                                  name="description"
                                  onChange={(e) => {
                                    handlechangework(
                                      e.target.value,
                                      index,
                                      "description"
                                    );
                                  }}
                                  defaultValue={data.description}
                                />
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                uploadHandler(index);
                              }}
                              className="uploadCertificate"
                            >
                              <h2 className="drop">
                                Drag your fies here to{" "}
                                <span className="browser">Browse</span>
                              </h2>
                              <h3>
                                Maximum size: 5MB,
                                <br /> PDF, JPEG and PNG accepted
                              </h3>
                            </div>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              name="aadhaarfront"
                              onChange={handleFileInputChange}
                            />
                            {data.certificate_file.length !== 0
                              ? data.certificate_file.map(
                                  (data, innerIndex) => (
                                    <div
                                      className="educationUploaded"
                                      key={innerIndex}
                                    >
                                      <div className="educationUploadedFlex">
                                        <div
                                          className="educationUploadedFlexLeft"
                                          onClick={() => {
                                            window.open(`${data}`, "_blank");
                                          }}
                                        >
                                          <img src={gallery} alt="" />
                                          <div className="educationUploadedFlexLeftDesc">
                                            <h2>
                                              certificate {innerIndex + 1}
                                            </h2>
                                          </div>
                                        </div>
                                        <div
                                          className="educationUploadedFlexRight"
                                          onClick={() => {
                                            deletebtn(index, innerIndex);
                                          }}
                                        >
                                          <img src={trash} alt="" />
                                        </div>
                                      </div>
                                      <div className="percent">
                                        <div className="range">
                                          <div className="InnerRange"></div>
                                        </div>
                                        <h2>100%</h2>
                                      </div>
                                    </div>
                                  )
                                )
                              : null}
                          </>
                        ))
                      : null}
                  </>
                ) : null}
              </div>
              {addcertificate == true ? (
                <div className="Add_More">
                  <button onClick={addcountwork}>
                    <img src={plus} alt="" />
                    <h3>ADD MORE CERTIFICATE DETAILS</h3>
                  </button>
                </div>
              ) : null}

              <div className="vedioResumeButtons">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Discard Changes
                </button>
                {loading === false ? (
                  <button className="save" onClick={displayHandler}>
                    Save & Close
                  </button>
                ) : (
                  <button className="save w-[10rem] flex justify-center items-center">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certificate;

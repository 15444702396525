/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "./Pagination";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { MdMarkEmailRead } from "react-icons/md";

const NewClientFlowtable = () => {
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const newclient = useSelector((store) => store.newclient);
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [recordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [selectid, setselectid] = useState(null);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [loadingnew2, setloadingnew2] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = totaldata.filter((skill) => {
        return skill.first_name.toLowerCase().includes(e.toLowerCase());
      });
      setalldata(matchingSkills);
      setCurrentPage(1);
    } else {
      setalldata(totaldata);
    }
  };

  const statusOrder = {
    "New Client": 1,
    "Mail send": 2,
    Success: 3,
    Reject: 4,
  };

  const sortClient = (a, b) => {
    const getStatusRank = (candidate) => {
      return statusOrder[candidate.status] || 6;
    };

    const rankA = getStatusRank(a);
    const rankB = getStatusRank(b);

    return rankA - rankB;
  };
  useEffect(() => {
    Getcandidate();
  }, []);
  const Getcandidate = async () => {
    if (newclient.length !== 0) {
      setloading(false);
      setIsSearchEnabled(true);
      setalldata(newclient);
      settotaldata(newclient);
      var allfacility = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility.companies !== undefined) {
        if (allfacility.companies.length !== 0) {
          var checkdata = await allfacility.companies.filter((data) => {
            return (
              data.status == "New Client" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send"
            );
          });
          checkdata.sort(sortClient);
          setloading(false);
          setalldata(checkdata);
          settotaldata(checkdata);
          dispatch(storeAction.newclientHander({ newclient: checkdata }));
          setIsSearchEnabled(false);
        }
      } else {
        setloading(false);
        setIsSearchEnabled(false);
      }
    } else {
      setloading(true);
      setIsSearchEnabled(true);
      var allfacility1 = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility1.companies !== undefined) {
        if (allfacility1.companies.length !== 0) {
          var checkdata1 = await allfacility1.companies.filter((data) => {
            return (
              data.status == "New Client" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send"
            );
          });
          checkdata1.sort(sortClient);
          setloading(false);
          setalldata(checkdata1);
          settotaldata(checkdata1);
          dispatch(storeAction.newclientHander({ newclient: checkdata1 }));
          setIsSearchEnabled(false);
        }
      } else {
        setloading(false);
        setIsSearchEnabled(false);
      }
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = alldata.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(alldata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const acceptbtn = async (data) => {
    setloadingnew(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Action Required: Complete your Onboarding Today!",
      message: `
      <p>Dear ${data.first_name},</p>
      <p>We are delighted to process your onboarding to Hirein5. Please click on the link below to move forward.</p>
      <p><a href="https://app.hirein5.com/#/clientpassword/${data.email}">Complete Your Onboarding</a></p>
      <p>During this process, please contact <a href="mailto:gohire@hirein5.com">gohire@hirein5.com</a> should you require any assistance.<p>
      <p><b>Team Hirein5</b></p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Client" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send"
          );
        });
        checkdata1.sort(sortClient);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newclientHander({ newclient: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const acceptbtn1 = async (data) => {
    setloadingnew2(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Action Required: Complete your Onboarding Today!",
      message: `
      <p>Dear ${data.first_name},</p>
      <p>We are delighted to process your onboarding to Hirein5. Please click on the link below to move forward.</p>
      <p><a href="https://app.hirein5.com/#/clientpassword/${data.email}">Complete Your Onboarding</a></p>
      <p>During this process, please contact <a href="mailto:gohire@hirein5.com">gohire@hirein5.com</a> should you require any assistance.<p>
      <p><b>Team Hirein5</b></p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Client" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send"
          );
        });
        checkdata1.sort(sortClient);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newclientHander({ newclient: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const rejectbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Application Update!",
      message: `<p>Hi ${data.first_name},</p>
                <p>Thank you for your interest in Hirein5.</p>
                <p>We regret to inform you that we are unable to process your application at this stage. We shall be in touch for other openings.</p>
                <p>All the best,</p>
                <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  const exportbtn = async () => {
    const manipulatedData = alldata.map((item) => {
      if (item.contact_preference_info !== null) {
        return {
          // S_No: index + 1,
          Name: item.first_name,
          Company_name: item.company !== null ? item.company.company_name : "",
          Location:
            item.requirements_info !== null
              ? item.requirements_info.location
              : "",
          Email: item.username,
          Phone_Number: item.phone,
          Skills:
            item.requirements_info !== null
              ? item.requirements_info.skills.length !== 0
                ? item.requirements_info.skills.join(", ")
                : ""
              : "",
          Date: item.contact_preference_info.date,
          Time: item.contact_preference_info.time,
          Time_Zone: item.contact_preference_info.time_zone,
          Prefered_Mode:
            item.contact_preference_info.prefered_mode.length !== 0
              ? item.contact_preference_info.prefered_mode.join(", ")
              : "",
        };
      }
    });
    var finaldata = manipulatedData.filter((data) => {
      return data !== undefined;
    });
    const worksheet = XLSX.utils.json_to_sheet(finaldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Onboarding_client.xlsx");
  };
  return (
    <div className="adminProfile paddingLeft50 paddingRight50">
      <DashHead
        head="Onboarding Client"
        desc="View and update profiles of onboarded Client"
        descClass="dashBoardMainHeadDescBetween"
      />
      <button className="exportntn" onClick={exportbtn}>
        Export
      </button>
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder={isSearchEnabled ? "Please wait loading data..." : "Search..."}
            onChange={(e) => searchvalue(e.target.value)}
            disabled={isSearchEnabled}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>Client Name</th>
                <th>Company Name</th>
                <th>Location</th>
                <th>Hiring Skills</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Status</th>
                {/* <th>Resume</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        {data.company !== null ? (
                          data.company.company_name.length !== 0 ? (
                            <h1>{data.company.company_name}</h1>
                          ) : (
                            <h1>-</h1>
                          )
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.requirements_info !== null ? (
                          <h1>{data.requirements_info.location}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.requirements_info !== null ? (
                          <h1>{data.requirements_info.skills.join(", ")}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>
                      <td>
                        {data.status == "Success" ? (
                          <p className="hiringActive px-2 rounded-full">
                            In Process
                          </p>
                        ) : data.status == "Reject" ? (
                          <p className="rejectstaus px-2 rounded-full">
                            On Hold
                          </p>
                        ) : data.status == "New Client" ? (
                          <p className="contracted px-2 rounded-full">New</p>
                        ) : data.status == "Mail send" ? (
                          <p className="processingstaus px-2 rounded-full">
                            Reviewed
                          </p>
                        ) : (
                          <p className="hiringActive px-2 rounded-full">-</p>
                        )}
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {data.status == "Success" ||
                          data.status == "Mail send" ? null : loadingnew ==
                            false ? (
                            <button
                              className="acceptbtn"
                              onClick={() => {
                                acceptbtn(data);
                              }}
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            selectid == data.id && (
                              <button className="acceptbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {loadingnew1 === false ? (
                            <>
                              <button
                                className="rejectbtn"
                                onClick={() => {
                                  rejectbtn(data);
                                }}
                              >
                                X
                              </button>

                              {loadingnew2 == true && selectid == data.id ? (
                                <button className="accept_btn">
                                  <FiLoader className="loadingIcon" />
                                </button>
                              ) : data.status == "New Client" ? null : (
                                <button
                                  className="accept_btn"
                                  onClick={() => {
                                    acceptbtn1(data);
                                  }}
                                >
                                  <MdMarkEmailRead />
                                </button>
                              )}
                            </>
                          ) : (
                            selectid == data.id && (
                              <button className="rejectbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewClientFlowtable;

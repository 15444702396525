/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./CandidateProfile.css";
import Education from "../Education/Education";
import Certificate from "../Certifications/Certificate";
import PersonalDetails from "../PersonalDetails/PersonalDetails";
import ProfessionalDetails from "../ProfessionalDetails/ProfessionalDetails";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import VideoResume from "../VideoResume/VideoResume";
import WorkExperience from "../WorkExperience/WorkExperience";
import DashHead from "../../../Reusable/DashBoardReusable/DashHead/DashHead";
import recEye from "../../../../assests/RecEye.svg";
import TravelHistory from "../../../Candidate/CandidateDashBoard/TravelHistory/TravelHistory";
import CandidateProfileCard from "../../../Reusable/CandidateProfileCard/CandidateProfileCard";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import file from "../../../../assests/file-text.png";
import { firebase } from "../../../../database/firebase";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import Brefimg from "../../../../assests/briefCase.png";
import SliderRange from "./Slider";
import moment from "moment";
import HorizontalNonLinearStepper from "./Stepper";

const CandidateProfile = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const [isPage, setIsPage] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const [erroritem, seterroritem] = useState({
    aadhar_front: false,
    aadhar_back: false,
    pan_front: false,
    passport_back: false,
    passport_front: false,
    resume: false,
    videoresume: false,
  });
  const [fileuploadsuccess, setfileuploadsuccess] = useState({
    aadhar_front: false,
    aadhar_back: false,
    pan_front: false,
    passport_front: false,
    passport_back: false,
    resume: false,
  });
  const [fileuploaderror, setfileuploaderror] = useState({
    aadhar_front: false,
    aadhar_back: false,
    pan_front: false,
    passport_front: false,
    passport_back: false,
    resume: false,
  });
  const [kycdata, setkycdata] = useState({
    aadhar_front: "",
    aadhar_back: "",
    pan_front: "",
    passport_front: "",
    passport_back: "",
    resume: "",
  });
  const [formtype, setformtype] = useState(null);
  const [formtypeloading, setformtypeloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setdays] = useState(0);
  const [loading, setloading] = useState(false);

  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const pageHandler = (event) => {
    setIsPage(!isPage);
    dispatch(storeAction.singleuserHander({ singleuser: userdata }));
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const isPopUp2 = useSelector((store) => {
    return store.isPopUp2;
  });
  useEffect(() => {
    GetPercentage();
  }, [userdata]);
  const GetPercentage = async () => {
    if (userdata.length !== 0) {
      var count = 0;
      if (userdata[0].address !== null) {
        count += 1;
      }
      if (userdata[0].work_preference_info !== null) {
        count += 1;
      }
      if (
        userdata[0].professional_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].project_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].certificate_info.length !== 0 ||
        userdata[0].no_certificate == true
      ) {
        count += 1;
      }
      if (userdata[0].travel_info !== null) {
        count += 1;
      }
      if (userdata[0].education_info.length !== 0) {
        count += 1;
      }
      if (userdata[0].video_resume !== null) {
        if (userdata[0].video_resume.length !== 0) {
          count += 1;
        }
      }
      let percent = Math.round((count / 8) * 100);
      setpercentage(percent);

      seterroritem({
        aadhar_front: false,
        aadhar_back: false,
        pan_front: false,
        passport_back: false,
        passport_front: false,
        resume: false,
      });
      const user = userdata[0];
      if (user.kyc_info) {
        const { aadhar_front, aadhar_back, pan_front } = user.kyc_info;
        if (aadhar_front.includes("firebasestorage.googleapis")) {
          seterroritem((values) => ({ ...values, aadhar_front: false }));
          setkycdata((values) => ({ ...values, aadhar_front }));
        } else {
          seterroritem((values) => ({ ...values, aadhar_front: true }));
        }
        if (aadhar_back.includes("firebasestorage.googleapis")) {
          seterroritem((values) => ({ ...values, aadhar_back: false }));
          setkycdata((values) => ({ ...values, aadhar_back }));
        } else {
          seterroritem((values) => ({ ...values, aadhar_back: true }));
        }
        if (pan_front.includes("firebasestorage.googleapis")) {
          seterroritem((values) => ({ ...values, pan_front: false }));
          setkycdata((values) => ({ ...values, pan_front }));
        } else {
          seterroritem((values) => ({ ...values, pan_front: true }));
        }
      }
      if (user.new_candidate_info) {
        const { resume } = user.new_candidate_info;
        if (resume.includes("firebasestorage.googleapis")) {
          seterroritem((values) => ({ ...values, resume: false }));
          setkycdata((values) => ({ ...values, resume }));
        } else {
          seterroritem((values) => ({ ...values, resume: true }));
        }
      }
      if (userdata.length && user.vendor.length && !user.video_resume.length) {
        seterroritem((values) => ({ ...values, videoresume: true }));
        setkycdata((values) => ({
          ...values,
          videoresume: user.video_resume,
        }));
      } else {
        seterroritem((values) => ({ ...values, videoresume: false }));
        setkycdata((values) => ({
          ...values,
          videoresume: user.video_resume,
        }));
      }
      const anyKycOrResumeError = [
        erroritem.aadhar_front,
        erroritem.aadhar_back,
        erroritem.pan_front,
        erroritem.resume,
        erroritem.videoresume,
      ].some((value) => value);
      if (anyKycOrResumeError) {
        if (user.passport_info) {
          const { passport_front, passport_back } = user.passport_info;
          if (passport_front.includes("firebasestorage.googleapis")) {
            seterroritem((values) => ({ ...values, passport_front: false }));
            setkycdata((values) => ({ ...values, passport_front }));
          } else {
            seterroritem((values) => ({ ...values, passport_front: true }));
          }
          if (passport_back.includes("firebasestorage.googleapis")) {
            seterroritem((values) => ({ ...values, passport_back: false }));
            setkycdata((values) => ({ ...values, passport_back }));
          } else {
            seterroritem((values) => ({ ...values, passport_back: true }));
          }
        }
      }
      if (userdata[0].nottify == true) {
        dispatch(storeAction.isPopUpHander2());
      } else {
        dispatch(storeAction.isPopUpHander2("ResumeparsingInfo"));
      }
    }
  };
  const [formData] = useState(new FormData());
  const handleFileInput_Change1 = async (e) => {
    setformtypeloading(true);
    // setfileuploadsuccess({
    //   aadhar_front: false,
    //   aadhar_back: false,
    //   pan_front: false,
    //   passport_front: false,
    //   passport_back: false,
    // });
    formData.append("image", e.target.files[0]);
    formData.append("name", `${formtype}_${userdata[0].id}`);
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`${formtype}_${userdata[0].id}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new = await file13;
    setkycdata((values) => ({
      ...values,
      [formtype]: imgurl_new,
    }));
    setfileuploadsuccess((values) => ({
      ...values,
      [formtype]: true,
    }));
    setfileuploaderror((values) => ({
      ...values,
      [formtype]: false,
    }));

    setformtypeloading(false);
    if (formtype == "resume") {
      fileInputRef1.current.value = "";
    } else {
      fileInputRef.current.value = "";
    }
  };
  const handleFileSelectClick = (data) => {
    fileInputRef.current.click();
    setformtype(data);
  };
  const handleFileSelectClick1 = (data) => {
    fileInputRef1.current.click();
    setformtype(data);
  };
  // useEffect(() => {
  //   Geterrordata();
  // }, [erroritem]);
  // const Geterrordata = async () => {
  //   const anyTrue = Object.values(erroritem).some((value) => value === true);
  //   if (anyTrue) {
  //     dispatch(storeAction.isPopUpHander("trigger"));
  //   } else {
  //     dispatch(storeAction.isPopUpHander());
  //   }
  // };
  const submitbtn = async () => {
    setfileuploaderror({
      aadhar_front: false,
      aadhar_back: false,
      pan_front: false,
      passport_front: false,
      passport_back: false,
      resume: false,
    });
    if (kycdata.aadhar_front.length == 0) {
      setfileuploaderror((values) => ({
        ...values,
        aadhar_front: true,
      }));
    } else if (kycdata.aadhar_back.length == 0) {
      setfileuploaderror((values) => ({
        ...values,
        aadhar_back: true,
      }));
    } else if (kycdata.pan_front.length == 0) {
      setfileuploaderror((values) => ({
        ...values,
        pan_front: true,
      }));
    } else if (kycdata.resume.length == 0) {
      setfileuploaderror((values) => ({
        ...values,
        resume: true,
      }));
    } else {
      setIsLoading(true);
      var obj = {
        username: userdata[0].username,
        kyc_info: {
          aadhar_back: kycdata.aadhar_back,
          aadhar_front: kycdata.aadhar_front,
          pan_front: kycdata.pan_front,
        },
        passport_info: {
          passport_back: kycdata.passport_back,
          passport_front: kycdata.passport_front,
        },
        new_candidate_info: {
          resume: kycdata.resume,
        },
        video_resume: kycdata.videoresume,
      };
      var updatedata = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (
        updatedata.message === "User and Associated Info updated successfully"
      ) {
        dispatch(storeAction.userdataHander({ userdata: [updatedata.user] }));
        setIsLoading(false);
        window.location.reload();
      } else {
        setIsLoading(false);
      }
    }
  };
  const noticebtn = () => {
    dispatch(storeAction.isPopUpHander("noticeperiod"));
  };
  const savebtn = async () => {
    setloading(true);
    var obj = {
      availability_from: moment()
        .add(days + 3, "days")
        .format("YYYY-MM-DD"),
      availability_status: false,
      username: userdata[0].username,
      notice_period: days,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...userdata[0],
        availability_from: moment()
          .add(days + 3, "days")
          .format("YYYY-MM-DD"),
        availability_status: false,
        notice_period: days,
      };
      setloading(false);
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }, 10);
      setdays(0);
      dispatch(storeAction.isPopUpHander());
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && isPopUp === "noticeperiod") {
        savebtn();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPopUp]);
  return (
    <div>
      <div className="profilePage paddingLeft100 paddingRight100">
        {userdata.length !== 0 ? (
          percentage == 100 ? (
            <DashHead
              left=""
              center=""
              right=""
              head="Profile"
              billingId="billing"
              upgradeId="upgrade"
              desc="This is the Candidate Profile Dashboard"
              highLight=""
              button={
                userdata[0].nottify !== undefined
                  ? userdata[0].nottify == false ||
                    userdata[0].nottify.length == 0
                    ? "Preview"
                    : isPage === false
                    ? "View as Recruiter"
                    : "View as Candidate"
                  : "View as Candidate"
              }
              button1="Update Notice period"
              btnImg={recEye}
              descClass="dashBoardMainHeadDesc"
              btnClass="dashBoardMainHeadDescButton"
              btnClass1="noticebtn"
              fun={pageHandler}
              fun1={noticebtn}
            />
          ) : (
            <DashHead
              left=""
              center=""
              right=""
              head="Profile"
              billingId="billing"
              upgradeId="upgrade"
              desc="This is the Candidate Profile Dashboard"
              highLight=""
              button1="Update Notice period"
              // btnImg={recEye}
              descClass="dashBoardMainHeadDesc"
              // btnClass="dashBoardMainHeadDescButton"
              btnClass1="noticebtn"
              // fun={pageHandler}
              fun1={noticebtn}
            />
          )
        ) : null}

        <HorizontalNonLinearStepper userdata={userdata} />
        <div className="candidateProgress pt-5">
          <div className="candidateProgressHead">
            {isPage === false ? (
              <h1>Profile is completed</h1>
            ) : (
              <h1>You are now ready to get Hired-in-5!</h1>
            )}

            <h2>{percentage}%</h2>
          </div>
          {/* <div className="candidateProgressBar">
            <div className={`candidateProgressBarBackgound w-[${percentage}%]`}></div>
          </div> */}
          <div className="progress-container">
            {isPage === false ? (
              <progress
                id="file"
                value={percentage}
                max="100"
                className="progress_bar1 bg-primaryColor"
              >
                {percentage}%
              </progress>
            ) : (
              <progress
                id="file"
                value={percentage}
                max="100"
                className="progress_bar bg-primaryColor"
              >
                {percentage}%
              </progress>
            )}
          </div>
        </div>
        {isPage === false && (
          <div className="">
            <PersonalDetails />
            <WorkExperience />
            {/* <Achievement /> */}
            <ProfessionalDetails />
            <ProjectDetails />
            <Certificate />
            <TravelHistory />
            <Education />
            <VideoResume />
            {userdata.length !== 0 ? (
              userdata[0].nottify == false ? (
                percentage == 100 ? (
                  <div className="vedioResumeButtons1">
                    <button
                      className="discard"
                      onClick={() => {
                        window.location.replace("/#/preview");
                      }}
                    >
                      Preview
                    </button>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        )}
        {isPage === true && (
          <CandidateProfileCard back="disNone" main="candidateProfile1" />
        )}
      </div>
      {/* {isPopUp == "trigger" && (
        <div className="TriggerContainer overlay">
          <div className="TriggerContainer1">
            {erroritem.aadhar_front && (
              <div className="adminEditOverlayContent">
                <h3>Govt. Issued ID</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "aadhar_front" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick("aadhar_front");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Govt. Issued ID</h4>
                    </div>
                  </div>
                  <p>Maximum size: 5MB. PDF, JPEG and PNG accepted</p>
                  {formtype == "aadhar_front" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="passport_back"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.aadhar_front && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Govt. Issued ID Uploaded Successfully
                    </h6>
                  )}
                  {fileuploaderror.aadhar_front && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please upload Govt. Issued ID
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.aadhar_back && (
              <div className="adminEditOverlayContent">
                <h3>Aadhaar Card Back</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "aadhar_back" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick("aadhar_back");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Aadhaar card back</h4>
                    </div>
                  </div>
                  <p>Maximum size: 5MB. PDF, JPEG and PNG accepted</p>
                  {formtype == "aadhar_back" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="passport_back"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.aadhar_back && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Aadhaar Card Back Uploaded Successfully
                    </h6>
                  )}
                  {fileuploaderror.aadhar_back && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please upload Aadhaar Card Back
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.pan_front && (
              <div className="adminEditOverlayContent">
                <h3>Pan Card</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "pan_front" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick("pan_front");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Pan Card</h4>
                    </div>
                  </div>
                  <p>Maximum size: 5MB. PDF, JPEG and PNG accepted</p>
                  {formtype == "pan_front" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".pdf,.jpeg,.jpg,.png"
                    name="passport_back"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.pan_front && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Pan Card Uploaded Successfully
                    </h6>
                  )}
                  {fileuploaderror.pan_front && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please upload Pan Card
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.passport_front && (
              <div className="adminEditOverlayContent">
                <h3>Passport Front (optional)</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "passport_front" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick("passport_front");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Passport Front </h4>
                    </div>
                  </div>
                  <p>Maximum size: 5MB. PDF, JPEG and PNG accepted</p>
                  {formtype == "passport_front" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="passport_back"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.passport_front && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Passport Front Uploaded Successfully
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.passport_back && (
              <div className="adminEditOverlayContent">
                <h3>Passport Back (optional)</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "passport_back" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick("passport_back");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Passport Back</h4>
                    </div>
                  </div>
                  <p>Maximum size: 5MB. PDF, JPEG and PNG accepted</p>
                  {formtype == "passport_back" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="passport_back"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.passport_back && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Passport Back Uploaded Successfully
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.resume && (
              <div className="adminEditOverlayContent">
                <h3>Resume</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "resume" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick1("resume");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Resume</h4>
                    </div>
                  </div>
                  <p>accepted formats: .pdf, .doc, .docx, .txt, .rtf</p>
                  {formtype == "resume" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload new file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef1}
                    style={{ display: "none" }}
                    name="passport_back"
                    accept=".pdf,.doc,.docx,.txt"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.resume && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Resume Uploaded Successfully
                    </h6>
                  )}
                  {fileuploaderror.resume && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please upload Resume
                    </h6>
                  )}
                </div>
              </div>
            )}
            {erroritem.videoresume && (
              <div className="adminEditOverlayContent">
                <h3>Video Resume</h3>
                <div
                  className="adminEditOverlayUpload backGround"
                  onClick={
                    formtype == "videoresume" && formtypeloading == true
                      ? null
                      : () => {
                          handleFileSelectClick1("videoresume");
                        }
                  }
                >
                  <div className="adminEditOverlayUploadHead">
                    <img src={file} alt="" />
                    <div className="adminEditOverlayUploadHeadRight">
                      <h4>Video Resume</h4>
                    </div>
                  </div>
                  <p>accepted formats: .mp4</p>
                  <p>Maximum size: 20 mb</p>
                  {formtype == "videoresume" && formtypeloading == true ? (
                    <button>loading...</button>
                  ) : (
                    <button>Upload file</button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef1}
                    style={{ display: "none" }}
                    name="videoresume"
                    accept=".mp4"
                    onChange={handleFileInput_Change1}
                  />
                  {fileuploadsuccess.videoresume && (
                    <h6 className="text-green-500 text-xs font-semibold mt-2">
                      Video Resume Uploaded Successfully
                    </h6>
                  )}
                  {fileuploaderror.resume && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please upload Video Resume
                    </h6>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="vedioResumeButtons2">
            <div className="flex items-center gap-2">
              <IoMdInformationCircleOutline className="text-red-500" />
              <h1 className="text-red-500 text-sm font-medium">
                Please re-upload the files
              </h1>
            </div>
            {isLoading === false ? (
              <button className="save mt-5 mb-5" onClick={submitbtn}>
                Submit
              </button>
            ) : (
              <button className="save mt-5 mb-5 flex gap-2">
                <span className="text-[#f4f4f5]">loading...</span>
                <FiLoader className="loadingIcon" />
              </button>
            )}
          </div>
        </div>
      )} */}
      {isPopUp == "noticeperiod" && (
        <div className="TriggerContainerNew overlay">
          <div className="workExperienceHead">
            <div className="workExperienceHeadLeft">
              <img src={Brefimg} alt="" />
              <h1>Notice Period (In Days)</h1>
            </div>
            <div
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
              className="workExperienceHeadLeftIcon"
            >
              <RxCross1 />
            </div>
          </div>
          <div className="professionalDetailsOverlay2new">
            <h6>Add your current organization's notice period.</h6>
            <h1 className="my-5">Duration of Notice Period</h1>
            <SliderRange setdays={setdays} />
            <p>
              These days will be counted from today, which is today plus the
              days you are providing here.
            </p>
            <div>
              {days == 0 ? (
                userdata.length !== 0 ? (
                  userdata[0].availability_from.length !== 0 ? (
                    <h1 className="text-center font-medium text-xl">
                      Availability From :{" "}
                      {moment(userdata[0].availability_from).format(
                        "DD-MM-YYYY"
                      )}
                    </h1>
                  ) : (
                    <h1 className="text-center text-red-500 font-medium text-xl">
                      Candidate did not update notice period
                    </h1>
                  )
                ) : null
              ) : (
                <h1 className="text-center font-medium text-xl">
                  Updated Date :{" "}
                  {moment()
                    .add(Number(days) + 3, "days")
                    .format("DD-MM-YYYY")}
                </h1>
              )}
            </div>
          </div>
          <div className="editOverlayButton mt-5">
            <button
              className="discard"
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
            >
              Discard Changes
            </button>

            {loading === false ? (
              <button className="save" onClick={savebtn}>
                Save & Close
              </button>
            ) : (
              <button className="save w-[10rem] flex justify-center items-center">
                <FiLoader className="loadingIcon" />
              </button>
            )}
          </div>
        </div>
      )}
      {isPopUp2 == "ResumeparsingInfo" && (
        <div className="TriggerContainerNew overlay">
          <div className="workExperienceHead">
            <div className="workExperienceHeadLeft">
              <img src={Brefimg} alt="" />
              <h1>Information</h1>
            </div>
            <div
              onClick={() => {
                dispatch(storeAction.isPopUpHander2());
              }}
              className="workExperienceHeadLeftIcon"
            >
              <RxCross1 />
            </div>
          </div>
          <div className="professionalDetailsOverlay2new">
            <p className="text-base font-medium">
              {/* Resume parsing may not be 100% accurate. Please review all fields and make necessary corrections. */}

              <span className="block">1. Upload your Video Resume</span>
              <span className="block">2. Complete your application 100%</span>
              <span className="block">3. Provide your Notice Period</span>
              <span className="block">
                4. Preview your application & Verify all the fields
              </span>
              <span className="block">5. Submit your Application</span>
            </p>

            <div className="flex justify-center items-center py-5">
              <button
                className="save"
                onClick={() => {
                  dispatch(storeAction.isPopUpHander2());
                }}
              >
                I understand
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateProfile;

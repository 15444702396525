/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./NewCandidateFlow.css";
import Head from "../Reusable/LogoHead/Head";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { firebase, auth } from "../../database/firebase";
import SuccessResponse from "../Reusable/SuccessResponse/SuccessResponse";
import axios from "axios";
import country_and_states from "../../assests/country-states-register";
import Skilllist from "../../assests/skillsJSON.json";
import Select from "react-select";
import { ImAttachment } from "react-icons/im";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countrycode from "../../assests/CountryCodes.json";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const NewCandidateFlow = () => {
  const navigate = useNavigate();
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const onboarding_status = useSelector((store) => store.onboarding_status);
  const token = useSelector((store) => store.token);
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIwMzg4MDM4LCJpYXQiOjE3MjAzNzAwMzgsImp0aSI6ImU0MGQyOTk5NTE5YTQ5NjJiOTJjNDViNTEyZGYwYzc0IiwidXNlcl9pZCI6NzAsImZpcnN0X25hbWUiOiJNIERpbmVzaGt1bWFyIiwiZW1haWwiOiJkaW5lc2hraXQxNUBnbWFpbC5jb20iLCJwaG9uZSI6IjgwNTY1MjE0NjEiLCJ0aXRsZSI6Im51bGwiLCJsaW5rZWRfaW4iOiIiLCJyb2xlIjoiMyIsIm9uYm9hcmRpbmdfc3RhdHVzIjoiNCJ9.KORsUfHLpYtLbmoRzJ4y2_i0dhF-VVMhvVMQ9l32eTY";

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerify, setemailVerify] = useState(false);
  const [phoneVerify, setphoneVerify] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [wrongotpcountemail, setwrongotpcountemail] = useState(0);
  const [wrongotpcountphone, setwrongotpcountphone] = useState(0);
  const [wrongotp, setwrongotp] = useState(false);
  const [cvuploaderror, setcvuploaderror] = useState(false);
  const [uploaderror, setuploaderror] = useState(false);
  const [uploadsuccess, setuploadsuccess] = useState(false);
  const [phone, setphone] = useState("");
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [notmatch, setnotmatch] = useState(false);

  const [registationdata, setregistationdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "",
    location: "",
    linkedin: "",
    password: "",
    cpassword: "",
  });
  const [registationdataerror, setregistationdataerror] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    country: false,
    location: false,
    linkedin: false,
    password: false,
    cpassword: false,
  });
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [errorstatus, seterrorstatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );

  const [final, setfinal] = useState(null);
  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [finalerror, setfinalerror] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newloading1, setnewloading1] = useState(false);
  const [newloading2, setnewloading2] = useState(false);
  const [newloading3, setnewloading3] = useState(false);
  const [bottomerror, setbottomerror] = useState(false);
  const [resenstatus, setresenstatus] = useState(false);
  const [buttonenable, setbuttonenable] = useState(false);
  const [cvfile, setcvfile] = useState("");
  const [validations, setValidations] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    newcapcha();
  }, [recaptchaId]);

  const newcapcha = () => {
    var newid = Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("");
    setTimeout(() => {
      setRecaptchaId(newid);
    }, 5000);
  };

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);
  const CheckStage = async () => {
    if (userdata.length != 0) {
      setemailVerify(
        userdata[0].email_verification == undefined
          ? false
          : userdata[0].email_verification
      );
      setphoneVerify(true);
      setregistationdata((values) => ({ ...values, email: userdata[0].email }));
      setregistationdata((values) => ({ ...values, phone: userdata[0].phone }));
      setupdateid(userdata[0].id);
      // dispatch(storeAction.tokenHandler({ token: userdata[0].access_token }));
    }
    if (onboarding_status == 2) {
      setIsPage("page2");
    }
  };

  const submitHandler = async (e) => {
    if (isPage == "page1") {
      setbottomerror(false);
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        linkedin: false,
        password: false,
        cpassword: false,
      });
      setnewloading3(false);
      setbottomerror(true);
      setnotmatch(false);
      var values_Array = [];
      if (selectedOptionskill !== null) {
        values_Array = selectedOptionskill.map((country) => country.value);
      }
      if (registationdata.first_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, first_name: true }));
      } else if (registationdata.last_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, last_name: true }));
      } else if (registationdata.email.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, email: true }));
      } else if (emailVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, emailVerify: true }));
      } else if (registationdata.phone.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phone: true }));
      } else if (phoneVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phoneVerify: true }));
      } else if (registationdata.password.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, password: true }));
      } else if (registationdata.cpassword.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, cpassword: true }));
      } else if (registationdata.password !== registationdata.cpassword) {
        setnotmatch(true);
        setbottomerror(true);
      } else if (values_Array.length == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, skill: true }));
      } else if (registationdata.location.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, location: true }));
      } else if (cvfile.length == 0) {
        setuploaderror(true);
      } else {
        setnewloading3(true);
        setbottomerror(false);
        setregistationdataerror({
          first_name: false,
          last_name: false,
          phone: false,
          email: false,
          country: false,
          location: false,
          linkedin: false,
          password: false,
          cpassword: false,
        });
        var notification = {
          email: "careers@hirein5.com",
          from_email: "connect@hirein5.com",
          subject: "Profile Submission for Review!",
          message: `<p>Candidate <b>${registationdata.first_name} ${
            registationdata.last_name
          }</b> from <b>${
            registationdata.location
          }</b> with <b>${values_Array.join(
            ", "
          )}</b> skills has submitted their profile for review.</p>
            <p>Please review the candidate's profile and proceed with the next steps as necessary.</p>
            <p>Best regards,<br>Team Hirein5</p>`,
        };

        var notification1 = {
          email: registationdata.email,
          from_email: "connect@hirein5.com",
          subject: "Welcome to Hirein5: Your Account is Ready!",
          message: `
            <p>Dear ${registationdata.first_name} ${registationdata.last_name},</p>

            <p>Congratulations! Your account has been successfully created and your password has been set up.</p>

            <p>You can now log in to your account to complete the next steps of your application process:</p>
            <p>
              <a href="https://app.hirein5.com/#/login" target="_blank" style="color: #007bff; text-decoration: none;">Log in to your account</a>
            </p>

            <p>Once you log in, please ensure you:</p>
            <ul>
              <li>Complete your profile with all the required information.</li>
              <li>Upload any necessary documents (if applicable).</li>
              <li>Review and submit your application.</li>
            </ul>

            <p>If you have any questions or need assistance, feel free to reach out to us at <a href="mailto:careers@hirein5.com" style="color: #007bff; text-decoration: none;">careers@hirein5.com</a>.</p>

            <p>We look forward to helping you take the next step in your career!</p>

            <p>Best regards,<br>Team Hirein5</p>`,
        };

        var new_obj = {
          username: registationdata.email,
          email: registationdata.email,
          first_name: `${registationdata.first_name} ${registationdata.last_name}`,
          onboarding_status: 4,
          password: registationdata.password,
          status: "Success",
          country_code: registationdata.country,
          new_candidate_info: {
            skills: values_Array,
            location: registationdata.location,
            linkedin: registationdata.linkedin,
            resume: cvfile,
          },
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
        };
        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            new_obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        var new_obj1 = {
          email: registationdata.email,
          new_password: registationdata.password,
        };
        await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/update/password/`,
            new_obj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          var new_1obj = {
            message: `
              <p>
                <strong>${registationdata.first_name} ${registationdata.last_name}</strong>
                has registered as a candidate from <strong>${registationdata.location}</strong>.
              </p>`,
            status: "false",
            on_type: "Candidate has onboarded",
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${updateid}/`,
              new_1obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          await Promise.all([
            axios.post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              notification,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            ),
            axios.post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              notification1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            ),
          ]);
          setnewloading3(false);
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: 2,
            })
          );
          let updatedObject = {
            ...userdata[0],
            onboarding_status: 2,
          };
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          setnewloading3(false);
          setIsPage("page2");
        } else {
          setnewloading3(false);
        }
        setTimeout(() => {
          setnewloading3(false);
        }, 5000);
      }
    } else {
      setIsPage(e.target.id);
      setnewloading3(false);
    }
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const verifyHandler = async (e) => {
    setregistationdataerror({
      first_name: false,
      last_name: false,
      phone: false,
      email: false,
      country: false,
      location: false,
      linkedin: false,
    });
    setInputValues(["", "", "", "", "", ""]);
    if (e == "mobileverification") {
      if (registationdata.phone.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          phone: true,
        }));
      } else {
        setnewloading1(true);
        setregistationdataerror((values) => ({
          ...values,
          phone: false,
        }));
        if (registationdata.phone.length !== 0) {
          if (registationdata.phone.length === 10) {
            const recaptchaId = `recaptcha-${Math.random()
              .toString(36)
              .substring(2, 15)}`; // Generate unique ID
            const recaptchaContainer = document.createElement("div");
            recaptchaContainer.id = recaptchaId;
            recaptchaContainer.classList.add("forget");
            document.body.appendChild(recaptchaContainer); // Append the container to the document body

            const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
              size: "invisible",
            });

            setTimeout(() => {
              auth
                .signInWithPhoneNumber(
                  `${registationdata.country}${registationdata.phone}`,
                  verify
                )
                .then((result) => {
                  setfinal(result);

                  setresenstatus(true);
                  setbuttonenable(true);
                  setTimeout(() => {
                    recaptchaContainer.remove();
                    setresenstatus(false);
                  }, 5000);
                })
                .catch((err) => {
                  // Handle error
                  // For example, display an error message
                  recaptchaContainer.remove();
                  setresenstatus(false); // Remove the container on error
                });
            }, 100);
          } else {
            setresenstatus(false);
            // Handle invalid phone number length
          }
        } else {
          setRecaptchaId(
            Array.from({ length: 6 }, () =>
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            ).join("")
          );
        }
        setnewloading1(false);
        dispatch(storeAction.isPopUpHander(e));
      }
    } else if (e == "emailverification") {
      setfinalerror(false);
      if (registationdata.email.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else {
        setnewloading2(true);
        setregistationdataerror((values) => ({
          ...values,
          email: false,
        }));
        if (updateid == null) {
          var newobj = {
            email: registationdata.email,
            username: registationdata.email,
            password: "Password@123",
            role: 3,
          };
          var createuser = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
              newobj
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (createuser.access_token !== undefined) {
            dispatch(storeAction.userdataHander({ userdata: [createuser] }));
            dispatch(
              storeAction.tokenHandler({ token: createuser.access_token })
            );
            setnewloading2(false);
            dispatch(storeAction.isPopUpHander(e));
            setupdateid(createuser.id);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
              headers: {
                Authorization: `JWT ${createuser.access_token}`,
              },
            };
            axios
              .request(config)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                return error;
              });
            setstatus(true);
            setTimeout(() => {
              setstatus(false);
            }, 5000);
          } else {
            if (createuser.data.error == "User already exists") {
              var new_obj = {
                username: registationdata.email,
                email: registationdata.email,
                password: "Password@123",
              };
              var loginuser = await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                  new_obj
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (loginuser.access !== undefined) {
                const token = loginuser.access;
                const decoded = jwtDecode(token);
                var userinfo = await axios
                  .get(
                    `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${loginuser.access}`,
                      },
                    }
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .catch((err) => {
                    return err.response;
                  });
                if (userinfo) {
                  if (userinfo.status == null || userinfo.status.length == 0) {
                    await axios
                      .delete(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                    verifyHandler("emailverification");
                  } else {
                    setnewloading2(false);
                    setfinalerror(true);
                  }
                }
              } else {
                setnewloading2(false);
                setfinalerror(true);
              }
            } else {
              setnewloading2(false);
              setfinalerror(true);
            }
            setnewloading2(false);
          }
        } else {
          dispatch(storeAction.isPopUpHander(e));
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander(e));
    }
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const validatePassword = (password) => ({
    validLength: password.length >= 6,
    hasLowerCase: /[a-z]/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    hasNumber: /\d/.test(password),
  });

  const handlechange = (e) => {
    setregistationdataerror({
      first_name: false,
      last_name: false,
      phone: false,
      email: false,
      country: false,
      location: false,
      linkedin: false,
      password: false,
      cpassword: false,
    });
    setErrorMessage(false);
    const { name, value } = e.target;
    if (name == "phone") {
      setphone(value);
      if (value.length == 10) {
        setphoneVerify(true);
      } else {
        setphoneVerify(false);
      }
    }
    if (name === "password" || name === "cpassword") {
      const newValidations = validatePassword(value);
      setValidations(newValidations);
      setErrorMessage(!Object.values(newValidations).every(Boolean));
    }
    setregistationdata((values) => ({ ...values, [name]: value }));
  };

  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const emailverification = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: registationdata.email,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      setemailVerify(true);
      if (userdata.length !== 0) {
        let updatedObject = {
          ...userdata[0],
          email_verification: true,
        };
        setInputValues(["", "", "", "", "", ""]);
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }
      settoastifystatus(true);
      toast.success("Email ID verified", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        country: false,
        location: false,
        linkedin: false,
      });
      setTimeout(() => {
        setIsLoading(false);
        dispatch(storeAction.isPopUpHander());
      }, 2000);

      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
      setwrongotpcountemail(Number(wrongotpcountemail) + 1);
    }
  };

  const SendMail = async () => {
    setstatus(false);
    seterrorstatus(false);
    setwrongotpcountemail(0);
    setInputValues(["", "", "", "", "", ""]);
    if (updateid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  const phoneverification = async () => {
    setIsLoading(true);
    setwrongotp(false);
    var otp = inputValues.join("");
    final
      .confirm(otp)
      .then(async (result) => {
        let data = JSON.stringify({
          username: registationdata.email,
          email: registationdata.email,
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
        });
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        var user_data = await axios
          .request(config)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
        if (
          user_data.message === "User and Associated Info updated successfully"
        ) {
          let updatedObject = {
            ...userdata[0],
            email_verification: true,
            mobile_verification: true,
            phone: registationdata.phone,
          };
          dispatch(storeAction.userdataHander({ userdata: [] }));
          setTimeout(() => {
            dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          }, 10);
        }
        settoastifystatus(true);
        setphoneVerify(true);
        toast.success("Phone number verified", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
        setIsLoading(false);
        setregistationdataerror({
          first_name: false,
          last_name: false,
          phone: false,
          email: false,
          country: false,
          location: false,
          linkedin: false,
        });
        setTimeout(() => {
          dispatch(storeAction.isPopUpHander());
          setIsLoading(false);
        }, 2000);
        setTimeout(() => {
          settoastifystatus(false);
        }, 3000);
      })
      .catch((err) => {
        setwrongotp(true);
        setwrongotpcountphone(Number(wrongotpcountphone) + 1);
        setIsLoading(false);
      });
  };

  const resendbtn = async () => {
    setbuttonenable(false);
    setInputValues(["", "", "", "", "", ""]);
    setwrongotpcountphone(0);
    setwrongotp(false);
    if (registationdata.phone.length !== 0) {
      if (registationdata.phone.length === 10) {
        const recaptchaId = `recaptcha-${Math.random()
          .toString(36)
          .substring(2, 15)}`; // Generate unique ID
        const recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = recaptchaId;
        recaptchaContainer.classList.add("forget");
        document.body.appendChild(recaptchaContainer); // Append the container to the document body

        const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
          size: "invisible",
        });

        setTimeout(() => {
          auth
            .signInWithPhoneNumber(
              `${registationdata.country}${registationdata.phone}`,
              verify
            )
            .then((result) => {
              setfinal(result);

              setresenstatus(true);
              setbuttonenable(true);
              setTimeout(() => {
                recaptchaContainer.remove();
                setresenstatus(false); // Remove the container after verification
              }, 5000);
            })
            .catch((err) => {
              // Handle error
              // For example, display an error message
              recaptchaContainer.remove();
              setresenstatus(false); // Remove the container on error
            });
        }, 100);
      } else {
        setresenstatus(false);

        // Handle invalid phone number length
      }
    } else {
      setresenstatus(false);

      // Handle empty phone number
    }
  };

  useEffect(() => {
    GetTokenvalidation();
  }, []);

  const GetTokenvalidation = async () => {
    if (token !== null && token.length !== 0 && userdata.length !== 0) {
      var userinfo = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (userinfo.data.detail == "Given token not valid for any token type") {
        dispatch(storeAction.isPopUpHander("contactus"));
      }
    }
  };

  useEffect(() => {
    Getskill();
  }, [Skilllist]);

  const Getskill = async () => {
    var skillarrray = Skilllist;
    const uniqueSkills = Array.from(
      new Set(skillarrray.map((skill) => skill.Skill))
    );
    if (uniqueSkills.length !== 0) {
      var filter = [];
      for (var i = 0; i < uniqueSkills.length; i++) {
        filter.push({
          value: uniqueSkills[i],
          label: uniqueSkills[i],
        });
      }
      setskilloption(filter);
    }
  };

  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page2") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });
  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const continuebtn = async () => {
    const [firstName, lastName] = [
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .slice(0, -1)
        .join(" "),
      `${registationdata.first_name} ${registationdata.last_name}`
        .split(" ")
        .pop(),
    ];
    var obj = {
      contacted_url: window.location.href,
      first_name: firstName,
      last_name: lastName,
      email: registationdata.email,
      phone: phone,
      role: "Candidate",
      country: registationdata.location,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/zoho/lead/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var userinfo = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}`, {
        maxRedirects: 0,
        validateStatus: (status) => {
          // Allow redirect status codes (301, 302) to pass without throwing an error
          return status < 400;
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    dispatch(storeAction.issidebarHandler({ issidebar: true }));
    dispatch(storeAction.isloginHandler({ islogin: true }));
    dispatch(
      storeAction.onboarding_statusHander({
        onboarding_status: 4,
      })
    );

    dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
    dispatch(storeAction.tokenHandler({ token: token }));
    dispatch(storeAction.useridHandler({ userid: updateid }));
    dispatch(storeAction.loginroleHander({ loginrole: 3 }));
    dispatch(
      storeAction.onboarding_statusHander({
        onboarding_status: 4,
      })
    );
    window.location.replace("/#/profile");
    // navigate(`/login`);
    // dispatch(storeAction.reset());
    // localStorage.removeItem("persist:root");
    // localStorage.clear();
    // sessionStorage.clear();
  };

  const fileInputRef = useRef(null);

  const handleFileSelectClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const selectedImage = e.target.files[0];
    // await uploadPdf(e.target.files[0]);
    setuploaderror(false);
    setuploadsuccess(false);
    setnewloading3(true);
    if (selectedImage.size > 5 * 1024 * 1024) {
      fileInputRef.current.value = "";
      setcvuploaderror(true);
      fileInputRef.current.value = "";
      setnewloading3(false);
    } else {
      var id = Math.floor(100000 + Math.random() * 900000);
      settoastifystatus(true);
      toast.info("Please wait resume uploading... ", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref(`cv/${id}` + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl1 = await file13;
      settoastifystatus(false);
      setuploadsuccess(true);
      setcvfile(imgurl1);
      setnewloading3(false);
    }
    fileInputRef.current.value = "";
  };

  // const uploadPdf = async (pdfFile) => {
  //   if (pdfFile) {
  //     setnewloading3(true);
  //     const reader = new FileReader();
  //     reader.onload = async function (event) {
  //       try {
  //         const base64String = event.target.result.split(";base64,")[1];
  //         const sixDigitNumber = Math.floor(100000 + Math.random() * 900000);
  //         let dataToSend = JSON.stringify({
  //           index: {
  //             indexType: "Resume",
  //             indexKey: "KDJ6NFUC",
  //           },
  //           data: {
  //             id: sixDigitNumber.toString(),
  //             fileContent: base64String,
  //             fileName: pdfFile.name,
  //           },
  //           commit: true,
  //           deDuplication: {
  //             checkDuplicate: true,
  //             fields: ["Email"],
  //             update: true,
  //           },

  //           filedata: base64String,
  //           filename: pdfFile.name,
  //           userkey: "Rchilli provided userkey for api access",
  //           version: "8.0.0",
  //           subuserid: "as per agreement",
  //         });

  //         let config = {
  //           method: "post",
  //           maxBodyLength: Infinity,
  //           url: "https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //           },
  //           data: dataToSend,
  //         };

  //         var indexdata = await axios
  //           .request(config)
  //           .then((response) => {
  //             return response.data;
  //           })
  //           .catch((error) => {
  //             throw error;
  //           });

  //         if (indexdata && indexdata.status == 200) {
  //           try {
  //             const base64EncodedString = indexdata.doc;
  //             let decodedString = atob(base64EncodedString);
  //             var resumedata2 = JSON.parse(decodedString);

  //             const arrayOfStrings =
  //               resumedata2.ResumeParserData.LanguageKnown.map((obj) =>
  //                 obj.Language && obj.Language.length
  //                   ? `${obj.Language}: Proficient`
  //                   : ""
  //               ).filter((str) => str !== "");

  //             let webSites = resumedata2.ResumeParserData.WebSite;
  //             var linkedinurl = "";
  //             var githuburl = "";
  //             webSites.forEach((site) => {
  //               if (site.Type === "Github") {
  //                 githuburl = site.Url;
  //               } else if (site.Type === "Linkedin") {
  //                 linkedinurl = site.Url;
  //               }
  //             });
  //             const skills =
  //               resumedata2.ResumeParserData.SkillKeywords.length !== 0
  //                 ? resumedata2.ResumeParserData.SkillKeywords.split(
  //                     ","
  //                   ).filter((skill) => skill.trim() !== "")
  //                 : [];

  //             const firstFiveSkills =
  //               skills.length !== 0 ? skills.slice(0, 8) : [];
  //             var newobj1 = {
  //               username:
  //                 userdata.length !== 0
  //                   ? userdata[0].username
  //                   : registationdata.email,
  //               resume_json: JSON.stringify(resumedata2.ResumeParserData),
  //               gender: resumedata2.ResumeParserData.Gender,
  //               title: "Candidate",
  //               date_of_birth: resumedata2.ResumeParserData.DateOfBirth,
  //               current_place_of_residence:
  //                 resumedata2.ResumeParserData.Address[0].Country,
  //               lived_at_current_residence: 0,
  //               email_verification: true,
  //               mobile_verification: true,
  //               bio: resumedata2.ResumeParserData.ExecutiveSummary,

  //               address: {
  //                 address: resumedata2.ResumeParserData.Address[0].Street,
  //                 city: resumedata2.ResumeParserData.Address[0].City,
  //                 state: resumedata2.ResumeParserData.Address[0].State,
  //                 pincode:
  //                   resumedata2.ResumeParserData.Address[0].ZipCode.length !== 0
  //                     ? Number(resumedata2.ResumeParserData.Address[0].ZipCode)
  //                     : 0,
  //                 country: resumedata2.ResumeParserData.Address[0].Country,
  //               },
  //               kyc_info: {
  //                 pan_number: resumedata2.PanNo,
  //               },
  //               passport_info: {
  //                 passport_number:
  //                   resumedata2.ResumeParserData.PassportDetail.PassportNumber,
  //                 passport_validity:
  //                   resumedata2.ResumeParserData.PassportDetail.DateOfExpiry,
  //                 country_of_citizenship:
  //                   resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
  //                 country_of_issue:
  //                   resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
  //               },
  //               preference_info: {
  //                 year_of_experience:
  //                   resumedata2.ResumeParserData.WorkedPeriod
  //                     .TotalExperienceInYear.length !== 0
  //                     ? Number(
  //                         Math.round(
  //                           resumedata2.ResumeParserData.WorkedPeriod
  //                             .TotalExperienceInYear
  //                         )
  //                       )
  //                     : 0,
  //                 skills: firstFiveSkills,
  //                 linkedin: linkedinurl ? linkedinurl : undefined,
  //                 github: githuburl ? githuburl : undefined,
  //                 language:
  //                   resumedata2.ResumeParserData.LanguageKnown.length !== 0
  //                     ? arrayOfStrings
  //                     : undefined,
  //                 qualification: resumedata2.ResumeParserData.JobProfile,
  //               },
  //               work_preference_info: {
  //                 key_skills: firstFiveSkills,
  //                 github_url: githuburl ? githuburl : undefined,
  //               },
  //             };
  //             var updatedata = await axios
  //               .put(
  //                 `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
  //                 newobj1,
  //                 {
  //                   headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `JWT ${token}`,
  //                   },
  //                 }
  //               )
  //               .then((res) => {
  //                 return res.data;
  //               })
  //               .catch((err) => {
  //                 return err.response;
  //               });
  //             if (
  //               updatedata.message ===
  //               "User and Associated Info updated successfully"
  //             ) {
  //               let updatedObject = {
  //                 ...userdata[0],
  //                 resume_json: JSON.stringify(resumedata2.ResumeParserData),
  //                 username:
  //                   userdata.length !== 0
  //                     ? userdata[0].username
  //                     : registationdata.email,

  //                 gender: resumedata2.ResumeParserData.Gender,
  //                 title: "Candidate",
  //                 date_of_birth: resumedata2.ResumeParserData.DateOfBirth,
  //                 current_place_of_residence:
  //                   resumedata2.ResumeParserData.Address[0].Country,
  //                 lived_at_current_residence: 0,
  //                 email_verification: true,
  //                 mobile_verification: true,
  //                 bio: resumedata2.ResumeParserData.ExecutiveSummary,

  //                 address: {
  //                   address: resumedata2.ResumeParserData.Address[0].Street,
  //                   city: resumedata2.ResumeParserData.Address[0].City,
  //                   state: resumedata2.ResumeParserData.Address[0].State,
  //                   pincode:
  //                     resumedata2.ResumeParserData.Address[0].ZipCode.length !==
  //                     0
  //                       ? Number(
  //                           resumedata2.ResumeParserData.Address[0].ZipCode
  //                         )
  //                       : 0,
  //                   country: resumedata2.ResumeParserData.Address[0].Country,
  //                 },
  //                 kyc_info: {
  //                   pan_number: resumedata2.PanNo,
  //                 },
  //                 passport_info: {
  //                   passport_number:
  //                     resumedata2.ResumeParserData.PassportDetail
  //                       .PassportNumber,
  //                   passport_validity:
  //                     resumedata2.ResumeParserData.PassportDetail.DateOfExpiry,
  //                   country_of_citizenship:
  //                     resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
  //                   country_of_issue:
  //                     resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
  //                 },
  //                 preference_info: {
  //                   year_of_experience:
  //                     resumedata2.ResumeParserData.WorkedPeriod
  //                       .TotalExperienceInYear.length !== 0
  //                       ? Number(
  //                           Math.round(
  //                             resumedata2.ResumeParserData.WorkedPeriod
  //                               .TotalExperienceInYear
  //                           )
  //                         )
  //                       : 0,
  //                   skills: firstFiveSkills,
  //                   linkedin: linkedinurl ? linkedinurl : undefined,
  //                   github: githuburl ? githuburl : undefined,
  //                   language:
  //                     resumedata2.ResumeParserData.LanguageKnown.length !== 0
  //                       ? arrayOfStrings
  //                       : undefined,
  //                 },
  //                 work_preference_info: {
  //                   key_skills: firstFiveSkills,
  //                   github_url: githuburl ? githuburl : undefined,
  //                 },
  //               };
  //               dispatch(storeAction.userdataHander({ userdata: [] }));
  //               setTimeout(() => {
  //                 dispatch(
  //                   storeAction.userdataHander({ userdata: [updatedObject] })
  //                 );
  //               }, 10);
  //               if (
  //                 resumedata2.ResumeParserData.SegregatedExperience.length !== 0
  //               ) {
  //                 for (
  //                   var i = 0;
  //                   i <
  //                   resumedata2.ResumeParserData.SegregatedExperience.length;
  //                   i++
  //                 ) {
  //                   var profesionaldata = {
  //                     username:
  //                       resumedata2.ResumeParserData.Email[0].EmailAddress,
  //                     professional_details_info: {
  //                       user: updateid,
  //                       title:
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .JobProfile.Title,
  //                       years_active: `${
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .StartDate.length !== 0
  //                           ? resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].StartDate.split("/")[2] -
  //                             resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].StartDate.split("/")[1] -
  //                             resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].StartDate.split("/")[0]
  //                           : ""
  //                       },${
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .EndDate.length !== 0
  //                           ? resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].EndDate.split("/")[2] -
  //                             resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].EndDate.split("/")[1] -
  //                             resumedata2.ResumeParserData.SegregatedExperience[
  //                               i
  //                             ].EndDate.split("/")[0]
  //                           : ""
  //                       }`,
  //                       company_name:
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .Employer.EmployerName,
  //                       location:
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .Location.City,
  //                       description:
  //                         resumedata2.ResumeParserData.SegregatedExperience[i]
  //                           .JobDescription,
  //                     },
  //                   };
  //                   await axios
  //                     .post(
  //                       `${process.env.REACT_APP_LOCAL_HOST_URL}/getProffessionalDetails/${updateid}/`,
  //                       profesionaldata,
  //                       {
  //                         headers: {
  //                           "Content-Type": "application/json",
  //                           Authorization: `JWT ${token}`,
  //                         },
  //                       }
  //                     )
  //                     .then((res) => {
  //                       return res.data;
  //                     })
  //                     .catch((err) => {
  //                       return err.response;
  //                     });
  //                 }
  //               }

  //               if (
  //                 resumedata2.ResumeParserData.SegregatedQualification
  //                   .length !== 0
  //               ) {
  //                 for (
  //                   var j = 0;
  //                   j <
  //                   resumedata2.ResumeParserData.SegregatedQualification.length;
  //                   j++
  //                 ) {
  //                   var education_infodata = {
  //                     username:
  //                       resumedata2.ResumeParserData.Email[0].EmailAddress,
  //                     education_info: {
  //                       user: updateid,
  //                       degree:
  //                         resumedata2.ResumeParserData.SegregatedQualification[
  //                           j
  //                         ].Degree.DegreeName,
  //                       year_of_graduation:
  //                         resumedata2.ResumeParserData.SegregatedQualification[
  //                           j
  //                         ].EndDate.split("/")[2],
  //                       university_name:
  //                         resumedata2.ResumeParserData.SegregatedQualification[
  //                           j
  //                         ].Institution.Name,
  //                       education_level:
  //                         resumedata2.ResumeParserData.SegregatedQualification[
  //                           j
  //                         ].Institution.Type,
  //                     },
  //                   };
  //                   await axios
  //                     .post(
  //                       `${process.env.REACT_APP_LOCAL_HOST_URL}/getEducations/${updateid}/`,
  //                       education_infodata,
  //                       {
  //                         headers: {
  //                           "Content-Type": "application/json",
  //                           Authorization: `JWT ${token}`,
  //                         },
  //                       }
  //                     )
  //                     .then((res) => {
  //                       return res.data;
  //                     })
  //                     .catch((err) => {
  //                       return err.response;
  //                     });
  //                 }
  //               }
  //               dispatch(storeAction.userdataHander({ userdata: [] }));
  //               setTimeout(() => {
  //                 dispatch(
  //                   storeAction.userdataHander({ userdata: [updatedObject] })
  //                 );
  //               }, 10);
  //             }
  //             setnewloading3(false);
  //           } catch (error) {
  //             console.error("Error decoding Base64 string:", error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error processing PDF file:", error);
  //       }
  //       setTimeout(() => {
  //         return true;
  //       }, 2000);
  //     };
  //     reader.readAsDataURL(pdfFile);
  //   }
  // };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isPage == "page1" && event.key === "Enter") {
        submitHandler();
      } else if (isPage == "page2" && event.key === "Enter") {
        continuebtn();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPage, registationdata]);

  const showPassword = () => {
    setShow(!show);
  };

  const showConfirmPassword = () => {
    setShow1(!show1);
  };
  const closeBtn = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
  };
  return (
    <div>
      <Helmet>
        <title>Sign Up | Setup your HireIn5 Account</title>
        <meta
          name="description"
          content="Create your Hirein5 account in minutes and unlock access to top job opportunities and career growth tools."
        />
      </Helmet>
      {isPage == "page1" && (
        <div className="backGround">
          <button className="logoutReg" onClick={closeBtn}>
            Close
          </button>
          <div className="newClientFlow1 sectionWidth">
            <div className="newClientFlowHead pt-10">
              <Head />
              <h2>Your Next Opportunity Starts Here!</h2>
              <h1>
                Complete your profile to boost your chances of getting hired and
                stay updated on new opportunities!
              </h1>
            </div>
            <div className="newClientFlowBody">
              <div className="newClientFlowContent">
                <div className="newClientFlowContent1">
                  <h2>
                    First & Middle Name <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    placeholder="First & Middle Name"
                    defaultValue={registationdata.first_name}
                    name="first_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.first_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter First & Middle Name
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  <h2>
                    Last Name <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    placeholder="Last name"
                    defaultValue={registationdata.last_name}
                    name="last_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.last_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Last Name
                    </p>
                  )}
                </div>
              </div>

              <div className="newClientFlowContent">
                <div className="newClientFlowContentSelect marginBottom0">
                  <h2>
                    Official Email ID <span className="text-red-500">*</span>
                  </h2>
                  <div className="newClientFlowContentInput">
                    <input
                      className="buttonWidth85"
                      type="text"
                      placeholder="yourname@gmail.com"
                      defaultValue={registationdata.email}
                      name="email"
                      onChange={handlechange}
                      disabled={emailVerify == true}
                    />
                    <button
                      onClick={
                        emailVerify == true
                          ? null
                          : () => verifyHandler("emailverification")
                      }
                      className={
                        emailVerify == true
                          ? "newClientFlowContentSelectEnable"
                          : "newClientFlowContentSelectDisable"
                      }
                    >
                      {emailVerify == true ? (
                        <h4 className="verifybtn">
                          Verified <FiCheck />
                        </h4>
                      ) : newloading2 == false ? (
                        "Verify"
                      ) : (
                        <FiLoader className="loadingIcon" />
                      )}
                    </button>
                  </div>
                  {registationdataerror.emailVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Email ID
                    </p>
                  )}
                  {registationdataerror.email && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Official Email ID
                    </p>
                  )}
                  {finalerror && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      User already registered with email address.
                    </p>
                  )}
                </div>
              </div>

              <div className="newClientFlowContent mt-5">
                <div className="newClientFlowContentSelectnew marginBottom0">
                  <h3>
                    Phone number <span className="text-red-500">*</span>
                  </h3>
                  <div className="newClientFlowContentInput">
                    <select
                      name="country"
                      defaultValue={registationdata.country}
                      onChange={handlechange}
                      // disabled={phoneVerify == false && emailVerify == false}
                    >
                      <option value="">Select Country</option>
                      {Countrycode.length !== 0
                        ? Countrycode.map((data, index) => (
                            <option
                              value={data.dial_code}
                              key={index}
                              selected={
                                registationdata.country == data.dial_code
                              }
                            >
                              {data.name} ({data.dial_code})
                            </option>
                          ))
                        : null}
                    </select>

                    <input
                      type="number"
                      placeholder="Your Phone no"
                      defaultValue={registationdata.phone}
                      name="phone"
                      onChange={handlechange}
                    />
                  </div>

                  {registationdataerror.phone && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Phone number
                    </p>
                  )}
                  {registationdataerror.phoneVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Phone number
                    </p>
                  )}
                  <div id={recaptchaId} className="forget"></div>
                </div>
              </div>

              <div className="newClientFlowContent mt-5">
                <div className="newClientFlowContent1">
                  <h2>
                    Password <span className="text-red-500">*</span>
                  </h2>
                  <div className="clientSignUpCompBodyPasswordInput">
                    <input
                      type={show === true ? "text" : "password"}
                      name="password"
                      onChange={handlechange}
                      placeholder="Password"
                      defaultValue={registationdata.password}
                    />
                    {show === false ? (
                      <FiEyeOff
                        className="text-gray-500 eyeOne"
                        onClick={showPassword}
                      />
                    ) : (
                      <FiEye
                        className="text-gray-500 eyeOne"
                        onClick={showPassword}
                      />
                    )}
                  </div>
                  {registationdataerror.password && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Password
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  <h2>
                    Confirm Password <span className="text-red-500">*</span>
                  </h2>
                  <div className="clientSignUpCompBodyConfirmPasswordInput">
                    <input
                      onChange={handlechange}
                      type={show1 === true ? "text" : "password"}
                      name="cpassword"
                    />

                    {show1 === false ? (
                      <FiEyeOff
                        className="text-gray-500 eyeTwo"
                        onClick={showConfirmPassword}
                      />
                    ) : (
                      <FiEye
                        className="text-gray-500 eyeTwo"
                        onClick={showConfirmPassword}
                      />
                    )}
                  </div>
                  {registationdataerror.cpassword && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Confirm Password
                    </p>
                  )}
                </div>
              </div>
              {notmatch && (
                <h6 className="text-red-500 text-xs font-semibold -mt-2 mb-2">
                  Password & Confirm Password not match
                </h6>
              )}

              {(registationdata.password.length !== 0 ||
                registationdata.cpassword.length !== 0) &&
              errorMessage ? (
                <div className="validation-indicators mt-2 mb-2">
                  <div
                    className={validations.validLength ? "valid" : "invalid"}
                  >
                    {validations.validLength ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}

                    {!validations.validLength && (
                      <span className="error-message">
                        Password must be at least 6 characters long.
                      </span>
                    )}
                    {validations.validLength && (
                      <span>Password must be at least 6 characters long.</span>
                    )}
                  </div>
                  <div
                    className={validations.hasLowerCase ? "valid" : "invalid"}
                  >
                    {validations.hasLowerCase ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasLowerCase && (
                      <span>
                        Password must contain at least one lowercase letter.
                      </span>
                    )}
                    {!validations.hasLowerCase && (
                      <span className="error-message">
                        Password must contain at least one lowercase letter.
                      </span>
                    )}
                  </div>
                  <div
                    className={validations.hasUpperCase ? "valid" : "invalid"}
                  >
                    {validations.hasUpperCase ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}

                    {!validations.hasUpperCase && (
                      <span className="error-message">
                        Password must contain at least one uppercase letter.
                      </span>
                    )}
                    {validations.hasUpperCase && (
                      <span>
                        Password must contain at least one uppercase letter.
                      </span>
                    )}
                  </div>
                  <div
                    className={validations.hasSpecialChar ? "valid" : "invalid"}
                  >
                    {validations.hasSpecialChar ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasSpecialChar && (
                      <span>
                        Password must contain at least one special character.
                      </span>
                    )}

                    {!validations.hasSpecialChar && (
                      <span className="error-message">
                        Password must contain at least one special character.
                      </span>
                    )}
                  </div>
                  <div className={validations.hasNumber ? "valid" : "invalid"}>
                    {validations.hasNumber ? (
                      <FaCheckCircle />
                    ) : (
                      <FaTimesCircle />
                    )}
                    {validations.hasNumber && (
                      <span>Password must contain at least one number.</span>
                    )}
                    {!validations.hasNumber && (
                      <span className="error-message">
                        Password must contain at least one number.
                      </span>
                    )}
                  </div>
                </div>
              ) : registationdata.password.length !== 0 &&
                registationdata.cpassword.length !== 0 ? (
                registationdata.password == registationdata.cpassword ? null : (
                  !notmatch && (
                    <h6 className="text-red-500 text-xs font-semibold -mt-2 mb-2">
                      Password & Confirm Password not match
                    </h6>
                  )
                )
              ) : null}
              <div className="newClientFlow_Content">
                <h2>
                  Key Skills <span className="text-red-500">*</span>
                </h2>
                <Select
                  defaultValue={selectedOptionskill}
                  onChange={setSelectedOptionskill}
                  options={skilloption}
                  isMulti
                />
                {registationdataerror.skill && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Select Key Skills
                  </p>
                )}
              </div>
              <div className="newClientFlowContent marginTop15">
                <div className="newClientFlowContent1">
                  <h2>LinkedIn (Optional)</h2>
                  <input
                    type="text"
                    placeholder="LinkedIn"
                    defaultValue={registationdata.linkedin}
                    name="linkedin"
                    onChange={handlechange}
                  />
                  {registationdataerror.linkedin && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter LinkedIn
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  <h2>
                    Your current location{" "}
                    <span className="text-red-500">*</span>
                  </h2>
                  <select
                    className="newClientFlowContent1Select"
                    type="text"
                    placeholder="Select Country"
                    defaultValue={registationdata.location}
                    name="location"
                    onChange={handlechange}
                  >
                    <option value="">Select Country</option>
                    {country_and_states.country.length !== 0
                      ? country_and_states.country.map((item, index) => (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  {registationdataerror.location && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Select Your current location
                    </p>
                  )}
                </div>
              </div>
              <div
                className="aadhaar cursor-pointer"
                onClick={handleFileSelectClick}
              >
                <h3>
                  Upload CV <span className="text-red-500">*</span>
                </h3>
                <div className="aadhaarFront">
                  <h3>
                    Drop your CV or <span className="browser">browse</span>
                  </h3>
                  <p>Maximum size: 5MB</p>
                  <p title="">
                    accepted formats: .pdf, .doc, .docx, .txt, .rtf
                  </p>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  name="aadhaarfront"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={handleFileInputChange}
                />
                {uploaderror && (
                  <h6 className="text-red-500 text-xs font-semibold mt-2">
                    Please Upload CV
                  </h6>
                )}
                {uploadsuccess && (
                  <h6 className="text-green-500 text-xs font-semibold mt-2">
                    CV Uploaded Successfully
                  </h6>
                )}
                {uploadsuccess && (
                  <div
                    className="gradeCertificate"
                    onClick={() => {
                      window.open(`${cvfile}`, "_blank");
                    }}
                  >
                    <ImAttachment className="attachicon text-2xl" />
                    <div className="gradeCertificateDesc">
                      <p className="text-black">View CV</p>
                    </div>
                  </div>
                )}

                {cvuploaderror && (
                  <h6 className="text-red-500 text-xs font-semibold mt-2">
                    Image size exceeds 5 MB limit.
                  </h6>
                )}
              </div>
              {/* <p className="text-red-500 pt-4 font-medium">Note : Please upload video resume for better experience</p> */}
              {/* <div
                className="aadhaar cursor-pointer"
                onClick={handleFileSelectClick}
              >
                <h3>Upload Video Resume (Optional)</h3>
                <div className="aadhaarFront">
                  <h3>
                    Drop your Video Resume or{" "}
                    <span className="browser">browse</span>
                  </h3>
                  <p>Maximum size: 5MB</p>
                  <p title="">
                    accepted formats: .pdf, .doc, .docx, .txt, .rtf
                  </p>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  name="aadhaarfront"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={handleFileInputChange}
                />
                {uploaderror && (
                  <h6 className="text-red-500 text-xs font-semibold mt-2">
                    Please Video Resume
                  </h6>
                )}
                {uploadsuccess && (
                  <h6 className="text-green-500 text-xs font-semibold mt-2">
                    Video Resume Uploaded Successfully
                  </h6>
                )}
              </div> */}
            </div>
            {bottomerror && (
              <p className="text-red-500 text-sm font-semibold mb-2 text-start">
                Please complete all the mandatory fields.
              </p>
            )}
            <div className="QuestionsSubmitButton">
              {newloading3 === false ? (
                <button
                  className="nextbtn buttonWidth100"
                  id="page2"
                  onClick={submitHandler}
                >
                  Next
                </button>
              ) : (
                <button
                  id="Signup"
                  className="signUpCompBodyButtonLoading buttonWidth100"
                >
                  <FiLoader className="loadingIcon" />
                </button>
              )}
            </div>
            <div className="newClientFlowSupportnew">
              <h5>
                Not a Candidate?{" "}
                <a href="/#/clientregister">Click here for Client Signup</a>
              </h5>
            </div>
            <div className="newClientFlowSupport">
              <h5>
                For any assistance, please contact
                <a href="">candidatesupport@hirein5.com</a>
              </h5>
            </div>
            <div className="newClientFlowTermsAndConditions">
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                    "_blank"
                  );
                }}
              >
                Terms & Conditions
              </h6>
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                    "_blank"
                  );
                }}
              >
                Privacy Policy
              </h6>
            </div>
          </div>
        </div>
      )}
      {isPage == "page2" && (
        <div className="backGround">
          <button className="logoutReg" onClick={closeBtn}>
            Close
          </button>
          <div className="newClientFlow height100vh sectionWidth">
            <div className="newClientFlowHead displayFlex">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Thank you!"
                  des="We have received your details. Please complete your application process within 45 minutes to ensure a smooth onboarding."
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPopUp == "emailverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Email ID</h1>
            <p>
              Enter the 6-digit code we’ve sent to your Email Address
              <span>{registationdata.email}</span>
            </p>
            {status && (
              <p className="successerror">
                The OTP has been successfully sent to your email address.
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>

            {wrongotpcountemail !== 5 ? (
              errorstatus && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountemail}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountemail !== 5 ? (
              isLoading === false ? (
                <button className="mobVerifyButton" onClick={emailverification}>
                  Verify
                </button>
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive the email? Check your Spam folder or{" "}
              <span
                onClick={() => {
                  SendMail();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}
      {isPopUp == "mobileverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Phone No.</h1>
            <p>
              Enter the 6-digit code we’ve sent to your phone number
              <span>
                {registationdata.country} {registationdata.phone}
              </span>
            </p>
            {resenstatus && (
              <p className="successerror">
                The OTP has been successfully sent to your phone number
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>
            {wrongotpcountphone !== 5 ? (
              wrongotp && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountphone}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountphone !== 5 ? (
              isLoading === false ? (
                buttonenable ? (
                  <button
                    className="mobVerifyButton"
                    onClick={phoneverification}
                  >
                    Verify
                  </button>
                ) : (
                  <button className="mobVerifyButtondisable">Verify</button>
                )
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive OTP?
              <span
                onClick={() => {
                  resendbtn();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}

      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewCandidateFlow;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { firebase } from "../database/firebase";

const ChatComponent = () => {
  const token = useSelector((store) => store.token);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [responseData, setResponseData] = useState("");
  const reportRef = useRef(null);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleNext();
    }
  }, [timeLeft]);

  const fetchQuestions = async () => {
    const userMessage = {
      role: "user",
      content: `
        I am a HTML/CSS developer. I would like to go through a technical assessment. Can you please provide me with 12 questions and evaluate my answers?
        Please provide all these questions in an array of objects, where the "question" field is not an empty string, and the "answer" and "score" fields are empty strings.
        **Rules to follow:**
        1. Each time, generate some variation in the questions.
        2. Only provide the response in an array of objects.
        3. Do not add any extra text in the response.
        4. Include exactly 3 coding questions.
        5. Provide the response in JSON format only.
      `,
    };

    try {
      const response = await fetch(
        "https://shark-app-5pxme.ondigitalocean.app/api/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: [userMessage] }),
        }
      );
      const data = await response.json();
      const rawResponse = data.response.replace(/```[\w]*\n|```/g, "").trim();
      const parsedData = JSON.parse(rawResponse);

      setQuestionsList(parsedData);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleNext = () => {
    if (currentQuestionIndex < questionsList.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeft(60);
    }
  };

  const handleSubmit = async () => {
    const formattedQuestions = JSON.stringify(questionsList);

    const userMessage = {
      role: "user",
      content: `Here are the 12 questions along with answers given by a candidate:
      
      ${formattedQuestions} 
      
      Please evaluate all of them and generate a *detailed performance report* with a *consistent design*.

      *Report Format:*
      - *Candidate's Answers*
      - *Correct Answers*
      - *Score*
      - *Detailed Feedback*
      - *Total Score*
      - *Areas of Improvement*
      - *Suggestions*
      - *Weaknesses & Strengths*

      *Design Requirements:*
      - Use *tables for structured data*.
      - Apply *consistent font styling, colors, and proper margins*.
      - Ensure all sections are clearly separated.

       **Rules to follow:**
       - remove special charaters 
       - question followed by candidate answer and followed by correct answer and also score
       - remove ** 
       - remove | icon 
       - follow above the rules
       - give score only if candidate is provide proper answer please strict with score
       - need a proper css added table design
       - Apply *consistent font styling, colors, and proper margins*.
       - Ensure all sections are clearly separated.
       - need a well design document format first question then answer and follwed by score and other details.
      `
      ,
    };

    try {
      const response = await fetch(
        "https://shark-app-5pxme.ondigitalocean.app/api/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: [userMessage] }),
        }
      );

      setResponseData(response.data);
      const data = await response.json();
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 10;
      const contentWidth = pageWidth - 2 * margin;
      const lineHeight = 7;
      const maxHeight = pageHeight - 2 * margin;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text("Job Description", pageWidth / 2, margin + 5, {
        align: "center",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);

      const lines = doc.splitTextToSize(data.response, contentWidth);
      let yPosition = margin + 15;

      lines.forEach((line) => {
        if (yPosition + lineHeight > maxHeight) {
          doc.addPage(); // Add a new page when content overflows
          yPosition = margin; // Reset yPosition for the new page
        }
        doc.text(line, margin, yPosition);
        yPosition += lineHeight;
      });

      const pdfBlob = doc.output("blob");
      const fileUploadPromise = new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref(`assessment.pdf`);
        storageRef
          .put(pdfBlob)
          .then((snapshot) => {
            snapshot.ref
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });

      const imgurl2 = await fileUploadPromise;
      console.log(imgurl2, "imgurl2");
    } catch (error) {
      console.error("Error fetching evaluation report:", error);
    }
  };

  const handleInputChange = (e) => {
    const updatedQuestions = [...questionsList];
    updatedQuestions[currentQuestionIndex].answer = e.target.value;
    setQuestionsList(updatedQuestions);
  };

  const currentQuestion = questionsList[currentQuestionIndex];

  return (
    <Box sx={{ padding: 4, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Technical Assessment
      </Typography>

      <Stepper activeStep={currentQuestionIndex} alternativeLabel>
        {questionsList.map((_, index) => (
          <Step key={index}>
            <StepLabel>Question {index + 1}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {currentQuestion && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">
            {`Q${currentQuestionIndex + 1}: ${currentQuestion.question}`}
          </Typography>

          <Typography sx={{ margin: "10px 0" }} color="error">
            Time Left: {timeLeft}s
          </Typography>

          <TextField
            label={
              currentQuestion.type === "coding"
                ? "Write your code here"
                : "Your Answer"
            }
            multiline={currentQuestion.type === "coding"}
            rows={currentQuestion.type === "coding" ? 6 : 1}
            variant="outlined"
            fullWidth
            value={currentQuestion.answer || ""}
            onChange={handleInputChange}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            {currentQuestionIndex < questionsList.length - 1 ? (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      )}

      {responseData && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5" gutterBottom>
            Performance Evaluation
          </Typography>
          <Box
            ref={reportRef}
            sx={{
              padding: 2,
              border: "1px solid #ccc",
              borderRadius: 2,
              backgroundColor: "#ffffff",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{ __html: responseData }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatComponent;

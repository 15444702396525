/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./NewClientFlow.css";
import Head from "../Reusable/LogoHead/Head";
import { FiLoader } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { firebase, auth } from "../../database/firebase";
import SuccessResponse from "../Reusable/SuccessResponse/SuccessResponse";
import axios from "axios";
import country_and_states from "../../assests/country-states";
import Skilllist from "../../assests/skillsJSON.json";
import Select from "react-select";
import { Box, Slider } from "@mui/material";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countrycode from "../../assests/CountryCodes.json";
import { jwtDecode } from "jwt-decode";
import { InlineWidget } from "react-calendly";

const NewClientFlow = () => {
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const onboarding_status = useSelector((store) => store.onboarding_status);
  const token = useSelector((store) => store.token);

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerify, setemailVerify] = useState(false);
  const [phoneVerify, setphoneVerify] = useState(false);
  const [isPage, setIsPage] = useState("page1");
  const [wrongotpcountemail, setwrongotpcountemail] = useState(0);
  const [wrongotpcountphone, setwrongotpcountphone] = useState(0);
  const [wrongotp, setwrongotp] = useState(false);
  const [buttonenable, setbuttonenable] = useState(false);

  const [registationdata, setregistationdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "+91",
    date: "",
    time: "",
    time_zone: "",
    budget: "",
    currency: "",
    location: "",
    company_name: "",
    company_location: "",
  });
  const [registationdataerror, setregistationdataerror] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    date: false,
    time: false,
    time_zone: false,
    budget: false,
    currency: false,
    location: false,
  });
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [errorstatus, seterrorstatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [otherinput, setotherinput] = useState("");
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );

  const [final, setfinal] = useState(null);
  const [prefered_mode, setprefered_mode] = useState([]);
  const [agree, setagree] = useState(false);
  const [agree1, setagree1] = useState(false);
  const [contact_preferences, setcontact_preferences] = useState([]);
  const [lookingdata, setlookingdata] = useState([]);
  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [value, setValue] = useState(3);
  const [finalerror, setfinalerror] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [newloading1, setnewloading1] = useState(false);
  const [newloading2, setnewloading2] = useState(false);
  const [newloading3, setnewloading3] = useState(false);
  const [bottomerror, setbottomerror] = useState(false);
  const [resenstatus, setresenstatus] = useState(false);
  useEffect(() => {
    newcapcha();
  }, [recaptchaId]);
  const newcapcha = () => {
    var newid = Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("");
    setTimeout(() => {
      setRecaptchaId(newid);
    }, 5000);
  };

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);
  const CheckStage = async () => {
    if (userdata.length != 0) {
      setemailVerify(userdata[0].email_verification);
      setphoneVerify(true);
      setregistationdata((values) => ({ ...values, email: userdata[0].email }));
      setregistationdata((values) => ({ ...values, phone: userdata[0].phone }));
      setupdateid(userdata[0].id);
      dispatch(storeAction.tokenHandler({ token: userdata[0].access_token }));
    }
    if (onboarding_status == 2) {
      setIsPage("page2");
    }
  };

  const submitHandler = async (e) => {
    if (isPage == "page1") {
      setbottomerror(false);
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        date: false,
        time: false,
        time_zone: false,
        phoneverify: false,
        contact_preferences: false,
        prefered_mode: false,
        agree: false,
        emailVerify: false,
        budget: false,
        currency: false,
        location: false,
      });
      if (registationdata.first_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, first_name: true }));
      } else if (registationdata.last_name.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, last_name: true }));
      } else if (registationdata.email.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, email: true }));
      } else if (emailVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, emailVerify: true }));
      } else if (registationdata.phone.length === 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phone: true }));
      } else if (phoneVerify == false) {
        setbottomerror(true);
        setregistationdataerror((values) => ({ ...values, phoneverify: true }));
      } else if (registationdata.company_name == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({
          ...values,
          company_name: true,
        }));
      } else if (registationdata.company_location == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({
          ...values,
          company_location: true,
        }));
      } else if (contact_preferences.length == 0) {
        setbottomerror(true);
        setregistationdataerror((values) => ({
          ...values,
          contact_preferences: true,
        }));
      } else if (contact_preferences.includes("Schedule a personalized Demo")) {
        if (prefered_mode.length == 0) {
          setregistationdataerror((values) => ({
            ...values,
            prefered_mode: true,
          }));
          setbottomerror(true);
        } else if (registationdata.date.length === 0) {
          setregistationdataerror((values) => ({ ...values, date: true }));
          setbottomerror(true);
        } else if (registationdata.time.length === 0) {
          setregistationdataerror((values) => ({ ...values, time: true }));
          setbottomerror(true);
        } else if (registationdata.time_zone.length === 0) {
          setregistationdataerror((values) => ({ ...values, time_zone: true }));
          setbottomerror(true);
        } else if (agree == false) {
          setregistationdataerror((values) => ({ ...values, agree: true }));
          setbottomerror(true);
        } else {
          setbottomerror(false);
          setnewloading3(true);
          const updatedItems = prefered_mode.map((item) =>
            item === "Others"
              ? otherinput.length !== 0
                ? otherinput
                : null
              : item
          );
          var new_obj = {
            username: registationdata.email,
            first_name: `${registationdata.first_name} ${registationdata.last_name}`,
            contact_preference_info: {
              prefered_mode: updatedItems,
              date: registationdata.date,
              time: registationdata.time,
              time_zone: registationdata.time_zone,
            },
            company: {
              company_name: registationdata.company_name,
              company_location: registationdata.company_location,
              verified: false,
              terms: true,
              looking_for: lookingdata,
              agree_terms: true,
            },
            onboarding_status: 2,
            status: "New Client",
            email_verification: true,
            mobile_verification: true,
            phone: registationdata.phone,
            role: 2,
          };
          var updatedata = await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
              new_obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
          const emailTemplate = {
            email: "sales@hirein5.com",
            from_email: "connect@hirein5.com",
            subject: `Lead to be contacted on ${registationdata.date} at ${registationdata.time}`,
            message: `<p>Sales Team, <br><br> ${registationdata.first_name} ${
              registationdata.last_name
            } has submitted a form to be contacted via <b>${updatedItems.join(
              ", "
            )}</b> from WebApp. <br><br> Contact Date - ${
              registationdata.date
            }<br>Time - ${registationdata.time}<br>Time Zone - ${
              registationdata.time_zone
            }<br>Phone - ${registationdata.phone}<br><br>Email - ${
              registationdata.email
            }<br>Please ensure that you capture this information in CRM and update the status immediately.<br><br>Team Hirein5</p>`,
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
              emailTemplate,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (
            updatedata.message ===
            "User and Associated Info updated successfully"
          ) {
            dispatch(
              storeAction.onboarding_statusHander({
                onboarding_status: 2,
              })
            );
            let updatedObject = {
              ...userdata[0],
              onboarding_status: 2,
            };
            dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
            setnewloading3(false);
            setIsPage("page2");
          }
        }
      } else {
        setbottomerror(false);
        setnewloading3(true);
        const updatedItems = prefered_mode.map((item) =>
          item === "Others"
            ? otherinput.length !== 0
              ? otherinput
              : null
            : item
        );
        var new_obj1 = {
          username: registationdata.email,
          first_name: `${registationdata.first_name} ${registationdata.last_name}`,
          contact_preference_info: {
            prefered_mode: updatedItems,
            date: registationdata.date,
            time: registationdata.time,
            time_zone: registationdata.time_zone,
          },
          onboarding_status: 2,
        };
        var updatedata1 = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            new_obj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        if (
          updatedata1.message ===
          "User and Associated Info updated successfully"
        ) {
          dispatch(
            storeAction.onboarding_statusHander({
              onboarding_status: 2,
            })
          );
          let updatedObject = {
            ...userdata[0],
            onboarding_status: 2,
          };
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          setnewloading3(false);
          setIsPage("page2");
        }
      }
    } else if (isPage == "page3") {
      setregistationdataerror({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        date: false,
        time: false,
        time_zone: false,
        phoneverify: false,
        contact_preferences: false,
        prefered_mode: false,
        agree: false,
        emailVerify: false,
        budget: false,
        currency: false,
        location: false,
        lookingdata: false,
        duration: false,
        skill: false,
      });
      var values_Array = [];
      if (selectedOptionskill !== null) {
        values_Array = selectedOptionskill.map((country) => country.value);
      }
      if (lookingdata.length === 0) {
        setregistationdataerror((values) => ({ ...values, lookingdata: true }));
      } else if (value == 0) {
        setregistationdataerror((values) => ({ ...values, duration: true }));
      } else if (values_Array.length == 0) {
        setregistationdataerror((values) => ({ ...values, skill: true }));
      } else if (registationdata.budget.length == 0) {
        setregistationdataerror((values) => ({ ...values, budget: true }));
      } else if (registationdata.currency.length == 0) {
        setregistationdataerror((values) => ({ ...values, currency: true }));
      } else if (registationdata.location.length == 0) {
        setregistationdataerror((values) => ({ ...values, location: true }));
      } else {
        setIsLoading(true);
        var new_obj2 = {
          username: registationdata.email,
          requirements_info: {
            looking_for: lookingdata,
            duration: value,
            skills: values_Array,
            budget: registationdata.budget,
            currency: registationdata.currency,
            location: registationdata.location,
          },
        };
        var update_data = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
            new_obj2,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err;
          });
        if (
          update_data.message ===
          "User and Associated Info updated successfully"
        ) {
          setIsLoading(false);
          setIsPage("page4");
        }
      }
    } else {
      // setIsLoading(true);
      setIsPage(e.target.id);
    }
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const verifyHandler = async (e) => {
    setInputValues(["", "", "", "", "", ""]);
    if (e == "mobileverification") {
      if (registationdata.phone.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          phone: true,
        }));
      } else {
        setnewloading1(true);
        setregistationdataerror((values) => ({
          ...values,
          phone: false,
        }));
        if (registationdata.phone.length !== 0) {
          if (registationdata.phone.length === 10) {
            const recaptchaId = `recaptcha-${Math.random()
              .toString(36)
              .substring(2, 15)}`; // Generate unique ID
            const recaptchaContainer = document.createElement("div");
            recaptchaContainer.id = recaptchaId;
            recaptchaContainer.classList.add("forget");
            document.body.appendChild(recaptchaContainer); // Append the container to the document body

            const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
              size: "invisible",
            });

            setTimeout(() => {
              auth
                .signInWithPhoneNumber(
                  `${registationdata.country}${registationdata.phone}`,
                  verify
                )
                .then((result) => {
                  setfinal(result);

                  setresenstatus(true);
                  setbuttonenable(true);
                  // Handle verification result
                  // For example, setfinal(result) and setresenstatus(true)
                  setTimeout(() => {
                    recaptchaContainer.remove();
                    setresenstatus(false); // Remove the container after verification
                  }, 5000);
                })
                .catch((err) => {
                  // Handle error
                  // For example, display an error message
                  recaptchaContainer.remove();
                  setresenstatus(false); // Remove the container on error
                });
            }, 100);
          } else {
            setresenstatus(false);

            // Handle invalid phone number length
          }
        } else {
          setRecaptchaId(
            Array.from({ length: 6 }, () =>
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            ).join("")
          );
        }
        setnewloading1(false);
        dispatch(storeAction.isPopUpHander(e));
      }
    } else if (e == "emailverification") {
      setfinalerror(false);
      if (registationdata.email.length == 0) {
        setregistationdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else {
        setnewloading2(true);
        setregistationdataerror((values) => ({
          ...values,
          email: false,
        }));
        if (updateid == null) {
          var newobj = {
            email: registationdata.email,
            username: registationdata.email,
            password: "Password@123",
            role: 2,
          };
          var createuser = await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
              newobj
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (createuser.access_token !== undefined) {
            dispatch(storeAction.userdataHander({ userdata: [createuser] }));
            dispatch(
              storeAction.tokenHandler({ token: createuser.access_token })
            );
            setnewloading2(false);
            dispatch(storeAction.isPopUpHander(e));
            setupdateid(createuser.id);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${createuser.id}`,
              headers: {
                Authorization: `JWT ${createuser.access_token}`,
              },
            };
            axios
              .request(config)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                return error;
              });
            setstatus(true);
            setTimeout(() => {
              setstatus(false);
            }, 5000);
          } else {
            if (createuser.data.error == "User already exists") {
              var new_obj = {
                username: registationdata.email,
                email: registationdata.email,
                password: "Password@123",
              };
              var loginuser = await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/token/obtain/`,
                  new_obj
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (loginuser.access !== undefined) {
                const token = loginuser.access;
                const decoded = jwtDecode(token);
                var userinfo = await axios
                  .get(
                    `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${loginuser.access}`,
                      },
                    }
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .catch((err) => {
                    return err.response;
                  });

                if (userinfo) {
                  if (userinfo.status == null || userinfo.status.length == 0) {
                    await axios
                      .delete(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}/`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                    verifyHandler("emailverification");
                  } else {
                    setnewloading2(false);
                    setfinalerror(true);
                  }
                }
              } else {
                setnewloading2(false);
                setfinalerror(true);
              }
            }
            setnewloading2(false);
            // if (loginuser.access !== undefined) {
            //   const token = loginuser.access;
            //   const decoded = jwtDecode(token);
            //   dispatch(storeAction.tokenHandler({ token: loginuser.access }));
            //   setupdateid(decoded.user_id);
            //   let config = {
            //     method: "post",
            //     maxBodyLength: Infinity,
            //     url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${decoded.user_id}`,
            //     headers: {
            //       Authorization: `JWT ${token}`,
            //     },
            //   };
            //   var checkmail = await axios
            //     .request(config)
            //     .then((response) => {
            //       return response.data;
            //     })
            //     .catch((error) => {
            //       return error;
            //     });
            //   setstatus(true);
            //   setTimeout(() => {
            //     setstatus(false);
            //   }, 5000);
            //   if (
            //     checkmail.error == "The Admin not authorized to send this email"
            //   ) {
            //     setnewloading2(false);
            //     setfinalerror(true);
            //   } else {
            //     setnewloading2(false);
            //     dispatch(storeAction.isPopUpHander(e));
            //     var userinfo = await axios
            //       .get(
            //         `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${decoded.user_id}`,
            //         {
            //           headers: {
            //             "Content-Type": "application/json",
            //             Authorization: `JWT ${loginuser.access}`,
            //           },
            //         }
            //       )
            //       .then((res) => {
            //         return res.data;
            //       })
            //       .catch((err) => {
            //         return err.response;
            //       });

            //     dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
            //   }
            // } else {
            //   setnewloading2(false);
            //   setfinalerror(true);
            // }
          }
        } else {
          dispatch(storeAction.isPopUpHander(e));
        }
      }
    } else {
      dispatch(storeAction.isPopUpHander(e));
    }
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setregistationdata((values) => ({ ...values, [name]: value }));
  };
  const inputRefs = useRef([]);
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };
  const emailverification = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: registationdata.email,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      setemailVerify(true);
      if (userdata.length !== 0) {
        let updatedObject = {
          ...userdata[0],
          email_verification: true,
        };
        setInputValues(["", "", "", "", "", ""]);
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }
      settoastifystatus(true);
      toast.success("Email ID verified", {
        autoClose: 2000,
        transition: Slide,
        style: customToastStyle,
      });
      setTimeout(() => {
        setIsLoading(false);
        dispatch(storeAction.isPopUpHander());
      }, 2000);

      setTimeout(() => {
        settoastifystatus(false);
      }, 3000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
      setwrongotpcountemail(Number(wrongotpcountemail) + 1);
    }
  };
  const SendMail = async () => {
    setstatus(false);
    seterrorstatus(false);
    setwrongotpcountemail(0);
    setInputValues(["", "", "", "", "", ""]);
    if (updateid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${updateid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  const phoneverification = async () => {
    setIsLoading(true);
    setwrongotp(false);
    var otp = inputValues.join("");
    final
      .confirm(otp)
      .then(async (result) => {
        let data = JSON.stringify({
          username: registationdata.email,
          email: registationdata.email,
          email_verification: true,
          mobile_verification: true,
          phone: registationdata.phone,
        });
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${updateid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        var user_data = await axios
          .request(config)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
        if (
          user_data.message === "User and Associated Info updated successfully"
        ) {
          let updatedObject = {
            ...userdata[0],
            email_verification: true,
            mobile_verification: true,
            phone: registationdata.phone,
          };
          dispatch(storeAction.userdataHander({ userdata: [] }));
          setTimeout(() => {
            dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          }, 10);
        }
        settoastifystatus(true);
        setphoneVerify(true);
        toast.success("Phone number verified", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
        setTimeout(() => {
          dispatch(storeAction.isPopUpHander());
          setIsLoading(false);
        }, 2000);
        setTimeout(() => {
          settoastifystatus(false);
        }, 3000);
      })
      .catch((err) => {
        setwrongotp(true);
        setwrongotpcountphone(Number(wrongotpcountphone) + 1);
        setIsLoading(false);
      });
  };

  const handleCheckboxChange1 = (itemName) => {
    const updatedItems = prefered_mode.includes(itemName)
      ? prefered_mode.filter((data) => data !== itemName)
      : [...prefered_mode, itemName];

    setprefered_mode(updatedItems);
  };
  const handleCheckboxChange = (itemName) => {
    const updatedItems = contact_preferences.includes(itemName)
      ? contact_preferences.filter((data) => data !== itemName)
      : [...contact_preferences, itemName];

    setcontact_preferences(updatedItems);
  };

  const handlelookfor = (itemName) => {
    const updatedItems = lookingdata.includes(itemName)
      ? lookingdata.filter((data) => data !== itemName)
      : [...lookingdata, itemName];

    setlookingdata(updatedItems);
  };
  const resendbtn = async () => {
    setbuttonenable(false);
    setInputValues(["", "", "", "", "", ""]);
    setwrongotpcountphone(0);
    setwrongotp(false);
    if (registationdata.phone.length !== 0) {
      if (registationdata.phone.length === 10) {
        const recaptchaId = `recaptcha-${Math.random()
          .toString(36)
          .substring(2, 15)}`; // Generate unique ID
        const recaptchaContainer = document.createElement("div");
        recaptchaContainer.id = recaptchaId;
        recaptchaContainer.classList.add("forget");
        document.body.appendChild(recaptchaContainer); // Append the container to the document body

        const verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
          size: "invisible",
        });

        setTimeout(() => {
          auth
            .signInWithPhoneNumber(
              `${registationdata.country}${registationdata.phone}`,
              verify
            )
            .then((result) => {
              setfinal(result);

              setresenstatus(true);
              setbuttonenable(true);
              // Handle verification result
              // For example, setfinal(result) and setresenstatus(true)
              setTimeout(() => {
                recaptchaContainer.remove();
                setresenstatus(false); // Remove the container after verification
              }, 5000);
            })
            .catch((err) => {
              // Handle error
              // For example, display an error message
              recaptchaContainer.remove();
              setresenstatus(false); // Remove the container on error
            });
        }, 100);
      } else {
        setresenstatus(false);

        // Handle invalid phone number length
      }
    } else {
      setresenstatus(false);

      // Handle empty phone number
    }
  };

  useEffect(() => {
    Getskill();
  }, [Skilllist]);

  const Getskill = async () => {
    var skillarrray = Skilllist;
    const uniqueSkills = Array.from(
      new Set(skillarrray.map((skill) => skill.Skill))
    );
    if (uniqueSkills.length !== 0) {
      var filter = [];
      for (var i = 0; i < uniqueSkills.length; i++) {
        filter.push({
          value: uniqueSkills[i],
          label: uniqueSkills[i],
        });
      }
      setskilloption(filter);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const valueLabelFormat = (value) => {
    if (value == 1 || value == 0) {
      return `${value} month`;
    } else {
      return `${value} months`;
    }
  };
  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page5" || isPage === "page4") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });
  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  const continuebtn = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("https://hirein5.com/");
  };
  useEffect(() => {
    GetTokenvalidation();
  }, []);
  const GetTokenvalidation = async () => {
    if (token !== null && token.length !== 0 && userdata.length !== 0) {
      var userinfo = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (userinfo.data.detail == "Given token not valid for any token type") {
        dispatch(storeAction.reset());
        localStorage.removeItem("persist:root");
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/#/login");
      }
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isPage == "page1" && event.key === "Enter") {
        submitHandler();
      } else if (isPage == "page2" && event.key === "Enter") {
        submitHandler();
      } else if (isPage == "page3" && event.key === "Enter") {
        submitHandler();
      } else if (isPage == "page4" && event.key === "Enter") {
        continuebtn();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPage, registationdata, lookingdata, value, selectedOptionskill]);

  const closeBtn = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
  };
  return (
    <div>
      {isPage == "page1" && (
        <div className="backGround">
          <button className="logoutReg" onClick={closeBtn}>
            Close
          </button>
          <div className="newClientFlow1 sectionWidth">
            <div className="newClientFlowHead pt-5">
              <Head />
              <h2>We are excited to sign you up for Hirein5</h2>
              <h1>
                Looking to hire top talent, simply click the link below to sign
                up as a Client.
              </h1>
            </div>
            <div className="newClientFlowBody">
              <div className="newClientFlowContent">
                <div className="newClientFlowContent1">
                  {/* <h2>First & Middle Name</h2> */}
                  <input
                    type="text"
                    placeholder="First & Middle Name"
                    defaultValue={registationdata.first_name}
                    name="first_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.first_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter First & Middle Name
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  {/* <h2>Last Name</h2> */}
                  <input
                    type="text"
                    placeholder="Last name"
                    defaultValue={registationdata.last_name}
                    name="last_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.last_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Last Name
                    </p>
                  )}
                </div>
              </div>

              <div className="newClientFlowContent">
                <div className="newClientFlowContentSelect marginBottom0">
                  {/* <h2>Official Email ID</h2> */}
                  <div className="newClientFlowContentInput">
                    <input
                      className="buttonWidth85"
                      type="text"
                      placeholder="yourname@companyname.com (Official Email ID)"
                      defaultValue={registationdata.email}
                      name="email"
                      onChange={handlechange}
                      disabled={emailVerify == true}
                    />
                    <button
                      onClick={
                        emailVerify == true
                          ? null
                          : () => verifyHandler("emailverification")
                      }
                      className={
                        emailVerify == true
                          ? "newClientFlowContentSelectEnable"
                          : "newClientFlowContentSelectDisable"
                      }
                    >
                      {emailVerify == true ? (
                        <h4 className="verifybtn">
                          Verified <FiCheck />
                        </h4>
                      ) : newloading2 == false ? (
                        "Verify"
                      ) : (
                        <FiLoader className="loadingIcon" />
                      )}
                    </button>
                  </div>
                  {registationdataerror.emailVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Email ID
                    </p>
                  )}
                  {registationdataerror.email && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Official Email ID
                    </p>
                  )}
                  {finalerror && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      User already registered with email address.
                    </p>
                  )}
                </div>
              </div>
              <div className="newClientFlowContent">
                <div className="newClientFlowContentSelectnew marginBottom0 marginTop20">
                  {/* <h2>Phone number</h2> */}
                  <div className="newClientFlowContentInput">
                    <select
                      name="country"
                      defaultValue={registationdata.country}
                      onChange={handlechange}
                      // disabled={phoneVerify == false && emailVerify == false}
                    >
                      <option value="">Select</option>
                      {Countrycode.length !== 0
                        ? Countrycode.map((data, index) => (
                            <option
                              value={data.dial_code}
                              key={index}
                              selected={
                                registationdata.country == data.dial_code
                              }
                            >
                              {data.name} ({data.dial_code})
                            </option>
                          ))
                        : null}
                    </select>

                    <input
                      type="number"
                      placeholder="Your Phone no"
                      defaultValue={registationdata.phone}
                      name="phone"
                      onChange={handlechange}
                      // disabled={phoneVerify == false && emailVerify == false}
                    />
                  </div>
                  {/* <div className="newClientFlowContentInput">
                    <select
                      name="country"
                      defaultValue={registationdata.country}
                      onChange={handlechange}
                      disabled={phoneVerify == true || emailVerify == false}
                    >
                      <option value="">Select</option>
                      {Countrycode.length !== 0
                        ? Countrycode.map((data, index) => (
                            <option
                              value={data.dial_code}
                              key={index}
                              selected={
                                registationdata.country == data.dial_code
                              }
                            >
                              {data.name} ({data.dial_code})
                            </option>
                          ))
                        : null}
                    </select>
                    <input
                      type="number"
                      placeholder="Your Phone no"
                      defaultValue={registationdata.phone}
                      name="phone"
                      onChange={handlechange}
                      disabled={phoneVerify == true || emailVerify == false}
                    />
                    <button
                      disabled={emailVerify == false}
                      onClick={
                        phoneVerify == true || emailVerify == false
                          ? null
                          : () => verifyHandler("mobileverification")
                      }
                      className={
                        phoneVerify == true
                          ? "newClientFlowContentSelectEnable"
                          : "newClientFlowContentSelectDisable"
                      }
                    >
                      {phoneVerify == true ? (
                        <h4 className="verifybtn">
                          Verified <FiCheck />
                        </h4>
                      ) : newloading1 == false ? (
                        "Verify"
                      ) : (
                        <FiLoader className="loadingIcon" />
                      )}
                    </button>
                  </div> */}
                  {registationdataerror.phone && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Phone number
                    </p>
                  )}
                  {registationdataerror.phoneVerify && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Verify Phone number
                    </p>
                  )}
                  <div id={recaptchaId} className="forget"></div>
                </div>
              </div>
              <div className="newClientFlowContent mt-5">
                <div className="newClientFlowContent1">
                  {/* <h2>First & Middle Name</h2> */}
                  <input
                    type="text"
                    placeholder="Company Name"
                    defaultValue={registationdata.company_name}
                    name="company_name"
                    onChange={handlechange}
                  />
                  {registationdataerror.company_name && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Company Name
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent1">
                  {/* <h2>Last Name</h2> */}
                  <select
                    className="newClientFlowContent1Select"
                    type="text"
                    placeholder="Company Location"
                    defaultValue={registationdata.company_location}
                    name="company_location"
                    onChange={handlechange}
                  >
                    <option value="">Company Location</option>
                    {country_and_states.country.length !== 0
                      ? country_and_states.country.map((item, index) => (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  {registationdataerror.company_location && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Company Location
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="newClientFlowBody">
              <div className="newClientFlowContentPreferece">
                <h3>Contact preferences</h3>
                <div
                  className={
                    contact_preferences.includes("Schedule a personalized Demo")
                      ? "newClientFlowContentPreferece1 borderBottomRadius"
                      : "newClientFlowContentPreferece1"
                  }
                >
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxChange("Schedule a personalized Demo")
                    }
                    checked={contact_preferences.includes(
                      "Schedule a personalized Demo"
                    )}
                  />
                  <h4
                    onClick={() =>
                      handleCheckboxChange("Schedule a personalized Demo")
                    }
                  >
                    Preferred modes of contact
                  </h4>
                </div>
                {contact_preferences.includes(
                  "Schedule a personalized Demo"
                ) && (
                  <>
                    <div className="contactMode">
                      {/* <h2>Preferred modes of contact</h2> */}
                      <div className="contactModeFlex">
                        <div className="newClientFlowContentPreferece1 mr-4">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange1("Phone")}
                            checked={prefered_mode.includes("Phone")}
                          />
                          <h4
                            className="fontWeight500"
                            onClick={() => {
                              handleCheckboxChange1("Phone");
                            }}
                          >
                            Phone
                          </h4>
                        </div>
                        <div className="newClientFlowContentPreferece1 mr-4">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange1("Whatsapp")}
                            checked={prefered_mode.includes("Whatsapp")}
                          />
                          <h4
                            className="fontWeight500"
                            onClick={() => {
                              handleCheckboxChange1("Whatsapp");
                            }}
                          >
                            Whatsapp
                          </h4>
                        </div>
                        <div className="newClientFlowContentPreferece1">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange1("Others")}
                            checked={prefered_mode.includes("Others")}
                          />

                          <h4
                            className="fontWeight500"
                            onClick={() => {
                              handleCheckboxChange1("Others");
                            }}
                          >
                            Others
                          </h4>
                        </div>
                      </div>
                      {prefered_mode.includes("Others") && (
                        <div className="my-2">
                          <h2 className="otherlabel">Others</h2>
                          <input
                            placeholder="Others"
                            onChange={(e) => {
                              setotherinput(e.target.value);
                            }}
                            className="contactModeFlexInput mr-4"
                          />
                        </div>
                      )}
                      {registationdataerror.prefered_mode && (
                        <p className="text-red-500 text-xs font-semibold my-2">
                          Please Select Preferred modes of contact
                        </p>
                      )}
                      {/* <h2>Preferred date and time to connect</h2> */}
                      <div className="contactModeFlex1">
                        <div className="w-full">
                          <input
                            className="contactModeFlexInput mr-4"
                            type="date"
                            defaultValue={registationdata.date}
                            name="date"
                            min="1000-01-01"
                            max="9999-12-31"
                            onChange={handlechange}
                          />
                          {registationdataerror.date && (
                            <p className="text-red-500 text-xs font-semibold my-2">
                              Please Select Preferred date
                            </p>
                          )}
                        </div>
                        <div className="w-full">
                          <input
                            className="contactModeFlexInput"
                            type="time"
                            defaultValue={registationdata.time}
                            name="time"
                            onChange={handlechange}
                          />
                          {registationdataerror.time && (
                            <p className="text-red-500 text-xs font-semibold my-2">
                              Please Select Preferred time
                            </p>
                          )}
                        </div>
                        <div className="w-full">
                          <select
                            type="text"
                            className="contactModeFlexInput"
                            defaultValue={registationdata.time_zone}
                            name="time_zone"
                            onChange={handlechange}
                          >
                            <option value="">Select</option>
                            <option value="(GMT-11:00) Pacific/Midway">
                              (GMT-11:00) Pacific/Midway
                            </option>
                            <option value="(GMT-11:00) Pacific/Niue">
                              (GMT-11:00) Pacific/Niue
                            </option>
                            <option value="(GMT-11:00) Pacific/Pago_Pago">
                              (GMT-11:00) Pacific/Pago_Pago
                            </option>
                            <option value="(GMT-10:00) Pacific/Honolulu">
                              (GMT-10:00) Pacific/Honolulu
                            </option>
                            <option value="(GMT-10:00) Pacific/Rarotonga">
                              (GMT-10:00) Pacific/Rarotonga
                            </option>
                            <option value="(GMT-10:00) Pacific/Tahiti">
                              (GMT-10:00) Pacific/Tahiti
                            </option>
                            <option value="(GMT-09:30) Pacific/Marquesas">
                              (GMT-09:30) Pacific/Marquesas
                            </option>
                            <option value="(GMT-09:00) America/Anchorage">
                              (GMT-09:00) America/Anchorage
                            </option>
                            <option value="(GMT-09:00) Pacific/Gambier">
                              (GMT-09:00) Pacific/Gambier
                            </option>
                            <option value="(GMT-08:00) America/Los_Angeles">
                              (GMT-08:00) America/Los_Angeles
                            </option>
                            <option value="(GMT-08:00) America/Tijuana">
                              (GMT-08:00) America/Tijuana
                            </option>
                            <option value="(GMT-08:00) America/Vancouver">
                              (GMT-08:00) America/Vancouver
                            </option>
                            <option value="(GMT-07:00) America/Denver">
                              (GMT-07:00) America/Denver
                            </option>
                            <option value="(GMT-07:00) America/Phoenix">
                              (GMT-07:00) America/Phoenix
                            </option>
                            <option value="(GMT-06:00) America/Chicago">
                              (GMT-06:00) America/Chicago
                            </option>
                            <option value="(GMT-06:00) America/Mexico_City">
                              (GMT-06:00) America/Mexico_City
                            </option>
                            <option value="(GMT-06:00) America/Regina">
                              (GMT-06:00) America/Regina
                            </option>
                            <option value="(GMT-05:00) America/New_York">
                              (GMT-05:00) America/New_York
                            </option>
                            <option value="(GMT-05:00) America/Toronto">
                              (GMT-05:00) America/Toronto
                            </option>
                            <option value="(GMT-05:00) America/Bogota">
                              (GMT-05:00) America/Bogota
                            </option>
                            <option value="(GMT-04:30) America/Caracas">
                              (GMT-04:30) America/Caracas
                            </option>
                            <option value="(GMT-04:00) America/Halifax">
                              (GMT-04:00) America/Halifax
                            </option>
                            <option value="(GMT-04:00) America/Santiago">
                              (GMT-04:00) America/Santiago
                            </option>
                            <option value="(GMT-03:30) America/St_Johns">
                              (GMT-03:30) America/St_Johns
                            </option>
                            <option value="(GMT-03:00) America/Argentina/Buenos_Aires">
                              (GMT-03:00) America/Argentina/Buenos_Aires
                            </option>
                            <option value="(GMT-02:00) Atlantic/South_Georgia">
                              (GMT-02:00) Atlantic/South_Georgia
                            </option>
                            <option value="(GMT-01:00) Atlantic/Azores">
                              (GMT-01:00) Atlantic/Azores
                            </option>
                            <option value="(GMT+00:00) Europe/London">
                              (GMT+00:00) Europe/London
                            </option>
                            <option value="(GMT+00:00) Africa/Abidjan">
                              (GMT+00:00) Africa/Abidjan
                            </option>
                            <option value="(GMT+01:00) Europe/Paris">
                              (GMT+01:00) Europe/Paris
                            </option>
                            <option value="(GMT+01:00) Africa/Algiers">
                              (GMT+01:00) Africa/Algiers
                            </option>
                            <option value="(GMT+02:00) Europe/Athens">
                              (GMT+02:00) Europe/Athens
                            </option>
                            <option value="(GMT+02:00) Africa/Johannesburg">
                              (GMT+02:00) Africa/Johannesburg
                            </option>
                            <option value="(GMT+03:00) Europe/Moscow">
                              (GMT+03:00) Europe/Moscow
                            </option>
                            <option value="(GMT+03:00) Asia/Baghdad">
                              (GMT+03:00) Asia/Baghdad
                            </option>
                            <option value="(GMT+03:30) Asia/Tehran">
                              (GMT+03:30) Asia/Tehran
                            </option>
                            <option value="(GMT+04:00) Asia/Dubai">
                              (GMT+04:00) Asia/Dubai
                            </option>
                            <option value="(GMT+04:30) Asia/Kabul">
                              (GMT+04:30) Asia/Kabul
                            </option>
                            <option value="(GMT+05:00) Asia/Karachi">
                              (GMT+05:00) Asia/Karachi
                            </option>
                            <option value="(GMT+05:30) Asia/Kolkata">
                              (GMT+05:30) Asia/Kolkata
                            </option>
                            <option value="(GMT+05:45) Asia/Kathmandu">
                              (GMT+05:45) Asia/Kathmandu
                            </option>
                            <option value="(GMT+06:00) Asia/Dhaka">
                              (GMT+06:00) Asia/Dhaka
                            </option>
                            <option value="(GMT+06:30) Asia/Yangon">
                              (GMT+06:30) Asia/Yangon
                            </option>
                            <option value="(GMT+07:00) Asia/Bangkok">
                              (GMT+07:00) Asia/Bangkok
                            </option>
                            <option value="(GMT+08:00) Asia/Hong_Kong">
                              (GMT+08:00) Asia/Hong_Kong
                            </option>
                            <option value="(GMT+09:00) Asia/Tokyo">
                              (GMT+09:00) Asia/Tokyo
                            </option>
                            <option value="(GMT+09:30) Australia/Darwin">
                              (GMT+09:30) Australia/Darwin
                            </option>
                            <option value="(GMT+10:00) Australia/Sydney">
                              (GMT+10:00) Australia/Sydney
                            </option>
                            <option value="(GMT+11:00) Pacific/Noumea">
                              (GMT+11:00) Pacific/Noumea
                            </option>
                            <option value="(GMT+12:00) Pacific/Fiji">
                              (GMT+12:00) Pacific/Fiji
                            </option>
                            <option value="(GMT+13:00) Pacific/Tongatapu">
                              (GMT+13:00) Pacific/Tongatapu
                            </option>
                            <option value="(GMT+14:00) Pacific/Kiritimati">
                              (GMT+14:00) Pacific/Kiritimati
                            </option>
                          </select>
                          {registationdataerror.time_zone && (
                            <p className="text-red-500 text-xs font-semibold my-2">
                              Please Select Time Zone
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="contactModeFlexAgree marginTop20">
                        <input
                          type="checkbox"
                          onClick={() => {
                            setagree(!agree);
                          }}
                          checked={agree == true}
                        />
                        <h4>
                          Agree to be contacted by Hirein5 for onboarding
                          purposes. We value your privacy and will never share
                          your information without your consent.
                        </h4>
                      </div>
                      {registationdataerror.agree && (
                        <p className="text-red-500 text-xs font-semibold mt-2">
                          Please Accept Terms & Condition
                        </p>
                      )}
                    </div>
                  </>
                )}
                <div className="newClientFlowContentPreferece1">
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxChange("Schedule Hirein5 Demo")
                    }
                    checked={contact_preferences.includes(
                      "Schedule Hirein5 Demo"
                    )}
                  />
                  <h4>
                    Schedule Hirein5 Demo{" "}
                    <a
                      onClick={() =>
                        handleCheckboxChange("Schedule Hirein5 Demo")
                      }
                    >
                      Click here
                    </a>
                  </h4>
                </div>
                {registationdataerror.contact_preferences && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Select Contact preferences
                  </p>
                )}
              </div>
            </div>
            {contact_preferences.includes("Schedule Hirein5 Demo") && (
              // <iframe
              //   width="100%"
              //   height="750px"
              //   src="https://hirein51.zohobookings.in/portal-embed#/221623000000029054"
              //   frameborder="0"
              //   allowfullscreen=""
              // ></iframe>
              <div className="calendly">
                <InlineWidget url="https://calendly.com/hirein5" />
              </div>
            )}
            <div className="candidateBottom buttonWidth100 mt-5">
              <button className="Agree mb-5">
                <input
                  type="checkbox"
                  onClick={() => {
                    setagree1(!agree1);
                  }}
                  checked={agree1 == true}
                />
                I agree to the Hirein5 <span>terms & conditions </span> and{" "}
                <span> privacy policy</span>
              </button>
              {bottomerror && (
                <p className="text-red-500 text-sm font-semibold mb-2 text-start">
                  Please complete all the mandatory fields.
                </p>
              )}
              {newloading3 === false ? (
                <button
                  className={
                    agree1 == true
                      ? "nextbtn buttonWidth100"
                      : "nextbtndisable buttonWidth100"
                  }
                  id="page2"
                  onClick={submitHandler}
                >
                  Next
                </button>
              ) : (
                <button
                  id="Signup"
                  className="signUpCompBodyButtonLoading buttonWidth100"
                >
                  <FiLoader className="loadingIcon" />
                </button>
              )}
              <div className="newClientFlowSupportnew">
                <h5>
                  Not a Client?{" "}
                  <a href="/#/candidateregister">
                    Click here for Candidate Signup
                  </a>
                </h5>
              </div>
            </div>
            <div className="newClientFlowSupport">
              <h5>
                For any assistance, please contact
                <a href="">gohire@hirein5.com</a>
              </h5>
            </div>
            <div className="newClientFlowTermsAndConditions">
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                    "_blank"
                  );
                }}
              >
                Terms & Conditions
              </h6>
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                    "_blank"
                  );
                }}
              >
                Privacy Policy
              </h6>
            </div>
          </div>
        </div>
      )}
      {isPage == "page2" && (
        <div className="backGround">
          <button className="logoutReg" onClick={closeBtn}>
            Close
          </button>
          <div className="newClientFlow height100vh sectionWidth">
            <div className="newClientFlowHead displayFlex">
              <Head />
              <div className="">
                <SuccessResponse
                  title="Your call has been successfully set up! "
                  des="Please check your email to find the relevant details 
                  Thank you for your interest in Hirein5 platform. We will connect with you shortly to begin hiring in 5!"
                />
                <div className="ShareReqiurementSection">
                  <div className="ShareReqiurementSectionContent">
                    <p>
                      To help you better, please fill in your specific
                      requirements
                    </p>
                  </div>
                  <button id="page3" onClick={submitHandler}>
                    Share Requirement
                  </button>
                </div>
                <h5
                  className="skipButton"
                  onClick={() => {
                    setIsPage("page5");
                  }}
                >
                  Skip
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPage == "page3" && (
        <div className="backGround">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow sectionWidth">
            <div className="newClientFlowHead">
              <Head />
              <h1>To help you better, please answer these questions</h1>
            </div>
            <div className="newClientFlowBody">
              <div className="newClientFlowContentPreferece">
                <h2>What are you looking for</h2>
                <div className="newClientFlowContentPreferece1">
                  <input
                    type="checkbox"
                    onChange={() => handlelookfor("Full-time remote resources")}
                    checked={lookingdata.includes("Full-time remote resources")}
                  />
                  <h4
                    onClick={() => handlelookfor("Full-time remote resources")}
                  >
                    Full-time remote resources
                  </h4>
                </div>
                <div className="newClientFlowContentPreferece1">
                  <input
                    type="checkbox"
                    onChange={() => handlelookfor("Part-time remote resources")}
                    checked={lookingdata.includes("Part-time remote resources")}
                  />
                  <h4
                    onClick={() => handlelookfor("Part-time remote resources")}
                  >
                    Part-time remote resources
                  </h4>
                </div>
                <div className="newClientFlowContentPreferece1">
                  <input
                    type="checkbox"
                    onChange={() =>
                      handlelookfor("Onsite resources (at company office)")
                    }
                    checked={lookingdata.includes(
                      "Onsite resources (at company office)"
                    )}
                  />
                  <h4
                    onClick={() =>
                      handlelookfor("Onsite resources (at company office)")
                    }
                  >
                    Onsite resources (at company office)
                  </h4>
                </div>
                {registationdataerror.lookingdata && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Select What are you looking for
                  </p>
                )}
                <div className="newClientFlow_Content marginTop15">
                  <h2>Duration of engagement for full-time in months</h2>
                  <Box sx={{ width: 385 }}>
                    <Slider
                      value={value}
                      onChange={handleChange}
                      min={3}
                      max={24}
                      // step={24 / 1}
                      valueLabelDisplay="auto"
                      valueLabelFormat={valueLabelFormat}
                    />
                  </Box>
                  {registationdataerror.duration && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Select Duration
                    </p>
                  )}
                </div>
                <div className="newClientFlow_Content marginTop15">
                  <h2>Skills interested in hiring</h2>
                  <Select
                    defaultValue={selectedOptionskill}
                    onChange={setSelectedOptionskill}
                    options={skilloption}
                    isMulti
                  />
                  {registationdataerror.skill && (
                    <p className="text-red-500 text-xs font-semibold mt-2">
                      Please Select Skills
                    </p>
                  )}
                </div>
                <div className="newClientFlowContent marginTop15">
                  <div className="newClientFlowContent1 marginBottom0">
                    <h2>Monthly budget (per resource)</h2>
                    <div className="newClientFlowContent2">
                      <input
                        type="text"
                        placeholder="Monthly budget"
                        defaultValue={registationdata.budget}
                        name="budget"
                        onChange={handlechange}
                      />
                      <select
                        defaultValue={registationdata.currency}
                        name="currency"
                        onChange={handlechange}
                      >
                        <option value="">Select</option>
                        <option value="USD">USD</option>
                        <option value="INR">INR</option>
                        <option value="JPY">JPY</option>
                        <option value="SGD">SGD</option>
                        <option value="GBP">GBP</option>
                        <option value="AED">AED</option>
                        <option value="CAD">CAD</option>
                        <option value="SAR">SAR</option>
                      </select>
                    </div>
                    {registationdataerror.budget && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Enter Monthly budget
                      </p>
                    )}
                    {registationdataerror.currency && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Select currency
                      </p>
                    )}
                  </div>
                  <div className="newClientFlowContent1 marginBottom0">
                    <h2>Your current location</h2>
                    <select
                      className="newClientFlowContent1Select"
                      type="text"
                      placeholder="Select Country"
                      defaultValue={registationdata.location}
                      name="location"
                      onChange={handlechange}
                    >
                      <option value="">Select Country</option>
                      {country_and_states.country.length !== 0
                        ? country_and_states.country.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </select>
                    {registationdataerror.location && (
                      <p className="text-red-500 text-xs font-semibold mt-2">
                        Please Select Your current location
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="QuestionsSubmitButton">
              <button
                id="page5"
                onClick={() => {
                  setIsPage("page5");
                }}
                className="skipDisable"
              >
                Skip
              </button>
              {isLoading == true ? (
                <button id="page4" className="SubmitEnable">
                  <FiLoader className="loadingIcon" />
                </button>
              ) : (
                <button
                  onClick={submitHandler}
                  id="page4"
                  className="SubmitEnable"
                >
                  Submit
                </button>
              )}
            </div>
            <div className="newClientFlowSupport">
              <h5>
                For any assistance, please contact
                <a href="">gohire@hirein5.com</a>
              </h5>
            </div>
            <div className="newClientFlowTermsAndConditions">
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                    "_blank"
                  );
                }}
              >
                Terms & Conditions
              </h6>
              <h6
                onClick={() => {
                  window.open(
                    "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                    "_blank"
                  );
                }}
              >
                Privacy Policy
              </h6>
            </div>
          </div>
        </div>
      )}
      {isPage == "page4" && (
        <div className="backGround">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow height100vh sectionWidth">
            <div className="newClientFlowHead displayFlex">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Success! "
                  des="Your requirements have been noted. We will connect with you shortly."
                  des1="Team Hirein5"
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPage == "page5" && (
        <div className="backGround">
          <button className="logoutReg" onClick={continuebtn}>
            Close
          </button>
          <div className="newClientFlow height100vh sectionWidth">
            <div className="newClientFlowHead displayFlex">
              <Head />
              <div className="newClientSuccessResponse">
                <SuccessResponse
                  title="Thank you! "
                  des="You’ve shared your details. Our sales team will contact you within 1 business day."
                  des1="Team Hirein5"
                />
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="continue marginTop20 marginBottom20"
                >
                  Continue
                </button>
                <h6 className="paymentTimer text-center mt-3">
                  Redirecting you to the next screen in{" "}
                  <span className="emailDarks">{formattedTime}s</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPopUp == "emailverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Email ID</h1>
            <p>
              Enter the 6-digit code we’ve sent to your Email Address
              <span>{registationdata.email}</span>
            </p>
            {status && (
              <p className="successerror">
                The OTP has been successfully sent to your email address.
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>

            {wrongotpcountemail !== 5 ? (
              errorstatus && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountemail}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountemail !== 5 ? (
              isLoading === false ? (
                <button className="mobVerifyButton" onClick={emailverification}>
                  Verify
                </button>
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive the email? Check your Spam folder or{" "}
              <span
                onClick={() => {
                  SendMail();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}
      {isPopUp == "mobileverification" && (
        <>
          <div className="newClientFlowVerification overlay">
            <h1>Verify Phone No.</h1>
            <p>
              Enter the 6-digit code we’ve sent to your phone number
              <span>
                {registationdata.country} {registationdata.phone}
              </span>
            </p>
            {resenstatus && (
              <p className="successerror">
                The OTP has been successfully sent to your phone number
              </p>
            )}
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  className="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>
            {wrongotpcountphone !== 5 ? (
              wrongotp && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcountphone}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}
            {wrongotpcountphone !== 5 ? (
              isLoading === false ? (
                buttonenable ? (
                  <button
                    className="mobVerifyButton"
                    onClick={phoneverification}
                  >
                    Verify
                  </button>
                ) : (
                  <button className="mobVerifyButtondisable">Verify</button>
                )
              ) : (
                <button className="mobVerifyButton">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : (
              <button className="mobVerifyButtondisable">Verify</button>
            )}

            <h6>
              Didn’t receive OTP?
              <span
                onClick={() => {
                  resendbtn();
                }}
              >
                Resend
              </span>
            </h6>
            <div className="close">
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
          </div>
        </>
      )}
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewClientFlow;

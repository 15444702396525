/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { storeAction } from "../../../Store/Store";
import * as XLSX from "xlsx";
import SampleFile from "./samplelist.xlsx";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CandidateScreening = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const isPopUp = useSelector((store) => store.isPopUp);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      recruiter: "",
      first_name: "",
      last_name: "",
      contact: "",
      email: "",
      primary_skill: "",
      experience: "",
      offline_screen1_outcome: "",
      call_date1: "",
      call_date2: "",
      candidate_interest: "",
      reasons_for_decline: "",
      comments: "",
      email_candidate: "",
      role: "",
      selected: "false",
      isNew: true,
      international_clients: "",
      current_ctc: "",
      expected_ctc: "",
      notice_period: "",
      certifications: "",
      location: "",
      current_company: "",
      current_designation: "",
      isFrom: "local",
    },
  ]);
  const [allteamlist, setallteamlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [mailloading, setmailloading] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [selectall, setselectall] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [xldata, setxldata] = useState([]);
  const [status, setstatus] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reasonFilter, setReasonFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [toastifystatus, settoastifystatus] = useState(false);
  const [fromDateexport, setFromDateexport] = useState("");
  const [toDateexport, setToDateexport] = useState("");
  const [searchname, setsearchname] = useState("");
  const [searchfilter, setsearchfilter] = useState(false);
  const [searchloading, setsearchloading] = useState(false);

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  const addCandidate = () => {
    const newCandidate = {
      id: uuidv4(),
      recruiter: "",
      first_name: "",
      last_name: "",
      contact: "",
      email: "",
      primary_skill: "",
      experience: "",
      offline_screen1_outcome: "",
      call_date1: "",
      call_date2: "",
      candidate_interest: "",
      reasons_for_decline: "",
      comments: "",
      email_candidate: "",
      role: "",
      selected: "false",
      isNew: true,
      international_clients: "",
      current_company: "",
      current_designation: "",
      current_ctc: "",
      expected_ctc: "",
      notice_period: "",
      certifications: "",
      location: "",
      isFrom: "local",
    };

    setteamlist([...teamlist, newCandidate]);
  };

  const removeCandidate = async (candidateId) => {
    const updatedTeamlist = [];
    await Promise.all(
      teamlist.map(async (candidate) => {
        if (candidate.isFrom === "local" && candidate.id !== candidateId) {
          updatedTeamlist.push(candidate);
        }
        if (candidate.isFrom !== "local") {
          if (candidate.id !== candidateId) {
            updatedTeamlist.push(candidate);
          } else {
            try {
              await axios.delete(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/delete/candidateScreen/${candidateId}/`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              );
            } catch (error) {
              console.error(
                "Error deleting candidate:",
                error.response || error
              );
            }
          }
        }
      })
    );
    setteamlist(updatedTeamlist);
  };

  const submitbtn = async () => {
    if (teamlist.length === 0) return;
    setloading(true);
    settoastifystatus(true);
    dispatch(storeAction.isPopUpHander("loading"));

    const loadingToastId = toast.info("Please wait..", {
      autoClose: false,
      transition: Slide,
      style: customToastStyle,
    });

    try {
      const apiCalls = teamlist.map(async (candidate) => {
        const newCandidateData = {
          recruiter: candidate.recruiter,
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          contact: candidate.contact,
          email: candidate.email,
          primary_skill: candidate.primary_skill,
          experience: candidate.experience,
          offline_screen1_outcome: candidate.offline_screen1_outcome,
          call_date1: candidate.call_date1,
          call_date2: candidate.call_date2,
          candidate_interest: candidate.candidate_interest,
          reasons_for_decline: candidate.reasons_for_decline,
          comments: candidate.comments,
          email_candidate: candidate.email_candidate,
          selected: candidate.selected,
          role: candidate.role,
          international_clients: candidate.international_clients,
          current_ctc: candidate.current_ctc,
          expected_ctc: candidate.expected_ctc,
          notice_period: candidate.notice_period,
          certifications: candidate.certifications,
          location: candidate.location,
          current_company: candidate.current_company,
          current_designation: candidate.current_designation,
        };

        const url = `${process.env.REACT_APP_LOCAL_HOST_URL}/${
          candidate.isNew
            ? "create/candidateScreen/"
            : `update/candidateScreen/${candidate.id}/`
        }`;

        const method = candidate.isNew ? axios.post : axios.put;

        await method(url, newCandidateData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        });

        candidate.isNew = false; // Mark as updated
      });

      await Promise.all(apiCalls); // Execute all API calls in parallel

      setTimeout(() => toast.dismiss(loadingToastId), 1000);
      toast.success("Data Updated Successfully...", {
        autoClose: 5000,
        transition: Slide,
        style: customToastStyle,
      });
      Getalldata();
    } catch (err) {
      toast.error("Failed to update data. Please try again.", {
        autoClose: 5000,
        transition: Slide,
        style: customToastStyle,
      });
    } finally {
      setloading(false);
      settoastifystatus(false);
      dispatch(storeAction.isPopUpHander());
    }
  };

  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };

  const Sendmail = async () => {
    setmailloading(true);
    settoastifystatus(true);
    dispatch(storeAction.isPopUpHander("loading"));

    const loadingToastId = toast.info("Please Wait - Mail Sending...", {
      autoClose: false,
      transition: Slide,
      style: customToastStyle,
    });

    try {
      const eligibleCandidates = teamlist.filter(
        (candidate) =>
          candidate.selected === "true" &&
          (!candidate.email_candidate || candidate.email_candidate === "No")
      );

      // Ensure all API calls include both email sending and updating
      const apiCalls = eligibleCandidates.map(async (candidate) => {
        const emailobj1 = {
          email: candidate.email,
          from_email: "connect@hirein5.com",
          subject: "Invitation to Register on Our Portal - Hirein5",
          message: `
            <p>Dear ${candidate.first_name} ${candidate.last_name},</p>
            <p>We hope this message finds you well. We are excited to invite you to register on our candidate portal to take advantage of the opportunities and resources we have to offer. By creating a profile, you'll be able to stay updated with the latest job openings, track your application status, and engage with our team seamlessly.</p>
            <p>Please click the link below to complete your registration:</p>
            <p><b>Registration Link:</b> <a href="https://app.hirein5.com/#/candidateregister" target="_blank">Click Here</a></p>
            <p>If you have any questions or need further assistance, feel free to reach out.</p>
            <p>We look forward to seeing your profile on our portal and helping you find your next career opportunity!</p>
            <br>
            <p>Best regards,</p>
            <p>The Hirein5 Team</p>
          `,
        };

        try {
          // Send email
          const emailResponse = await axios.post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
            emailobj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );

          if (emailResponse.data?.success === "Email Sent Successfully!!!") {
            candidate.email_candidate = "Yes";

            const updatedCandidateData = {
              recruiter: candidate.recruiter,
              first_name: candidate.first_name,
              last_name: candidate.last_name,
              contact: candidate.contact,
              email: candidate.email,
              primary_skill: candidate.primary_skill,
              experience: candidate.experience,
              offline_screen1_outcome: candidate.offline_screen1_outcome,
              call_date1: candidate.call_date1,
              call_date2: candidate.call_date2,
              candidate_interest: candidate.candidate_interest,
              reasons_for_decline: candidate.reasons_for_decline,
              comments: candidate.comments,
              email_candidate: candidate.email_candidate,
              selected: candidate.selected,
              role: candidate.role,
              international_clients: candidate.international_clients,
              current_ctc: candidate.current_ctc,
              expected_ctc: candidate.expected_ctc,
              notice_period: candidate.notice_period,
              certifications: candidate.certifications,
              location: candidate.location,
              current_company: candidate.current_company,
              current_designation: candidate.current_designation,
            };

            // Update candidate
            await axios.put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/update/candidateScreen/${candidate.id}/`,
              updatedCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            );
          }
        } catch (error) {
          console.error(
            `Failed to process candidate ${candidate.email}:`,
            error
          );
          throw error; // Re-throw to handle it in Promise.all
        }
      });

      // Ensure all API calls are completed
      await Promise.all(apiCalls);

      toast.dismiss(loadingToastId);
      toast.success("Bulk Mail Sent Successfully...", {
        autoClose: 5000,
        transition: Slide,
        style: customToastStyle,
      });
      Getalldata();
    } catch (error) {
      console.error("Error in sending mails or updating records:", error);
      toast.error(
        "An error occurred while sending mails or updating records.",
        {
          autoClose: 5000,
          transition: Slide,
          style: customToastStyle,
        }
      );
    } finally {
      dispatch(storeAction.isPopUpHander());
      setTimeout(() => {
        settoastifystatus(false);
        setmailloading(false);
        Getalldata();
        setselectall(false);
      }, 3000);
    }
  };

  const resendBtn = async (role) => {
    setmailloading(true);
    settoastifystatus(true);
    dispatch(storeAction.isPopUpHander("loading"));
    const emailobj1 = {
      email: role.email,
      from_email: "connect@hirein5.com",
      subject: "Invitation to Register on Our Portal - Hirein5",
      message: `
        <p>Dear ${role.first_name} ${role.last_name},</p>
        <p>We hope this message finds you well. We are excited to invite you to register on our candidate portal to take advantage of the opportunities and resources we have to offer. By creating a profile, you'll be able to stay updated with the latest job openings, track your application status, and engage with our team seamlessly.</p>
        <p>Please click the link below to complete your registration:</p>
        <p><b>Registration Link:</b> <a href="https://app.hirein5.com/#/candidateregister" target="_blank">Click Here</a></p>
        <p>If you have any questions or need further assistance, feel free to reach out.</p>
        <p>We look forward to seeing your profile on our portal and helping you find your next career opportunity!</p>
        <br>
        <p>Best regards,</p>
        <p>The Hirein5 Team</p>
      `,
    };

    await axios.post(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
      emailobj1,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    settoastifystatus(true);
    toast.success("Mail Sent Successfully...", {
      autoClose: 5000,
      transition: Slide,
      style: customToastStyle,
    });
    setTimeout(() => {
      settoastifystatus(false);
      dispatch(storeAction.isPopUpHander());
      setmailloading(false);
      settoastifystatus(false);
    }, 2000);
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/list/candidateScreen/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      setsearchfilter(false);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: false,
        isFrom: "db",
      }));
      setteamlist(updatedData);
      setallteamlist(updatedData);
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  const selectAll = () => {
    setteamlist(
      selectall == false
        ? teamlist.map((candidate) => ({
            ...candidate,
            selected:
              candidate.email_candidate === "Yes" ? candidate.selected : "true",
          }))
        : teamlist.map((candidate) => ({
            ...candidate,
            selected:
              candidate.email_candidate === "Yes"
                ? candidate.selected
                : "false",
          }))
    );
    setselectall(!selectall);
  };

  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        cellDates: true,
      });
      if (jsonData.length !== 0) {
        setTimeout(() => {
          setxldata(jsonData);
        }, 2000);
      }
    };
    reader.readAsBinaryString(file);
  };

  const submit_btn = () => {
    if (xldata.length !== 0) {
      setclicked(true);
      var finalarray = [];
      for (var i = 1; i < xldata.length; i++) {
        if (xldata[i].length !== 0) {
          const callDate1 =
            typeof xldata[i][17] === "number"
              ? new Date((xldata[i][17] - 25569) * 86400 * 1000)
                  .toISOString()
                  .split("T")[0]
              : xldata[i][17];
          const callDate2 =
            typeof xldata[i][18] === "number"
              ? new Date((xldata[i][18] - 25569) * 86400 * 1000)
                  .toISOString()
                  .split("T")[0]
              : xldata[i][18];

          var newobj = {
            recruiter: xldata[i][0],
            first_name: xldata[i][1],
            last_name: xldata[i][2],
            role: xldata[i][3],
            contact: xldata[i][4],
            email: xldata[i][5],
            primary_skill: xldata[i][6],
            experience: xldata[i][7],
            current_company: xldata[i][8],
            current_designation: xldata[i][9],
            location: xldata[i][10],
            international_clients: xldata[i][11],
            current_ctc: xldata[i][12],
            expected_ctc: xldata[i][13],
            notice_period: xldata[i][14],
            certifications: xldata[i][15],
            offline_screen1_outcome: xldata[i][16],
            call_date1: callDate1,
            call_date2: callDate2,
            candidate_interest: xldata[i][19],
            reasons_for_decline: xldata[i][20],
            comments: xldata[i][21],
            isNew: true,
            id: uuidv4(),
            isFrom: "local",
          };
          finalarray.push(newobj);
        }
      }
      setstatus(true);
      setteamlist((oldList) => {
        const updatedFinalArray = [...oldList, ...finalarray];
        return updatedFinalArray;
      });
      setallteamlist((oldList1) => {
        const updatedFinalArray = [...oldList1, ...finalarray];
        return updatedFinalArray;
      });
      setTimeout(() => {
        setstatus(false);
        setclicked(false);
        setxldata([]);
        dispatch(storeAction.isPopUpHander());
      }, 3000);
    }
  };

  const handleDateChange = (type, value) => {
    if (type === "from") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const filterByDate = (data) => {
    if (!fromDate && !toDate) return data; // If no dates are selected, return all data

    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    return data.filter((candidate) => {
      const callDate = new Date(candidate.call_date1 || candidate.call_date2);
      return (!from || callDate >= from) && (!to || callDate <= to);
    });
  };

  const handleReasonFilterChange = (value) => {
    setReasonFilter(value);
    if (value) {
      const updatedData = allteamlist.filter(
        (role) =>
          role.reasons_for_decline &&
          role.reasons_for_decline.toLowerCase().includes(value.toLowerCase())
      );
      setteamlist(updatedData);
    } else {
      Getalldata();
    }
  };

  const handleRecruiterFilterChange = (value) => {
    setReasonFilter(value);
    if (value) {
      const updatedData = allteamlist.filter(
        (role) =>
          role.recruiter &&
          role.recruiter.toLowerCase().includes(value.toLowerCase())
      );
      setteamlist(updatedData);
    } else {
      Getalldata();
    }
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    if (value) {
      const updatedData = allteamlist.filter(
        (role) =>
          role.status && role.status.toLowerCase() === value.toLowerCase()
      );
      setteamlist(updatedData);
    } else {
      Getalldata();
    }
  };

  const exportBtn = async () => {
    var obj = {
      from_date: fromDateexport,
      to_date: toDateexport,
    };
    setclicked(true);
    const Alldata = await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/search/candidateScreen/lists/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
    if (Alldata.length !== 0) {
      const manipulatedData = Alldata.map((item) => {
        return {
          // S_No: index + 1,
          Recruiter: item.recruiter,
          First_Name: item.first_name,
          Last_Name: item.last_name,
          role: item.role,
          Phone: item.contact,
          Email: item.email,
          Primary_Skill: item.primary_skill,
          Experience: item.experience,
          Offline_Screen1_Outcome: item.offline_screen1_outcome,
          Call_Date1: item.call_date1,
          Last_Contact_Date: item.call_date2,
          Candidate_interest: item.candidate_interest,
          Reasons_For_Decline: item.reasons_for_decline,
          Comments: item.comments,
          Email_Candidate: item.email_candidate,
          Status: item.status,
          international_clients: item.international_clients,
          current_ctc: item.current_ctc,
          expected_ctc: item.expected_ctc,
          notice_period: item.notice_period,
          certifications: item.certifications,
          location: item.location,
          current_company: item.current_company,
          current_designation: item.current_designation,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(manipulatedData);
      const workbook = XLSX.utils.book_new();
      setclicked(false);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Candidate Screening.xlsx");
    } else {
      setclicked(false);
      alert("No Data found...");
    }
  };

  const filteredData = filterByDate(teamlist);

  const DownloadBtn = () => {
    const link = document.createElement("a");
    link.href = SampleFile;
    link.download = "candidatelist.xlsx";
    link.click();
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      try {
        dispatch(storeAction.isPopUpHander("loading"));
        let response;
        if (searchfilter !== true) {
          response = await axios.get(newurl, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          });
        } else {
          response = await axios.post(
            newurl,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );
        }

        if (response.data.results.length !== 0) {
          setcount(response.data.count);

          setpagination_status(response.data.count > 20);

          setcandidateprevious(response.data.previous);
          setcandidatenext(response.data.next);

          const updatedData = response.data.results.map((candidate) => ({
            ...candidate,
            isNew: false,
            isFrom: "db",
          }));
          setteamlist(updatedData);
          setallteamlist(updatedData);
          document.getElementById("Recruiterid").value = "";
          setReasonFilter("");
        }
      } catch (error) {
        console.error("Error fetching candidates:", error);
      } finally {
        // Hide loading popup
        setReasonFilter("");
        dispatch(storeAction.isPopUpHander());
        document.getElementById("Recruiterid").value = "";
      }
    }
  };

  const searchbtn = async () => {
    if (searchname.length !== 0) {
      setsearchloading(true);
      var obj = {
        name: searchname,
      };
      const Alldata = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/candidate-screen-name-filters/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response);
      if (Alldata.results.length !== 0) {
        setsearchloading(false);
        setsearchfilter(true);
        setcount(Alldata.count);
        if (Alldata.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(Alldata.previous);
        setcandidatenext(Alldata.next);
        const updatedData = Alldata.results.map((candidate) => ({
          ...candidate,
          isNew: false,
          isFrom: "db",
        }));
        setteamlist(updatedData);
        setallteamlist(updatedData);
        dispatch(storeAction.isPopUpHander());
      } else {
        setsearchloading(false);
        setsearchfilter(false);
      }
    } else {
      setsearchloading(false);
      setsearchfilter(false);
      alert("Please Enter Search First & Middle Name or Last Name...");
    }
  };

  return (
    <div>
      <div className="flex gap-2 items-center">
        <button
          className="importbtn"
          onClick={() => {
            dispatch(storeAction.isPopUpHander("upload"));
          }}
        >
          Import
        </button>
        <div className="flex gap-2">
          <div className="flex flex-col">
            <input
              type="date"
              min="1000-01-01"
              max="9999-12-31"
              className="border border-gray-300 rounded px-4 py-2"
              value={fromDate}
              onChange={(e) => handleDateChange("from", e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <input
              type="date"
              min="1000-01-01"
              max="9999-12-31"
              className="border border-gray-300 rounded px-4 py-2"
              value={toDate}
              onChange={(e) => handleDateChange("to", e.target.value)}
            />
          </div>
        </div>

        <button
          className="resetbtn"
          onClick={() => {
            Getalldata();
            setToDate("");
            setFromDate("");
          }}
        >
          Reset
        </button>
        <button
          className="importbtn"
          onClick={() => {
            dispatch(storeAction.isPopUpHander("export"));
          }}
        >
          Export
        </button>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="text"
          className="w-[20%] mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
          placeholder="Search First & Middle Name or Last Name"
          onChange={(e) => setsearchname(e.target.value)}
          value={searchname}
        />
        {searchloading == true ? (
          <button className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium">
            Searching...
          </button>
        ) : (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium"
            onClick={searchbtn}
          >
            Search
          </button>
        )}

        {searchfilter && (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-black text-white font-medium"
            onClick={() => {
              setsearchfilter(false);
              Getalldata();
              setsearchname("");
            }}
          >
            Cancel
          </button>
        )}
      </div>
      <table>
        <thead>
          <tr className="hireTableHead">
            <th>Action</th>
            <th className="flex gap-2 items-center mt-5">
              <span className="w-[70%]">Recruiter</span>
              <select
                value={reasonFilter}
                onChange={(e) => handleRecruiterFilterChange(e.target.value)}
                className="w-[30%] border rounded border-black p-1"
                id="Recruiterid"
              >
                <option value="">All</option>
                <option value="Sanskriti">Sanskriti</option>
                <option value="Shikha">Shikha</option>
                <option value="Sanchan">Sanchan</option>
                <option value="Divya">Divya</option>
                <option value="Priya">Priya</option>
              </select>
            </th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Role</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Primary skill</th>
            <th>Experience</th>
            <th>Current Company</th>
            <th>Current Designation</th>
            <th>Current Location</th>
            <th>International clients</th>
            <th>Current CTC</th>
            <th>Expected CTC</th>
            <th>Notice Period</th>
            <th>Certifications</th>
            <th>Offline Screen 1 Outcome</th>
            <th>Call Date 1</th>
            <th>Last Contact Date</th>
            <th>Candidate Interest in Hirein5</th>
            <th className="flex gap-1 w-full items-center mt-5">
              <span className="w-[80%]">Reasons for Decline</span>
              <select
                value={reasonFilter}
                onChange={(e) => handleReasonFilterChange(e.target.value)}
                className="w-[20%] border rounded border-black p-1"
              >
                <option value="">All</option>
                <option value="Currently not seeking for a change">
                  Currently not seeking for a change
                </option>
                <option value="Candidate not interested">
                  Candidate not interested
                </option>
                <option value="Recently switched jobs">
                  Recently switched jobs
                </option>
                <option value="Prefer to work in an MNC">
                  Prefer to work in an MNC
                </option>
                <option value="Seeking WFO but not willing to relocate">
                  Seeking WFO but not willing to relocate
                </option>
                <option value="Onboarding process on Hirein5 platform is too lengthy">
                  Onboarding process on Hirein5 platform is too lengthy
                </option>
                <option value="Candidates are not comfortable sharing their Aadhaar or PAN card details.">
                  Candidates are not comfortable sharing their Aadhaar or PAN
                  card details.
                </option>
                <option value="Lack of Required Skills">
                  Lack of Required Skills
                </option>
                <option value="Insufficient Experience">
                  Insufficient Experience
                </option>
                <option value="Poor Interview Performance">
                  Poor Interview Performance
                </option>
                <option value="Incomplete Application">
                  Incomplete Application
                </option>
                <option value="Background Check Issues">
                  Background Check Issues
                </option>
                <option value="NA">NA</option>
              </select>
            </th>
            <th>Comments</th>
            <th>
              Send Mail <br />
              <input
                type="checkbox"
                onClick={selectAll}
                checked={selectall == true}
              />
            </th>
            <th>Resend</th>
            <th>Email Candidate</th>
            <th className="flex gap-1 w-full items-center mt-5">
              <span className="w-[60px]">Status</span>
              <select
                value={statusFilter}
                onChange={(e) => handleStatusFilterChange(e.target.value)}
                className="w-[20px] border rounded border-black p-1"
              >
                <option value="">All</option>
                <option value="Success">Success</option>
                <option value="Reject">Reject</option>
                <option value="New Candidate">New Candidate</option>
                <option value="Mail send">Mail Sent</option>
                <option value="NA">NA</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {filteredData.length !== 0 &&
            filteredData.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                <td>
                  <div className="flex items-center justify-center">
                    {filteredData.length == 1 ? null : (
                      <FaRegCircleXmark
                        className="xIcon"
                        onClick={() => removeCandidate(role.id)}
                      />
                    )}

                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.recruiter}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "recruiter", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Sanskriti">Sanskriti</option>
                      <option value="Shikha">Shikha</option>
                      <option value="Sanchan">Sanchan</option>
                      <option value="Divya">Divya</option>
                      <option value="Priya">Priya</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.role}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "role", e.target.value)
                      }
                    />
                  </h3>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.contact}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "contact", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.email}
                      type="email"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "email", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.primary_skill}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "primary_skill",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.experience}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "experience", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.current_company}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "current_company",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.current_designation}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "current_designation",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.location}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "location", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.international_clients}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "international_clients",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.current_ctc}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "current_ctc",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.expected_ctc}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "expected_ctc",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.notice_period}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "notice_period",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.certifications}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "certifications",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.offline_screen1_outcome}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "offline_screen1_outcome",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      type="date"
                      min="1000-01-01"
                      max="9999-12-31"
                      value={role.call_date1}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "call_date1", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      type="date"
                      min="1000-01-01"
                      max="9999-12-31"
                      value={role.call_date2}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "call_date2", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.candidate_interest}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "candidate_interest",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Accept">Accept</option>
                      <option value="Decline">Decline</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.reasons_for_decline}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "reasons_for_decline",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Currently not seeking for a change">
                        Currently not seeking for a change
                      </option>
                      <option value="Candidate not interested">
                        Candidate not interested
                      </option>
                      <option value="Recently switched jobs">
                        Recently switched jobs
                      </option>
                      <option value="Prefer to work in an MNC">
                        Prefer to work in an MNC
                      </option>
                      <option value="Seeking WFO but not willing to relocate">
                        Seeking WFO but not willing to relocate
                      </option>
                      <option value="Onboarding process on Hirein5 platform is too lengthy">
                        Onboarding process on Hirein5 platform is too lengthy
                      </option>
                      <option value="Candidates are not comfortable sharing their Aadhaar or PAN card details.">
                        Candidates are not comfortable sharing their Aadhaar or
                        PAN card details.
                      </option>
                      <option value="Lack of Required Skills">
                        Lack of Required Skills
                      </option>
                      <option value="Insufficient Experience">
                        Insufficient Experience
                      </option>
                      <option value="Poor Interview Performance">
                        Poor Interview Performance
                      </option>
                      <option value="Incomplete Application">
                        Incomplete Application
                      </option>
                      <option value="ackground Check Issues">
                        Background Check Issues
                      </option>
                      <option value="NA">NA</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      type="checkbox"
                      disabled={
                        role.email_candidate == "Yes" && role.selected == "true"
                      }
                      checked={role.selected == "true"}
                      onChange={() => {
                        handleChangeNew(
                          roleIndex,
                          "selected",
                          role.selected == "true" ? "false" : "true"
                        );
                      }}
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    {role.email_candidate == "Yes" &&
                    role.selected == "true" ? (
                      <button
                        className="resendbtn"
                        onClick={() => {
                          resendBtn(role);
                        }}
                      >
                        Re-Send
                      </button>
                    ) : (
                      <button className="resendbtn1" disabled>
                        Re-Send
                      </button>
                    )}
                  </h3>
                </td>
                <td>
                  <h3>
                    {role.email_candidate === "Yes" ? (
                      <p className="contracted px-2 rounded-full">Yes</p>
                    ) : role.selected == "true" ? (
                      <p className="contracted px-2 rounded-full">Yes</p>
                    ) : (
                      <p className="rejectstaus px-2 rounded-full">No</p>
                    )}
                  </h3>
                </td>
                <td>
                  <h3 className="px-5">
                    {(role.status == "Success" || role.status == "") &&
                    role.onboarding_status > 1 ? (
                      <p className="reserving px-2 rounded-full">In Progress</p>
                    ) : role.status == "Success" || role.status == "Pending" ? (
                      <p className="hiringActive px-2 rounded-full">
                        Processing
                      </p>
                    ) : role.status == "Reject" ? (
                      <p className="rejectstaus px-2 rounded-full">On Hold</p>
                    ) : role.status == "New Candidate" || role.status == "" ? (
                      <p className="contracted px-2 rounded-full">New</p>
                    ) : role.status == "Mail send" ? (
                      <p className="processingstaus px-2 rounded-full">
                        Reviewed
                      </p>
                    ) : (
                      <p className="rejectstaus px-2 rounded-full">
                        Not Register
                      </p>
                    )}
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {mailloading ? (
        <button className="saveSubmit1">Please Wait...</button>
      ) : (
        <button className="saveSubmit1" onClick={Sendmail}>
          Send Mail
        </button>
      )}

      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/candidateScreen/") &&
                  newurl.split("/candidateScreen/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/candidateScreen/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
      {isPopUp == "upload" && (
        <div className="interViewDetailOverlay">
          <div className="interViewDetailOverlayHead">
            <h1>Upload File</h1>
          </div>
          <div className="px-5">
            <div className="flex justify-between py-2 items-center">
              <label className="font-medium">File</label>
              <label
                className="font-medium cursor-pointer text-sm text-blue-500"
                onClick={DownloadBtn}
              >
                Download Sample File
              </label>
            </div>
            <input
              type="file"
              placeholder="Email ID"
              name="email"
              className="w-full h-12 mt-2 mb-4 px-4 pt-2 border border-gray-200 rounded"
              onChange={(e) => handleFile(e.target.files[0])}
            />
            {status && (
              <p className="py-2 text-green-500 font-medium">
                Candidate Imported Successfully...{" "}
              </p>
            )}
            {xldata.length !== 0 ? (
              clicked === true ? (
                <button className="w-full h-10 my-4 bg-[#1D37E7] text-white rounded">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="w-full h-10 my-4 bg-[#1D37E7] text-white rounded"
                  onClick={submit_btn}
                >
                  Submit
                </button>
              )
            ) : (
              <button className="w-full h-10 my-4 bg-[#8b5cf67a] text-white rounded">
                Submit
              </button>
            )}
          </div>
        </div>
      )}
      {isPopUp == "export" && (
        <div className="interViewDetailOverlay">
          <div className="interViewDetailOverlayHead">
            <h1>Export</h1>
          </div>
          <div className="px-5">
            <div className="py-2 flex flex-col">
              <label className="font-medium">From Date</label>
              <input
                type="date"
                min="1000-01-01"
                max="9999-12-31"
                className="w-full mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
                onChange={(e) => {
                  setFromDateexport(e.target.value);
                }}
              />
            </div>
            <div className="py-2 flex flex-col">
              <label className="font-medium">To Date</label>
              <input
                type="date"
                min="1000-01-01"
                max="9999-12-31"
                className="w-full mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
                onChange={(e) => {
                  setToDateexport(e.target.value);
                }}
              />
            </div>

            {clicked === true ? (
              <button className="w-full h-10 my-4 bg-[#1D37E7] text-white rounded">
                Please Wait...
              </button>
            ) : (
              <button
                className="w-full h-10 my-4 bg-[#1D37E7] text-white rounded"
                onClick={exportBtn}
              >
                Export
              </button>
            )}
          </div>
        </div>
      )}
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default CandidateScreening;

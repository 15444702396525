/* eslint-disable no-unused-vars */
import React from "react";
import CandidateSignUp from "../Components/Candidate/CandidateSignUp/SignUp/CandidateSignUp";
import NewClientFlow from "../Components/NewClientFlow/NewClientFlow";
import { useSelector } from "react-redux";

const ClientSignup = () => {
  const role = useSelector((store) => store.role);

  return <>{role === "Client" ? <NewClientFlow /> : <CandidateSignUp />}</>;
};

export default ClientSignup;

/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import * as XLSX from "xlsx";
import { MdMarkEmailRead } from "react-icons/md";
import moment from "moment";

const NewCandidatetable = () => {
  const dispatch = useDispatch();
  const customToastStyle = {
    background: "#14B8A6",
    color: "white",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    "--toastify-icon-color-success": "white",
  };

  const token = useSelector((store) => store.token);
  const ocCandidate = useSelector((store) => store.ocCandidate);
  const adminsearch = useSelector((store) => store.adminsearch);

  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [loading, setloading] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);
  const [loadingnew2, setloadingnew2] = useState(false);
  const [selectid, setselectid] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [newurl, setnewurl] = useState(null);
  const [count, setcount] = useState(0);
  const [search_value, setsearch_value] = useState("");
  const [pagination_status, setpagination_status] = useState(false);
  const [search_status, setsearch_status] = useState(false);
  const [coundata, setcoundata] = useState({
    InProgress: 0,
    New: 0,
    OnHold: 0,
    Processing: 0,
    Reviewed: 0,
  });
  const [datefilter, setdatefilter] = useState(null);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const statusOrder = {
    "New Candidate": 1,
    "Mail send": 2,
    Success: 3,
    SuccessWithOnboarding: 4,
    Reject: 5,
  };

  const sortCandidates = (a, b) => {
    const getStatusRank = (candidate) => {
      if (candidate.status === "Success" && candidate.onboarding_status > 1) {
        return statusOrder["SuccessWithOnboarding"];
      }
      return statusOrder[candidate.status] || 6;
    };

    const rankA = getStatusRank(a);
    const rankB = getStatusRank(b);

    return rankA - rankB;
  };

  useEffect(() => {
    Getcandidate();
  }, []);

  const Getcandidate = async () => {
    if (ocCandidate.count !== undefined) {
      if (ocCandidate.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(ocCandidate.count);
        if (ocCandidate.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        const allFaculties = ocCandidate.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        var checkdata = await sortedCandidates.filter((data) => {
          return (
            (data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send" ||
              data.status !== "") &&
            data.nottify !== true
          );
        });
        checkdata.sort(sortCandidates);
        setloading(false);
        setcandidateprevious(ocCandidate.previous);
        setcandidatenext(ocCandidate.next);
        setalldata(checkdata);
        settotaldata(checkdata);
        const response = await axios
          .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/newCandidate/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          });
        if (response.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          setcount(response.count);
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }

          const allFaculties = response.results || [];
          const sortedCandidates = [...allFaculties].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          var checkdata1 = await sortedCandidates.filter((data) => {
            return (
              (data.status == "New Candidate" ||
                data.status == "Success" ||
                data.status == "Reject" ||
                data.status == "Mail send" ||
                data.status !== "") &&
              data.nottify !== true
            );
          });
          checkdata1.sort(sortCandidates);
          setloading(false);
          setcandidateprevious(response.previous);
          setcandidatenext(response.next);
          setalldata(checkdata1);
          settotaldata(checkdata1);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: response }));
        } else {
          dispatch(storeAction.isPopUpHander());
          setloading(false);
          setcandidateprevious(null);
          setcandidatenext(null);
          setalldata([]);
          settotaldata([]);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
        }
      } else {
        dispatch(storeAction.isPopUpHander());
        setloading(false);
        setcandidateprevious(null);
        setcandidatenext(null);
        setalldata([]);
        settotaldata([]);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
      }
    } else {
      setloading(true);
      setnewurl(null);
      if (adminsearch.length == 0) {
        const response = await axios
          .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/newCandidate/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          });
        if (response.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          setcount(response.count);
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }

          const allFaculties = response.results || [];
          const sortedCandidates = [...allFaculties].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          var checkdata2 = await sortedCandidates.filter((data) => {
            return (
              (data.status == "New Candidate" ||
                data.status == "Success" ||
                data.status == "Reject" ||
                data.status == "Mail send" ||
                data.status !== "") &&
              data.nottify !== true
            );
          });
          checkdata2.sort(sortCandidates);
          setloading(false);
          setcandidateprevious(response.previous);
          setcandidatenext(response.next);
          setalldata(checkdata2);
          settotaldata(checkdata2);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: response }));
        } else {
          dispatch(storeAction.isPopUpHander());
          setloading(false);
          setcandidateprevious(null);
          setcandidatenext(null);
          setalldata([]);
          settotaldata([]);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
        }
      } else {
        setpagination_status(false);
        dispatch(storeAction.adminsearchHander({ adminsearch: adminsearch }));
        const response = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/fetch-status/candidates/`,
            {
              status: adminsearch,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
        if (response.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          setcount(response.count);
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          const allFaculties = response.results || [];
          const sortedCandidates = [...allFaculties].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          var checkdata3 = await sortedCandidates.filter((data) => {
            return (
              (data.status == "New Candidate" ||
                data.status == "Success" ||
                data.status == "Reject" ||
                data.status == "Mail send" ||
                data.status !== "") &&
              data.nottify !== true
            );
          });
          checkdata3.sort(sortCandidates);
          setloading(false);
          setcandidateprevious(response.previous);
          setcandidatenext(response.next);
          setalldata(checkdata3);
          settotaldata(checkdata3);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: response }));
        } else {
          dispatch(storeAction.isPopUpHander());
          setloading(false);
          setcandidateprevious(null);
          setcandidatenext(null);
          setalldata([]);
          settotaldata([]);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
        }
      }
    }

    const countdata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/count-view/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata.New !== undefined) {
      setcoundata({
        InProgress: countdata.InProgress,
        New: countdata.New,
        OnHold: countdata.OnHold,
        Processing: countdata.Processing,
        Reviewed: countdata.Reviewed,
      });
    }
  };

  const acceptbtn = async (data) => {
    setloadingnew(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
    
        <p>Thank you for registering with <strong>Hirein5</strong>! To get started, please complete your application.</p>
    
        <p>Kindly ensure that you fill out all fields in your application accurately.</p>
    
        <p>Click below to access your account: </p>
    
        <p>
          <a href="https://app.hirein5.com/#/login" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Log in to Your Account
          </a>
        </p>
    
        <p>
          If you're new to Hirein5, please 
          <a href="https://app.hirein5.com/#/candidateregister" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Sign Up Here
          </a>.
        </p>
    
        <p>
          If you have any questions, feel free to contact us at 
          <a href="mailto:candidatesupport@hirein5.com" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             candidatesupport@hirein5.com
          </a>.
        </p>
    
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
    
        <p><strong>Best regards,</strong><br>Team Hirein5</p>
      `,
    };

    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send" ||
            data.nottify !== true
          );
        });
        checkdata1.sort(sortCandidates);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newcandidateHander({ newcandidate: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };

  const acceptbtn1 = async (data) => {
    setloadingnew2(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
    
        <p>Thank you for registering with <strong>Hirein5</strong>! To get started, please complete your application.</p>
    
        <p>Kindly ensure that you fill out all fields in your application accurately.</p>
    
        <p>Click below to access your account:</p>
    
        <p>
          <a href="https://app.hirein5.com/#/login" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Log in to Your Account
          </a>
        </p>
    
        <p>
          If you're new to Hirein5, please 
          <a href="https://app.hirein5.com/#/candidateregister" target="_blank" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             Sign Up Here
          </a>.
        </p>
    
        <p>
          If you have any questions, feel free to contact us at 
          <a href="mailto:candidatesupport@hirein5.com" 
             style="color: #007bff; text-decoration: none; font-weight: bold;">
             candidatesupport@hirein5.com
          </a>.
        </p>
    
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
    
        <p><strong>Best regards,</strong><br>Team Hirein5</p>
      `,
    };

    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setloadingnew2(false);
    setselectid(null);
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send" ||
            data.nottify !== true
          );
        });
        checkdata1.sort(sortCandidates);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newcandidateHander({ newcandidate: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
  };

  const rejectbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Application Update!",
      message: `<p>Hi ${data.first_name},</p>
                <p>Thank you for your interest in Hirein5.</p>
                <p>We regret to inform you that we are unable to process your application at this stage. We shall be in touch for other openings.</p>
                <p>All the best,</p>
                <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };

  const exportbtn = async () => {
    const manipulatedData = alldata.map((item) => {
      if (item.new_candidate_info !== null) {
        return {
          // S_No: index + 1,
          Name: item.first_name,
          Email: item.username,
          Phone_Number: item.phone,
          Skills:
            item.new_candidate_info.skills.length !== 0
              ? item.new_candidate_info.skills.join(", ")
              : " ",
          Resume: item.new_candidate_info.resume,
        };
      }
    });
    var finaldata = manipulatedData.filter((data) => {
      return data !== undefined;
    });
    const worksheet = XLSX.utils.json_to_sheet(finaldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Onboarding_candidate.xlsx");
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const fetchCandidates = async (payload = {}, method = "get") => {
    try {
      setloading(true);
      setpagination_status(false);

      const options = {
        method,
        url: newurl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        data: method === "post" ? payload : undefined,
      };

      const response = await axios(options).then((res) => res.data);

      if (response.results.length === 0) {
        setalldata([]);
        settotaldata([]);
        setcandidateprevious(null);
        setcandidatenext(null);
        dispatch(storeAction.isPopUpHander());
        return response;
      }

      dispatch(storeAction.isPopUpHander());
      setpagination_status(response.count > 20);

      // Sorting candidates by updated_at
      let sortedCandidates = [...response.results].sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );

      // Filtering candidates based on status
      let filteredCandidates = sortedCandidates.filter(
        (data) =>
          ["New Candidate", "Success", "Reject", "Mail send"].includes(
            data.status
          ) && data.nottify !== true
      );

      // Sorting based on custom logic (if any)
      filteredCandidates.sort(sortCandidates);

      setalldata(filteredCandidates);
      settotaldata(filteredCandidates);
      setcandidateprevious(response.previous);
      setcandidatenext(response.next);

      return response;
    } finally {
      setloading(false);
    }
  };

  const GetnewCandidate = async () => {
    if (!newurl) return;

    let payload = {};
    let method = "get";

    if (adminsearch.length === 0) {
      if (!search_status) {
        return fetchCandidates();
      }
      payload = { first_name: search_value };
      method = "post";
    } else if (
      ["today", "yesterday", "last_week", "last_month"].includes(adminsearch)
    ) {
      payload = { date_format: adminsearch };
      method = "post";
    } else if (adminsearch === "Custom") {
      payload = { start_date: startdate, end_date: enddate };
      method = "post";
    } else {
      payload = search_status
        ? { first_name: adminsearch }
        : { status: adminsearch };

      method = "post";
    }

    const response = await fetchCandidates(payload, method);

    if (response && Object.keys(response).length !== 0) {
      dispatch(storeAction.ptCandidateHander({ ptCandidate: response }));
    } else {
      dispatch(storeAction.ptCandidateHander({ ptCandidate: {} }));
    }
  };

  const searchbtn = async () => {
    if (search_value.length !== 0) {
      setsearch_status(true);
      setloading(true);
      setnewurl(null);
      setpagination_status(false);
      setcount(0);
      var obj = {
        first_name: search_value,
      };
      const response = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/registered/search/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        var checkdata = await response.results.filter((data) => {
          return (
            (data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send" ||
              data.status !== "") &&
            data.nottify !== true
          );
        });
        setloading(false);
        checkdata.sort(sortCandidates);
        setalldata(checkdata);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
      } else {
        setpagination_status(false);
        setloading(false);
        dispatch(storeAction.isPopUpHander());
        setalldata([]);
        setcandidateprevious(null);
        setcandidatenext(null);
      }
    }
  };

  const change_status = async (e) => {
    if (e !== "All") {
      setloading(true);
      setpagination_status(false);
      setnewurl(null);
      dispatch(storeAction.adminsearchHander({ adminsearch: e }));
      const response = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/fetch-status/candidates/`,
          {
            status: e,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        const allFaculties = response.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        var checkdata = await sortedCandidates.filter((data) => {
          return (
            (data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send" ||
              data.status !== "") &&
            data.nottify !== true
          );
        });
        checkdata.sort(sortCandidates);
        setloading(false);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        setalldata(checkdata);
        settotaldata(checkdata);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: response }));
      } else {
        dispatch(storeAction.isPopUpHander());
        setloading(false);
        setcandidateprevious(null);
        setcandidatenext(null);
        setalldata([]);
        settotaldata([]);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
      }
    } else {
      dispatch(storeAction.adminsearchHander({ adminsearch: "" }));
      setloading(true);
      setnewurl(null);
      setpagination_status(false);
      const response = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/newCandidate/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }

        const allFaculties = response.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        var checkdata1 = await sortedCandidates.filter((data) => {
          return (
            (data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send" ||
              data.status !== "") &&
            data.nottify !== true
          );
        });
        checkdata1.sort(sortCandidates);
        setloading(false);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: response }));
      } else {
        dispatch(storeAction.isPopUpHander());
        setloading(false);
        setcandidateprevious(null);
        setcandidatenext(null);
        setalldata([]);
        settotaldata([]);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: {} }));
      }
    }
  };

  const changeDateFilter = async (e) => {
    if (e.length !== 0) {
      setcount(0);
      setpagination_status(false);
      setdatefilter(e);
      dispatch(storeAction.adminsearchHander({ adminsearch: e }));
      if (
        e == "today" ||
        e == "yesterday" ||
        e == "last_week" ||
        e == "last_month"
      ) {
        setloading(true);
        const filterdata = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/profile-tracker/date-filters/`,
            { date_format: e },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
        if (filterdata.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          if (filterdata.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          setcount(filterdata.count);
          const sortedCandidates = [...filterdata.results].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setalldata(sortedCandidates);
          setloading(false);
          setcandidateprevious(filterdata.previous);
          setcandidatenext(filterdata.next);
          dispatch(storeAction.ocCandidateHander({ ocCandidate: filterdata }));
        } else {
          setcount(0);
          dispatch(storeAction.isPopUpHander());
          setalldata([]);
          setcandidateprevious(null);
          setcandidatenext(null);
          setloading(false);
          setpagination_status(false);
        }
      }
    } else {
      Getcandidate();
    }
  };

  const filterbtn = async () => {
    if (startdate && enddate) {
      setcount(0);
      setloading(true);
      setpagination_status(false);
      const filterdata = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/profile-tracker/date-filters/`,
          { start_date: startdate, end_date: enddate },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      if (filterdata.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        if (filterdata.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcount(filterdata.count);
        const sortedCandidates = [...filterdata.results].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setalldata(sortedCandidates);
        setloading(false);
        setcandidateprevious(filterdata.previous);
        setcandidatenext(filterdata.next);
        dispatch(storeAction.ocCandidateHander({ ocCandidate: filterdata }));
      } else {
        setcount(0);
        dispatch(storeAction.isPopUpHander());
        setalldata([]);
        setcandidateprevious(null);
        setcandidatenext(null);
        setloading(false);
        setpagination_status(false);
      }
    } else {
      alert("Please Select Star date & End date");
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= pageNumbers.length) {
      setCurrentPage(page);
    }
  };

  const getPaginationNumbers = () => {
    if (pageNumbers.length <= 1) return [];

    let pages = [];
    for (let i = 1; i <= pageNumbers.length; i++) {
      pages.push(i);
    }

    if (pages.length > 5) {
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(currentPage + 2, pageNumbers.length);
      const slicedPages = pages.slice(start - 1, end);

      return [
        ...(start > 1 ? [1, "..."] : []),
        ...slicedPages,
        ...(end < pageNumbers.length ? ["...", pageNumbers.length] : []),
      ];
    }
    return pages;
  };

  // useEffect(() => {
  //   fetchData(currentPage); // Fetch new data when currentPage changes
  // }, [currentPage]);

  // const fetchData = async (page) => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.example.com/data?page=${page}`
  //     );
  //     console.log(response, "response");
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  return (
    <div className="adminProfile paddingLeft50 paddingRight50">
      <DashHead
        head="Registered Candidate"
        desc="View and update profiles of onboarded candidates"
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="flex gap-2 justify-end items-center mb-2">
        <select
          className="py-2 border border-[#dbdbdb] rounded px-2"
          onChange={(e) => {
            change_status(e.target.value);
          }}
          // defaultValue={statussearchvalue}
        >
          <option value="" disabled>
            Status
          </option>
          <option value="All">All</option>
          <option value="New Candidate">New Candidate</option>
          <option value="Mail send">Registered</option>
          <option value="In-Progress">Form</option>
          <option value="Processing">Pwd Set</option>
          <option value="Reject">Reject</option>
        </select>
        <button className="exportntn" onClick={exportbtn}>
          Export
        </button>
      </div>
      <div className="AdminClientProfileComp">
        <div className="flex justify-between gap-4 mb-5">
          <div className="card div-card">
            <h3>Form</h3>
            <p>{coundata.InProgress}</p>
          </div>
          <div className="card div-card">
            <h3>New Candidate</h3>
            <p>{coundata.New}</p>
          </div>
          <div className="card div-card">
            <h3>On Hold</h3>
            <p>{coundata.OnHold}</p>
          </div>
          <div className="card div-card">
            <h3>Pwd Set</h3>
            <p>{coundata.Processing}</p>
          </div>
          <div className="card div-card">
            <h3>Registered</h3>
            <p>{coundata.Reviewed}</p>
          </div>
        </div>
        <div className="flex gap-4 w-full items-center">
          <div className="AdminClientProfileCompSearch w-full">
            <input
              type="text"
              placeholder="Search..."
              onChange={(e) => setsearch_value(e.target.value)}
              id="seachinput"
            />
            <img src={search} alt="" />
            <div className="flex gap-4 divbutton">
              <button className="savesearch" onClick={searchbtn}>
                Search
              </button>
              {search_status && (
                <button
                  className="cancelsearch"
                  onClick={() => {
                    setsearch_status(false);
                    setalldata(totaldata);
                    document.getElementById("seachinput").value = "";
                    setpagination_status(true);
                    setnewurl(null);
                    setcandidateprevious(null);
                    setcandidatenext(null);
                    Getcandidate();
                    dispatch(
                      storeAction.adminsearchHander({ adminsearch: "" })
                    );
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <select
            className="border border-[#dbdbdb] rounded py-2 px-3 w-[20%]"
            onChange={(e) => {
              changeDateFilter(e.target.value);
            }}
            id="datefilterid"
          >
            <option value="">Filter</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_week">Last 7 Days</option>
            <option value="last_month">Last Month</option>
            <option value="Custom">Custom</option>
          </select>
          {datefilter == "Custom" && (
            <>
              <input
                type="date"
                className="border border-[#dbdbdb] rounded py-2 px-3"
                onChange={(e) => {
                  setstartdate(e.target.value);
                }}
              />
              <input
                type="date"
                className="border border-[#dbdbdb] rounded py-2 px-3"
                onChange={(e) => {
                  setenddate(e.target.value);
                }}
              />
              <button className="savesearchfilter" onClick={filterbtn}>
                Filter
              </button>
            </>
          )}
          {datefilter !== null && (
            <button
              className="cancelsearch"
              onClick={() => {
                setdatefilter(null);
                dispatch(storeAction.adminsearchHander({ adminsearch: "" }));
                document.getElementById("datefilterid").value = "";
                Getcandidate();
              }}
            >
              Cancel
            </button>
          )}
        </div>

        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable1 w-full">
            <thead>
              <tr className="AdminTableHead">
                <th>Candidate Name</th>
                <th>Registered At</th>
                <th>Key Skills</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Resume</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                alldata.length !== 0 ? (
                  alldata.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td className="w-[150px]">
                        {data.created_at !== null &&
                        data.created_at.length !== 0 ? (
                          <h1>
                            {moment(data.created_at).format(
                              "Do MMM YY, hh:mm A"
                            )}
                          </h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.new_candidate_info !== null ? (
                          <h1>{data.new_candidate_info.skills.join(", ")}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>
                      <td>
                        <h1
                          className="font-medium text-blue-500 cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${
                                data.new_candidate_info !== null
                                  ? data.new_candidate_info.resume
                                  : null
                              }`,
                              "_blank"
                            );
                          }}
                        >
                          View
                        </h1>
                      </td>
                      <td>
                        {data.status == "Success" &&
                        data.onboarding_status > 1 ? (
                          data.onboarding_status == 2 ? (
                            <p className="reserving px-2 rounded-full w-[75px]">
                              Form 1
                            </p>
                          ) : data.onboarding_status == 3 ? (
                            <p className="reserving px-2 rounded-full w-[75px]">
                              Form 2
                            </p>
                          ) : (
                            data.onboarding_status == 4 && (
                              <p className="reserving px-2 rounded-full w-[75px]">
                                Form 3
                              </p>
                            )
                          )
                        ) : data.status == "Success" ? (
                          <p className="hiringActive px-2 rounded-full w-[75px]">
                            Pwd Set
                          </p>
                        ) : data.status == "Reject" ? (
                          <p className="rejectstaus px-2 rounded-full">
                            On Hold
                          </p>
                        ) : data.status == "New Candidate" ? (
                          <p className="contracted px-2 rounded-full">New</p>
                        ) : data.status == "Mail send" ? (
                          <p className="processingstaus px-2 rounded-full">
                            Registered
                          </p>
                        ) : (
                          <p className="hiringActive px-2 rounded-full">-</p>
                        )}
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {data.status == "Success" ||
                          data.status == "Mail send" ? null : loadingnew ==
                            false ? (
                            <button
                              className="acceptbtn"
                              onClick={() => {
                                acceptbtn(data);
                              }}
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            selectid == data.id && (
                              <button className="acceptbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {loadingnew1 === false ? (
                            <>
                              <button
                                className="rejectbtn"
                                onClick={() => {
                                  rejectbtn(data);
                                }}
                              >
                                X
                              </button>
                              {loadingnew2 == true && selectid == data.id ? (
                                <button className="accept_btn">
                                  <FiLoader className="loadingIcon" />
                                </button>
                              ) : data.status == "New Candidate" ? null : (
                                <button
                                  className="accept_btn"
                                  onClick={() => {
                                    acceptbtn1(data);
                                  }}
                                >
                                  <MdMarkEmailRead />
                                </button>
                              )}
                            </>
                          ) : (
                            selectid == data.id && (
                              <button className="rejectbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {/* <button
                            className="acceptbtn"
                            onClick={() => {
                              acceptbtn(data);
                            }}
                          >
                            <FaCheck />
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="flex justify-center py-5">
                      <FiLoader className="loadingIconHAT" />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-center items-center mt-5">
          <button
            className={`mx-1 px-4 py-2 rounded-lg ${
              currentPage === 1
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-800"
            }`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Prev
          </button>

          {getPaginationNumbers().map((page, index) => (
            <button
              key={index}
              className={`mx-1 px-4 py-2 rounded-lg ${
                currentPage === page
                  ? "bg-blue-700 text-white"
                  : "bg-gray-300 text-black hover:bg-gray-400"
              }`}
              onClick={() => {
                if (typeof page === "number") handlePageChange(page);
              }}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}

          <button
            className={`mx-1 px-4 py-2 rounded-lg ${
              currentPage === pageNumbers.length
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-800"
            }`}
            disabled={currentPage === pageNumbers.length}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div> */}
        {pagination_status && (
          <div className="tablePagination">
            <nav>
              <ul className="pagination">
                {candidateprevious == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidateprevious);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                )}

                {newurl == null ? (
                  <h6 className="page-link">1</h6>
                ) : search_status === false ? (
                  <h6 className="page-link active">
                    {newurl.includes("/newCandidate/")
                      ? newurl
                          .split("/newCandidate/")[1]
                          ?.split("?page=")[1] !== undefined
                        ? newurl.split("/newCandidate/")[1].split("?page=")[1]
                        : 1
                      : newurl.includes("/fetch-status/candidates/")
                      ? newurl
                          .split("/fetch-status/candidates/")[1]
                          ?.split("?page=")[1] !== undefined
                        ? newurl
                            .split("/fetch-status/candidates/")[1]
                            .split("?page=")[1]
                        : 1
                      : newurl.includes("/profile-tracker/date-filters/") // New URL check added here
                      ? newurl
                          .split("/profile-tracker/date-filters/")[1]
                          ?.split("?page=")[1] !== undefined
                        ? newurl
                            .split("/profile-tracker/date-filters/")[1]
                            .split("?page=")[1]
                        : 1
                      : 1}
                  </h6>
                ) : (
                  <h6 className="page-link active">
                    {newurl.includes("/registered/search/") &&
                    newurl
                      .split("/registered/search/")[1]
                      ?.split("?page=")[1] !== undefined
                      ? newurl
                          .split("/registered/search/")[1]
                          .split("?page=")[1]
                      : 1}
                  </h6>
                )}
                {candidatenext == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidatenext);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                )}
              </ul>
            </nav>
            <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
              Total No Of Pages : {pageNumbers.length}
            </p>
          </div>
        )}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewCandidatetable;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import Avatar from "react-avatar";
import { FiLoader } from "react-icons/fi";
import moment from "moment";

const HatScheduleInterview = ({ isToggle }) => {
  const singleuser = useSelector((store) => store.singleuser);
  const token = useSelector((store) => store.token);
  const HATinterviewdata = useSelector((store) => store.HATinterviewdata);
  const isPopUp = useSelector((store) => store.isPopUp);
  const dispatch = useDispatch();

  const [interviewData, setinterviewData] = useState([]);
  const [loading, setloading] = useState(false);
  const [singleinterview, setsingleinterview] = useState([]);
  const [meetinglink, setmeetinglink] = useState("");
  const [interviewloader, setinterviewloader] = useState(false);
  const [errormsg, seterrormsg] = useState(false);

  useEffect(() => {
    GetInterviewdata();
  }, [isToggle]);

  const GetInterviewdata = async () => {
    setloading(true);
    if (HATinterviewdata.length !== 0) {
      setinterviewData(HATinterviewdata);
      setloading(false);
      const interviewdataResponse = await axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singleuser[0].id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );
      const interviewdata = interviewdataResponse.data;
      if (interviewdata.length !== 0) {
        const serverFilteredData = interviewdata
          .map((data) => {
            const now = new Date();
            const dateTimes = data.datetime
              .split(";")
              .flatMap((item) => item.split(", "));

            const futureDates = dateTimes.filter(
              (dateTimeStr) => new Date(dateTimeStr) > now
            );

            return futureDates.length > 0
              ? { ...data, datetime: futureDates.join(", ") }
              : null;
          })
          .filter((data) => data !== null);
        setinterviewData(
          serverFilteredData.length !== 0 ? serverFilteredData : []
        );
        dispatch(
          storeAction.HATinterviewdataHander({
            HATinterviewdata: serverFilteredData,
          })
        );
      } else {
        setinterviewData([]);
        setloading(false);
      }
    } else {
      const interviewdataResponse = await axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singleuser[0].id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );
      const interviewdata = interviewdataResponse.data;
      if (interviewdata.length !== 0) {
        const serverFilteredData = interviewdata
          .map((data) => {
            const now = new Date();
            const dateTimes = data.datetime
              .split(";")
              .flatMap((item) => item.split(", "));

            const futureDates = dateTimes.filter(
              (dateTimeStr) => new Date(dateTimeStr) > now
            );

            return futureDates.length > 0
              ? { ...data, datetime: futureDates.join(", ") }
              : null;
          })
          .filter((data) => data !== null);
        setinterviewData(
          serverFilteredData.length !== 0 ? serverFilteredData : []
        );
        dispatch(
          storeAction.HATinterviewdataHander({
            HATinterviewdata: serverFilteredData,
          })
        );
        setloading(false);
      } else {
        setinterviewData([]);
        setloading(false);
      }
    }
  };

  const viewsingle = (data) => {
    if (data !== null && data.datetime) {
      const dateTimeArray = data.datetime.split(", ").map((datetime) => {
        const [date, time] = datetime.split(" ");
        return { date, time };
      });
      const newData = { ...data, groupdata: dateTimeArray };
      setsingleinterview([newData]);
      setmeetinglink(data.meeting_url);
      dispatch(storeAction.isPopUpHander("ViewInvoiceHAT"));
    } else {
      setsingleinterview([]);
      dispatch(storeAction.isPopUpHander());
    }
  };

  const sendto = async () => {
    setinterviewloader(true);
    var obj = {
      status: "Send for approval",
    };
    const candidateEmailObj = {
      email: `${singleinterview[0].Candidate[0].username}`,
      subject: `Interview Schedule Confirmation with ${singleuser[0].first_name}`,
      from_email: "careers@hirein5.com",
      message: `
                  <p>Dear ${singleuser[0].first_name},</p>
                  <p>We are pleased to inform you that ${singleuser[0].first_name} has scheduled an interview with you. Below are the available time slots:</p>
                  <p><b>Schedule Interview Information:</b></p>
                  <ul>
                    <li>Date 1: ${singleinterview[0].groupdata[0].date}, Time: ${singleinterview[0].groupdata[0].time1}</li>
                    <li>Date 2: ${singleinterview[0].groupdata[1].date}, Time: ${singleinterview[0].groupdata[1].time1}</li>
                    <li>Date 3: ${singleinterview[0].groupdata[2].date}, Time: ${singleinterview[0].groupdata[2].time1}</li>
                  </ul>
                  <p>To confirm or manage your interview schedule, please log in to your account by clicking the link below:</p>
                  <p><a href="https://app.hirein5.com/#/login" target="_blank" style="color: #007BFF; text-decoration: none;"><b>Click here to log in</b></a></p>
                  <p>If you have any questions or need assistance, feel free to reach out to us.</p>
                  <p>Regards,</p>
                  <p><b>Team Hirein5</b></p>
                `,
    };
    await axios.post(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
      candidateEmailObj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singleinterview[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
    GetInterviewdata();
    setTimeout(() => {
      setinterviewloader(false);
      dispatch(storeAction.isPopUpHander());
    }, 3000);
  };

  const submitbtn = async () => {
    if (meetinglink.length == 0) {
      seterrormsg(true);
    } else {
      seterrormsg(false);
      setinterviewloader(true);
      var obj = {
        meeting_url: meetinglink,
      };
      var emailobj = {
        email: singleuser[0].username,
        from_email: "connect@hirein5.com",
        subject: "Meeting Link",
        message: `
          <p>Dear Client,</p>
          <p>Your interview request has been successfully processed. Please use the following link to join the interview on <strong>${singleinterview[0].time}</strong>.</p>
          <p><b>Meeting Link:</b> <a href="${meetinglink}" target="_blank">Join Meeting</a></p>
          <p>Please proceed with scheduling and conducting the interview as per your convenience.</p>
          
          <p>Best regards,</p>
          <p>Hirein5</p>
        `,
      };
      var emailobj1 = {
        email: singleinterview[0].Candidate[0].username,
        from_email: "connect@hirein5.com",
        subject: "Meeting Link",
        message: `
          <p>Dear Candidate,</p>
          <p>Your interview with <strong>${singleuser[0].first_name}</strong> has been successfully scheduled. Please join the interview using the link provided below at <strong>${singleinterview[0].time}</strong>.</p>
          <p><b>Meeting Link:</b> <a href="${meetinglink}" target="_blank">Join Meeting</a></p>
          <p>If you have any questions or need further assistance, feel free to reach out.</p>
          
          <p>Best regards,</p>
          <p>Hirein5</p>
        `,
      };

      await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singleinterview[0].id}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj1, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      GetInterviewdata();
      setTimeout(() => {
        setinterviewloader(false);
        setmeetinglink("");
        dispatch(storeAction.isPopUpHander());
      }, 3000);
    }
  };

  return (
    <>
      <div className="innerTable">
        <table className="table">
          <thead>
            <tr className="tableHead">
              <th>Candidate Name</th>
              <th>Qualification</th>
              <th>Experience</th>
              <th>Key Skills</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading == false ? (
              interviewData.length !== 0 ? (
                interviewData.map((data, index) => (
                  <tr className="tableRow" key={index}>
                    <td>
                      <div className="profileData">
                        {data.Candidate[0].profile_picture.length !== 0 ? (
                          <img
                            src={data.Candidate[0].profile_picture}
                            alt=""
                            className="mr-2 interviewimg"
                          />
                        ) : (
                          <Avatar
                            name={data.Candidate[0].first_name}
                            size={30}
                            round="50px"
                            className="mr-2"
                          />
                        )}
                        <h2>{data.Candidate[0].first_name.split(" ")[0]}</h2>
                      </div>
                    </td>
                    <td>
                      <h2>{data.Candidate[0].preference_info.qualification}</h2>
                    </td>
                    <td>
                      <h2>
                        {data.Candidate[0].preference_info.year_of_experience}{" "}
                        Years
                      </h2>
                    </td>
                    <td className="skillData1">
                      <>
                        {data.Candidate[0].preference_info.skills.length !== 0
                          ? data.Candidate[0].preference_info.skills.map(
                              (datanew, index) =>
                                index < 3 ? <p key={index}>{datanew}</p> : null
                            )
                          : null}
                      </>
                    </td>

                    <td>
                      <div className="flex gap-2">
                        <button
                          className="saveinterview"
                          onClick={() => {
                            viewsingle(data);
                          }}
                        >
                          View
                        </button>
                        {data.Candidate[0].hired_status == "Applied" && (
                          <button
                            className="acceptbtn"
                            // onClick={() => acceptbtn(data.Candidate[0])}
                          >
                            Move to Hire
                          </button>
                        )}
                        {data.Candidate[0].hired_status == "Approved" && (
                          <button
                            className="rejectbtn"
                            // onClick={() => rejectbtn(data.Candidate[0])}
                          >
                            Move to Bench
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="flex justify-center py-5">
                      <h6>No data found...</h6>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="flex justify-center py-5">
                    <FiLoader className="loadingIconHAT" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isPopUp === "ViewInvoiceHAT" && (
        <div className="reserveSuccessinterview">
          <h1 className="font-semibold text-xl">
            Schedule Interview Information
          </h1>
          {singleinterview.length !== 0 ? (
            singleinterview[0].time.length == 0 ? (
              <>
                <div className="flex gap-4 w-[90%] justify-between">
                  {singleinterview[0].groupdata.length !== 0
                    ? singleinterview[0].groupdata.map((data, index) => (
                        <div className="items-center mt-5" key={index}>
                          <h2 className="font-medium font-bold text-[#1D37E7]">
                            Schedule {index + 1}
                          </h2>
                          <h2 className="my-2">
                            <b>Date :</b> {data.date}
                          </h2>
                          <h2>
                            <b>Time :</b> {data.time}
                          </h2>
                        </div>
                      ))
                    : null}
                </div>
                {singleinterview[0].status == "true" ? (
                  interviewloader == true ? (
                    <button className="save mt-5">Please Wait...</button>
                  ) : (
                    <button className="save mt-5" onClick={sendto}>
                      Send to Candidate
                    </button>
                  )
                ) : (
                  <button className="cartbtnimgbutton1disablenew mt-5">
                    Send to Candidate
                  </button>
                )}
              </>
            ) : (
              <>
                <div className="flex gap-4 justify-start">
                  <div className="items-center text-center mt-5">
                    <h2 className="font-medium text-[#1D37E7]">Preferred Date & Time :</h2>
                    <h2 className="my-2">
                      <b>Date :</b>{" "}
                      {moment(singleinterview[0].time).format("DD-MM-YYYY")}
                    </h2>
                    <h2>
                      <b>Time :</b>{" "}
                      {singleinterview[0].time.length !== 0
                        ? `${moment(singleinterview[0].time).format("h:mm A")}`
                        : "-"}
                    </h2>
                    <div className="flex gap-4 items-center mt-3">
                      <h2 className="font-bold text-medium">
                        Add Meeting Link :{" "}
                      </h2>
                      <input
                        type="text"
                        className="border rounded p-2 border-black w-[400px]"
                        placeholder="Meeting link"
                        onChange={(e) => {
                          setmeetinglink(e.target.value);
                        }}
                        defaultValue={singleinterview[0].meeting_url}
                      />
                    </div>
                    {errormsg && (
                      <p className="text-red-500 py-2 font-medium px-4">
                        Please Add Meeting Link
                      </p>
                    )}
                    <div className="flex gap-4 justify-end mt-5">
                      <button
                        className="savecancel"
                        onClick={() => {
                          dispatch(storeAction.isPopUpHander());
                        }}
                      >
                        Cancel
                      </button>
                      {interviewloader == true ? (
                        <button className="save">Please Wait...</button>
                      ) : (
                        <button className="save" onClick={submitbtn}>
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )
          ) : null}
        </div>
      )}
    </>
  );
};

export default HatScheduleInterview;

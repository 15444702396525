/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./Table.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../../../Store/Store";
import { RxCross1 } from "react-icons/rx";
import { FiLoader } from "react-icons/fi";
import Avatar from "react-avatar";
import { FaBookmark } from "react-icons/fa";

const Table = (props) => {
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const bookmarkdata = useSelector((store) => store.bookmarkdata);
  const shortlistUser = useSelector((store) => store.shortlistUser);
  const userdata = useSelector((store) => store.userdata);

  const [isSelect, setIsSelect] = useState("Shortlisted");
  const [alluserdata, setalluserdata] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [loading, setloading] = useState(false);
  const [singleuser, setsingleuser] = useState([]);
  const [timezone, settimezone] = useState("");
  const [lodingid, setlodingid] = useState(null);

  useEffect(() => {
    const debounceGetUserinfo = setTimeout(() => {
      getUserinfo();
    }, 300); // Adjust the debounce delay (e.g., 300ms) as needed.

    return () => clearTimeout(debounceGetUserinfo); // Cleanup the timeout.
  }, [bookmarkdata]);

  const getUserinfo = async () => {
    if (bookmarkdata.length !== 0) {
      if (shortlistUser.length !== 0) {
        setalluserdata(shortlistUser);
        const unique = bookmarkdata.filter(
          (value, index, array) => array.indexOf(value) === index
        );

        const data = JSON.stringify({
          users_list: unique,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/getUsersInformation/${userid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios.request(config);

          if (response.data.length !== 0) {
            const validuser = response.data.filter((data) => !data.disabled);
            setalluserdata(validuser);
            dispatch(
              storeAction.shortlistUserHander({ shortlistUser: validuser })
            );
          }

          const config1 = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
            headers: {
              Authorization: `JWT ${token}`,
            },
          };

          const tabledataResponse = await axios(config1);
          settabledata(tabledataResponse.data);
        } catch (error) {
          handleSessionExpiration(error);
        }
      } else {
        const unique = bookmarkdata.filter(
          (value, index, array) => array.indexOf(value) === index
        );

        const data = JSON.stringify({
          users_list: unique,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/getUsersInformation/${userid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios.request(config);

          if (response.data.length !== 0) {
            const validuser = response.data.filter((data) => !data.disabled);
            setalluserdata(validuser);
            dispatch(
              storeAction.shortlistUserHander({ shortlistUser: validuser })
            );
          }

          const config1 = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
            headers: {
              Authorization: `JWT ${token}`,
            },
          };

          const tabledataResponse = await axios(config1);
          settabledata(tabledataResponse.data);
        } catch (error) {
          handleSessionExpiration(error);
        }
      }
    }
  };

  const handleSessionExpiration = (error) => {
    if (error.response?.status === 401) {
      dispatch(storeAction.reset());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/#/login");
    }
  };

  const removebtn = async (id) => {
    setlodingid(id);
    var checkdata = await tabledata.filter((data) => {
      return data.bookmarked_user == id;
    });
    if (checkdata.length !== 0) {
      var config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/${checkdata[0].id}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      await axios(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
      GetTabledata();
    }
    setTimeout(() => {
      setlodingid(null);
    }, 2000);
  };
  const GetTabledata = async () => {
    var config1 = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    var table_data = await axios(config1)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    if (table_data.length !== undefined) {
      if (table_data.length !== 0) {
        const bookmarkedUserArray = table_data.map(
          (item) => item.bookmarked_user
        );
        dispatch(
          storeAction.bookmarkdataHander({ bookmarkdata: bookmarkedUserArray })
        );
      } else {
        dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      }
    } else {
      dispatch(storeAction.reset());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/#/login");
    }
    settabledata(table_data);
  };

  const isPopUp = useSelector((store) => store.isPopUp);

  const overLayHandler = (e, data) => {
    dispatch(storeAction.isPopUpHander(e));
    setsingleuser([data]);
  };

  const submitbtn = async () => {
    setloading(true);
    try {
      // Validate dates and times
      const datetimeStrings = row
        .map((item) =>
          item.date && item.time1 ? `${item.date} ${item.time1}` : null
        )
        .filter(Boolean);

      if (datetimeStrings.length === 0) {
        alert("Please add at least one valid date and time.");
        setloading(false);
        return;
      }

      if (timezone.length === 0) {
        alert("Please select a time zone.");
        setloading(false);
        return;
      }

      // Prepare the data objects
      const combinedDatetimeString = datetimeStrings.join(";");
      const obj = {
        user: userid,
        datetime: combinedDatetimeString,
        candidate: singleuser[0].id,
        time: "",
        time_zone: timezone,
        meeting_url: "",
        status: "false",
      };

      const newobj = {
        status: "false",
        on_type: "Scheduled an interview",
        message: `Client <b>${userdata[0].first_name}</b> has scheduled an interview with candidate <b>${singleuser[0].first_name}</b>.`,
      };

      const emailobj = {
        email: `${userdata[0].username}`,
        from_email: "sales@hirein5.com",
        subject: `Client ${userdata[0].first_name} has scheduled an interview with candidate ${singleuser[0].first_name}`,
        message: `
          <p>Dear ${userdata[0].first_name},</p>
          <p>Thank you for providing your schedule for interviewing ${singleuser[0].first_name}. We shall get back to you shortly with a confirmed slot.</p>
          <p><b>Schedule Interview Information:</b></p>
          <ul>
            <li>Date 1: ${row[0].date}, Time: ${row[0].time1}</li>
            <li>Date 2: ${row[1].date}, Time: ${row[1].time1}</li>
            <li>Date 3: ${row[2].date}, Time: ${row[2].time1}</li>
          </ul>
          <p>You can log in to your account to manage or review your interview schedules. Please click the link below:</p>
          <p><a href="https://app.hirein5.com/#/login" target="_blank" style="color: #007BFF; text-decoration: none;"><b>Click here to log in</b></a></p>
          <p>Regards,</p>
          <p><b>Team Hirein5</b></p>
        `,
      };

      const adminEmailObjList = [
        "sales@hirein5.com",
        "dhruv@hirein5.com",
        "shikha@hirein5.com",
      ].map((adminEmail) => ({
        email: adminEmail,
        from_email: "connect@hirein5.com",
        subject: `New Interview Scheduled Between ${userdata[0].first_name} and ${singleuser[0].first_name}`,
        message: `
          <p>Dear Admin,</p>
          <p>A new interview has been scheduled between the client <b>${userdata[0].first_name} ${userdata[0].last_name}</b> and the candidate <b>${singleuser[0].first_name} ${singleuser[0].last_name}</b>.</p>
          <p><b>Interview Details:</b></p>
          <ul>
            <li><b>Client Name:</b> ${userdata[0].first_name} ${userdata[0].last_name}</li>
            <li><b>Client Email:</b> ${userdata[0].username}</li>
            <li><b>Candidate Name:</b> ${singleuser[0].first_name} ${singleuser[0].last_name}</li>
            <li><b>Candidate Email:</b> ${singleuser[0].username}</li>
          </ul>
          <p><b>Available Interview Time Slots:</b></p>
          <ul>
            <li>Date 1: ${row[0].date}, Time: ${row[0].time1}</li>
            <li>Date 2: ${row[1].date}, Time: ${row[1].time1}</li>
            <li>Date 3: ${row[2].date}, Time: ${row[2].time1}</li>
          </ul>
          <p>Please log in to the admin portal for more details and to manage the interview schedule:</p>
          <p><a href="https://app.hirein5.com/#/admin/login" target="_blank" style="color: #007BFF; text-decoration: none;"><b>Click here to log in</b></a></p>
          <p>For any queries or assistance, feel free to contact the support team.</p>
          <p>Regards,</p>
          <p><b>Team Hirein5</b></p>
        `,
      }));

      // Perform API calls
      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/create/interview/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userdata[0].id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
        emailobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      for (const adminEmailObj of adminEmailObjList) {
        await axios.post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`,
          adminEmailObj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
      }

      // Success
      setloading(false);
      dispatch(storeAction.isPopUpHander());
    } catch (error) {
      console.error("Error scheduling interview:", error);
      alert("An error occurred while scheduling the interview.");
      setloading(false);
    }
  };

  const [row, setrow] = useState([
    { date: "", time1: "" },
    { date: "", time1: "" },
    { date: "", time1: "" },
  ]);

  const handleChange = (index, field, value) => {
    const updatedRow = [...row];
    updatedRow[index][field] = value;
    setrow(updatedRow);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = alluserdata.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(alluserdata.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      {bookmarkdata.length !== 0 && alluserdata.length !== 0 ? (
        <div className={props.class}>
          <h1>Candidates for review</h1>
          <div className="tableButton marginTop20 marginBottom20">
            <button
              onClick={() => {
                setIsSelect("Shortlisted");
              }}
              className={
                isSelect === "Shortlisted"
                  ? "shortListedActive"
                  : "shortListedInActive"
              }
            >
              Shortlisted
            </button>
            <button
              onClick={() => {
                setIsSelect("Hired");
              }}
              className={
                isSelect === "Hired"
                  ? "shortListedActive"
                  : "shortListedInActive"
              }
            >
              Hired
            </button>
          </div>
          {isSelect === "Shortlisted" ? (
            <div className="innerTable">
              <table className="table">
                <tr className="tableHead">
                  <th className="tableFirst"></th>
                  <th>Candidate Name</th>
                  <th>Qualification</th>
                  <th>Experience</th>
                  <th>Key Skills</th>
                  <th></th>
                </tr>
                {currentItems.length !== 0
                  ? currentItems.map((data, index) => {
                      return (
                        <tr className="tableRow" key={index}>
                          <td className="profileBookMark">
                            {lodingid == data.id ? (
                              <FiLoader className="normal_loading normalloading" />
                            ) : (
                              <FaBookmark
                                className="bookmark_icondiv"
                                onClick={() => {
                                  removebtn(data.id);
                                }}
                              />
                            )}
                          </td>
                          <td>
                            <div className="profileData">
                              {data.profile_picture.length !== 0 ? (
                                <img
                                  src={data.profile_picture}
                                  alt=""
                                  className="mr-2 tableimg"
                                />
                              ) : (
                                <Avatar
                                  name={data.first_name}
                                  size={30}
                                  round="50px"
                                  className="mr-2"
                                />
                              )}
                              <h2>
                                {/* {data.first_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ") +
                                  " " +
                                  data.first_name
                                    .split(" ")
                                    .pop()
                                    .replace(/./g, "x")} */}
                                {data.first_name.split(" ")[0]}
                              </h2>
                            </div>
                          </td>
                          {data.preference_info !== null ? (
                            <>
                              {" "}
                              <td>
                                <h2>{data.preference_info.qualification}</h2>
                              </td>
                              <td>
                                <h2>
                                  {data.preference_info.year_of_experience}{" "}
                                  years
                                </h2>
                              </td>
                              <td className="skillData">
                                {data.preference_info.skills.length !== 0
                                  ? data.preference_info.skills.map(
                                      (data, index) =>
                                        index == 0 ||
                                        index == 1 ||
                                        index == 2 ? (
                                          <p key={index}>{data}</p>
                                        ) : null
                                    )
                                  : "-"}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>-</td> <td>-</td> <td>-</td>
                            </>
                          )}

                          <td>
                            <div>
                              {data.block_expiry == null ||
                              data.block_expiry.length == 0 ? (
                                <button
                                  onClick={() => {
                                    overLayHandler("scheduleinterview", data);
                                  }}
                                  className="tdBtn"
                                >
                                  Schedule interview
                                </button>
                              ) : (
                                <button
                                  // onClick={() => {
                                  //   overLayHandler("scheduleinterview", data);
                                  // }}
                                  className="tdBtndisble"
                                >
                                  Schedule interview
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
              <div className="flex justify-center py-5">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    style={{
                      margin: "0 10px",
                      backgroundColor:
                        currentPage === i + 1 ? "#1D37E7" : "#fff",
                      color: currentPage === i + 1 ? "#fff" : "#000",
                      border: "1px solid #1D37E7",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                  >
                    {i + 1}
                  </button>
                ))}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {isPopUp == "scheduleinterview" && (
        <>
          <div className="adminEditOverlayInterview">
            <div className="adminEditOverlayHead">
              <h1>Schedule Interview (choose upto 3 slots)</h1>
              <RxCross1
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              />
            </div>
            <div className="adminEditOverlayBody">
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 1</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) => handleChange(0, "date", e.target.value)}
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 1</h2>
                  <input
                    type="time"
                    onChange={(e) => handleChange(0, "time1", e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 2</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) => handleChange(1, "date", e.target.value)}
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 2</h2>
                  <input
                    type="time"
                    onChange={(e) => handleChange(1, "time1", e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Date 3</h2>
                  <input
                    type="date"
                    min="1000-01-01"
                    max="9999-12-31"
                    onChange={(e) => handleChange(2, "date", e.target.value)}
                  />
                </div>
                <div className="adminEditOverlayContent w-full">
                  <h2>Interview Time 3</h2>
                  <input
                    type="time"
                    onChange={(e) => handleChange(2, "time1", e.target.value)}
                  />
                </div>
              </div>
              <div className="adminEditOverlayContent">
                <h2>Time Zone</h2>
                <select
                  onChange={(e) => {
                    settimezone(e.target.value);
                  }}
                  name="time_zone"
                >
                  <option value="">Select</option>
                  <option value="(GMT-11:00) Pacific/Midway">
                    (GMT-11:00) Pacific/Midway
                  </option>
                  <option value="(GMT-11:00) Pacific/Niue">
                    (GMT-11:00) Pacific/Niue
                  </option>
                  <option value="(GMT-11:00) Pacific/Pago_Pago">
                    (GMT-11:00) Pacific/Pago_Pago
                  </option>
                  <option value="(GMT-10:00) Pacific/Honolulu">
                    (GMT-10:00) Pacific/Honolulu
                  </option>
                  <option value="(GMT-10:00) Pacific/Rarotonga">
                    (GMT-10:00) Pacific/Rarotonga
                  </option>
                  <option value="(GMT-10:00) Pacific/Tahiti">
                    (GMT-10:00) Pacific/Tahiti
                  </option>
                  <option value="(GMT-09:30) Pacific/Marquesas">
                    (GMT-09:30) Pacific/Marquesas
                  </option>
                  <option value="(GMT-09:00) America/Anchorage">
                    (GMT-09:00) America/Anchorage
                  </option>
                  <option value="(GMT-09:00) Pacific/Gambier">
                    (GMT-09:00) Pacific/Gambier
                  </option>
                  <option value="(GMT-08:00) America/Los_Angeles">
                    (GMT-08:00) America/Los_Angeles
                  </option>
                  <option value="(GMT-08:00) America/Tijuana">
                    (GMT-08:00) America/Tijuana
                  </option>
                  <option value="(GMT-08:00) America/Vancouver">
                    (GMT-08:00) America/Vancouver
                  </option>
                  <option value="(GMT-07:00) America/Denver">
                    (GMT-07:00) America/Denver
                  </option>
                  <option value="(GMT-07:00) America/Phoenix">
                    (GMT-07:00) America/Phoenix
                  </option>
                  <option value="(GMT-06:00) America/Chicago">
                    (GMT-06:00) America/Chicago
                  </option>
                  <option value="(GMT-06:00) America/Mexico_City">
                    (GMT-06:00) America/Mexico_City
                  </option>
                  <option value="(GMT-06:00) America/Regina">
                    (GMT-06:00) America/Regina
                  </option>
                  <option value="(GMT-05:00) America/New_York">
                    (GMT-05:00) America/New_York
                  </option>
                  <option value="(GMT-05:00) America/Toronto">
                    (GMT-05:00) America/Toronto
                  </option>
                  <option value="(GMT-05:00) America/Bogota">
                    (GMT-05:00) America/Bogota
                  </option>
                  <option value="(GMT-04:30) America/Caracas">
                    (GMT-04:30) America/Caracas
                  </option>
                  <option value="(GMT-04:00) America/Halifax">
                    (GMT-04:00) America/Halifax
                  </option>
                  <option value="(GMT-04:00) America/Santiago">
                    (GMT-04:00) America/Santiago
                  </option>
                  <option value="(GMT-03:30) America/St_Johns">
                    (GMT-03:30) America/St_Johns
                  </option>
                  <option value="(GMT-03:00) America/Argentina/Buenos_Aires">
                    (GMT-03:00) America/Argentina/Buenos_Aires
                  </option>
                  <option value="(GMT-02:00) Atlantic/South_Georgia">
                    (GMT-02:00) Atlantic/South_Georgia
                  </option>
                  <option value="(GMT-01:00) Atlantic/Azores">
                    (GMT-01:00) Atlantic/Azores
                  </option>
                  <option value="(GMT+00:00) Europe/London">
                    (GMT+00:00) Europe/London
                  </option>
                  <option value="(GMT+00:00) Africa/Abidjan">
                    (GMT+00:00) Africa/Abidjan
                  </option>
                  <option value="(GMT+01:00) Europe/Paris">
                    (GMT+01:00) Europe/Paris
                  </option>
                  <option value="(GMT+01:00) Africa/Algiers">
                    (GMT+01:00) Africa/Algiers
                  </option>
                  <option value="(GMT+02:00) Europe/Athens">
                    (GMT+02:00) Europe/Athens
                  </option>
                  <option value="(GMT+02:00) Africa/Johannesburg">
                    (GMT+02:00) Africa/Johannesburg
                  </option>
                  <option value="(GMT+03:00) Europe/Moscow">
                    (GMT+03:00) Europe/Moscow
                  </option>
                  <option value="(GMT+03:00) Asia/Baghdad">
                    (GMT+03:00) Asia/Baghdad
                  </option>
                  <option value="(GMT+03:30) Asia/Tehran">
                    (GMT+03:30) Asia/Tehran
                  </option>
                  <option value="(GMT+04:00) Asia/Dubai">
                    (GMT+04:00) Asia/Dubai
                  </option>
                  <option value="(GMT+04:30) Asia/Kabul">
                    (GMT+04:30) Asia/Kabul
                  </option>
                  <option value="(GMT+05:00) Asia/Karachi">
                    (GMT+05:00) Asia/Karachi
                  </option>
                  <option value="(GMT+05:30) Asia/Kolkata">
                    (GMT+05:30) Asia/Kolkata
                  </option>
                  <option value="(GMT+05:45) Asia/Kathmandu">
                    (GMT+05:45) Asia/Kathmandu
                  </option>
                  <option value="(GMT+06:00) Asia/Dhaka">
                    (GMT+06:00) Asia/Dhaka
                  </option>
                  <option value="(GMT+06:30) Asia/Yangon">
                    (GMT+06:30) Asia/Yangon
                  </option>
                  <option value="(GMT+07:00) Asia/Bangkok">
                    (GMT+07:00) Asia/Bangkok
                  </option>
                  <option value="(GMT+08:00) Asia/Hong_Kong">
                    (GMT+08:00) Asia/Hong_Kong
                  </option>
                  <option value="(GMT+09:00) Asia/Tokyo">
                    (GMT+09:00) Asia/Tokyo
                  </option>
                  <option value="(GMT+09:30) Australia/Darwin">
                    (GMT+09:30) Australia/Darwin
                  </option>
                  <option value="(GMT+10:00) Australia/Sydney">
                    (GMT+10:00) Australia/Sydney
                  </option>
                  <option value="(GMT+11:00) Pacific/Noumea">
                    (GMT+11:00) Pacific/Noumea
                  </option>
                  <option value="(GMT+12:00) Pacific/Fiji">
                    (GMT+12:00) Pacific/Fiji
                  </option>
                  <option value="(GMT+13:00) Pacific/Tongatapu">
                    (GMT+13:00) Pacific/Tongatapu
                  </option>
                  <option value="(GMT+14:00) Pacific/Kiritimati">
                    (GMT+14:00) Pacific/Kiritimati
                  </option>
                </select>
              </div>
            </div>

            <div className="editOverlayButton">
              <button
                className="discard"
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              >
                Discard
              </button>

              {loading === false ? (
                <button className="save" onClick={submitbtn}>
                  Submit
                </button>
              ) : (
                <button className="save w-[10rem] flex justify-center items-center">
                  <FiLoader className="loadingIcon" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./AClientProfileView.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import { FiLoader } from "react-icons/fi";
import Avatar from "react-avatar";
import axios from "axios";
import ContractCard from "../../../Reusable/ContractCard/ContractCard";
import { IoMdArrowBack } from "react-icons/io";
import contractCard from "../../../../assests/contractCard.png";
import moment from "moment";
import { RxCross1, RxCross2 } from "react-icons/rx";
import Select from "react-select";
import { firebase } from "../../../../database/firebase";
import BillinginfoNew from "../../../NewClientFlow/BillinginfoNew";
import Onlywallet from "../../../Client/ClientSignUp/WalletModule/Onlywallet";
import HatScheduleInterview from "./HatScheduleInterview";
import ScheduleInterview from "./ScheduleInterview";

const AClientProfileView = () => {
  const singleuser = useSelector((store) => store.singleuser);
  const allcompanydata = useSelector((store) => store.allcompanydata);
  const alluserdata = useSelector((store) => store.alluserdata);
  const token = useSelector((store) => store.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState("billing");

  const toggleHandler = (e) => {
    setIsToggle(e.target.id);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const editHandler1 = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const [loading, setIsLoading] = useState(false);
  const [formdata, setformdata] = useState([]);
  const [selectedOptionskill, setSelectedOptionskill] = useState(null);
  const [skilloption, setskilloption] = useState([]);
  const [allbookmarkuserdata, setallbookmarkuserdata] = useState([]);
  const [bookmarkload, setbookmarkload] = useState(false);
  const [allhireduserdata, setallhireduserdata] = useState([]);
  const [hireload, sethireload] = useState(false);
  const [allreservedduserdata, setallreservedduserdata] = useState([]);
  const [reservedload, setreservedload] = useState(false);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [updateid, setupdateid] = useState(null);
  const [uploadstatus, setuploadstatus] = useState(false);
  const [name, setname] = useState(null);
  const [formData] = useState(new FormData());
  const [hirecandidate, sethirecandidate] = useState([]);

  useEffect(() => {
    getAllinfo();
  }, [singleuser, alluserdata]);

  const getAllinfo = async () => {
    if (alluserdata.length !== 0) {
      const filter1 = alluserdata
        .filter((user) => user.first_name.length !== 0)
        .map((user) => ({
          value: user.id,
          label: user.first_name,
        }));
      setskilloption(filter1);
    }

    setbookmarkload(true);
    sethireload(true);
    setreservedload(true);

    const promises = [
      axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/candidates/${singleuser[0].id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      ),
      axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get/hiredcandidates/${singleuser[0].id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      ),
      axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/reservation/blockedusers/${singleuser[0].id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      ),
    ];

    // Use Promise.all to run all requests concurrently
    const [bookmarkResponse, hiredResponse, reservedResponse] =
      await Promise.all(promises)
        .then((responses) => responses.map((response) => response.data))
        .catch((errors) => {
          console.error("Error fetching data", errors);
          // Handle errors as needed
          return [[], [], []]; // Default to empty arrays on error
        });

    // Handle responses
    setallbookmarkuserdata(
      bookmarkResponse.length !== 0 ? bookmarkResponse : []
    );
    setbookmarkload(false);
    if (hiredResponse.length !== 0) {
      var finaldata = await hiredResponse.filter((data) => {
        return data.candidate_data !== null;
      });
      setallhireduserdata(finaldata.length !== 0 ? finaldata : []);
    } else {
      setallhireduserdata([]);
    }

    setallreservedduserdata(
      reservedResponse.length !== 0 ? reservedResponse : []
    );
    setTimeout(() => {
      sethireload(false);
      setreservedload(false);
    }, 1000);
  };

  const getalldata = async (data) => {
    const index = allcompanydata.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      const updatedArray = [...allcompanydata];
      updatedArray[index] = { ...updatedArray[index], ...data };
      dispatch(
        storeAction.allcompanydataHander({ allcompanydata: updatedArray })
      );
    }
  };

  const handleFileInputChange = async (e) => {
    formData.append("image", e.target.files[0]);
    formData.append("name", `contract_${singleuser[0].id}`);
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`contract_${singleuser[0].id}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new2 = await file13;

    if (imgurl_new2.length !== 0) {
      var obj = {
        contracts_info: {
          file: imgurl_new2,
          name: e.target.files[0].name,
          user: singleuser[0].id,
        },
      };
      if (e.target.name === "upload") {
        var createdata = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/getContracts/${singleuser[0].id}/`,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (createdata !== null) {
          fileInputRef.current.value = "";
          fileInputRef1.current.value = "";
          getAll_data();
        }
      } else {
        var create_data = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/getContracts/${updateid}/`,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (create_data !== null) {
          fileInputRef.current.value = "";
          fileInputRef1.current.value = "";
          getAll_data();
        }
      }
    }
  };

  const handleFileInput_Change = async (e) => {
    formData.append("image", e.target.files[0]);
    formData.append("name", `contract_${singleuser[0].id}`);
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`contract_${singleuser[0].id}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new2 = await file13;
    setname(e.target.files[0].name);
    if (imgurl_new2.length !== 0) {
      setuploadstatus(true);
      setaddcontractdata((values) => ({
        ...values,
        file: imgurl_new2,
      }));
      fileInputRef.current.value = "";
    }
  };

  const showhandler = (data) => {
    fileInputRef.current.click();
  };

  const showhandler1 = (data) => {
    setupdateid(data.id);
    fileInputRef1.current.click();
  };

  useEffect(() => {
    getAll_data();
  }, [singleuser]);

  const getAll_data = async () => {
    if (singleuser.length !== 0) {
      var contactdata = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/getContracts/${singleuser[0].id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      setformdata(contactdata);
    }
  };

  const disablebtn = async (data) => {
    setIsLoading(true);
    var obj = {
      username: data.username,
      dissabled: true,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...singleuser[0],
        dissabled: true,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedObject);
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };

  const enablebtn = async (data) => {
    setIsLoading(true);
    var obj = {
      username: data.username,
      dissabled: false,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...singleuser[0],
        dissabled: false,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedObject);
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };

  const [addcontractdata, setaddcontractdata] = useState({
    contract_type: "",
    hired_on: "",
    contract_duration: "",
    billing_cycle: "",
    file: "",
  });

  const handlechange_contract = async (e) => {
    const { name, value } = e.target;
    setaddcontractdata((values) => ({ ...values, [name]: value }));
  };

  const createbtn = async () => {
    setIsLoading(true);
    if (addcontractdata.contract_type === "Statement of Work (SOW)") {
      if (selectedOptionskill !== null) {
        var checkuser = await alluserdata.filter((data) => {
          return data.id == selectedOptionskill.value;
        });
        var obj_new = {
          username: checkuser[0].username,
          status: "Hired",
          hire_info: {
            candidate_id: checkuser[0].id,
            company_name: singleuser[0].company.company_name,
            client_id: singleuser[0].id,
            contract_id: "",
          },
        };

        var updatedata = await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${checkuser[0].id}/`,
            obj_new,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });

        if (
          updatedata.message === "User and Associated Info updated successfully"
        ) {
          var emailobj = {
            email: checkuser[0].username,
            from_email: "connect@hirein5.com",
            subject: "Confirmation of Hiring!",
            message: `I am pleased to confirm my acceptance of the offer to join ${singleuser[0].company.company_name}. Excited to be part of the team!`,
          };
          await axios
            .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          getalldata(updatedata.user);
          setSelectedOptionskill(null);
        }
      }
    }
    setIsLoading(true);
    setuploadstatus(false);
    // dispatch(storeAction.isPopUpHander());
    var obj = {
      contracts_info: {
        file: addcontractdata.file,
        name: addcontractdata.contract_type,
        date: addcontractdata.hired_on,
        duration: addcontractdata.contract_duration,
        billing_cycle: addcontractdata.billing_cycle,
        candidate:
          selectedOptionskill !== null ? selectedOptionskill.value : null,
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/getContracts/${singleuser[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setaddcontractdata({
      contract_type: "",
      hired_on: "",
      contract_duration: "",
      billing_cycle: "",
      file: "",
    });
    setSelectedOptionskill(null);
    setIsLoading(false);
    getAll_data();
    setTimeout(() => {
      dispatch(storeAction.isPopUpHander());
    }, 2000);
  };

  const [type, settype] = useState("");
  const [loadingpopup, setloadingpopup] = useState(false);

  const deletebtn = async (data) => {
    settype("delete");
    setIsLoading(true);
    var arrayOfObjects = allcompanydata.filter((obj) => obj.id !== data.id);
    dispatch(
      storeAction.allcompanydataHander({ allcompanydata: arrayOfObjects })
    );
    await axios.delete(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    window.location.replace("/#/customerProfile");
  };

  const viewbtn = (data) => {
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/admincandidateview");
  };

  const acceptbtn = async (data) => {
    dispatch(storeAction.isPopUpHander("Hireconformation"));
    sethirecandidate([data]);
  };

  const rejectbtn = async (data) => {
    dispatch(storeAction.isPopUpHander("HireReject"));
    sethirecandidate([data]);
  };

  const rejectbtnHire = async (data) => {
    setloadingpopup(true);
    var obj = {
      username: data.candidate_data.username,
      hired_status: "",
      status: "Success",
    };

    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.candidate_data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      await axios
        .delete(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/delete/hiredcandidates/${data.id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      getAllinfo();
      dispatch(storeAction.isPopUpHander());
      setallhireduserdata([]);
      setloadingpopup(false);
    } else {
      setloadingpopup(false);
    }
  };

  const CloseOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const yeshire = async () => {
    setloadingpopup(true);
    var obj = {
      username: hirecandidate[0].username,
      hired_status: "Approved",
      status: "Hired",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${hirecandidate[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      setloadingpopup(false);
      getAllinfo();
      dispatch(storeAction.isPopUpHander());
      setallhireduserdata([]);
    } else {
      setloadingpopup(false);
    }
  };

  const yesMove = async () => {
    setloadingpopup(true);
    var obj = {
      username: hirecandidate[0].username,
      hired_status: "Applied",
      status: "Success",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${hirecandidate[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      getAllinfo();
      dispatch(storeAction.isPopUpHander());
      setallhireduserdata([]);
      setloadingpopup(false);
    } else {
      setloadingpopup(false);
    }
  };

  return (
    <div>
      {singleuser.length !== 0 ? (
        <div className="clientProfileOverview paddingLeft100 paddingRight100 ">
          <div className="clientProfileViewHeader">
            <div className="ClientProfileBackButton">
              <span onClick={() => navigate("/customerProfile")}>
                {" "}
                <IoMdArrowBack />
              </span>
              <h5
                className="cursor-pointer"
                onClick={() => navigate("/customerProfile")}
              >
                Back to profile page
              </h5>
            </div>
            <div className="clientProfileViewFlex">
              <div className="clientProfileViewFlexLeft">
                <div className="clientProfileViewFlexLeftImg">
                  {singleuser[0].profile_picture.length !== 0 ? (
                    <img src={singleuser[0].profile_picture} alt="" />
                  ) : singleuser[0].company !== null ? (
                    singleuser[0].company.company_name.length !== 0 ? (
                      <Avatar
                        name={singleuser[0].company.company_name}
                        size={100}
                        round="50px"
                      />
                    ) : (
                      <Avatar
                        name={singleuser[0].first_name}
                        size={100}
                        round="50px"
                      />
                    )
                  ) : (
                    <Avatar
                      name={singleuser[0].first_name}
                      size={100}
                      round="50px"
                    />
                  )}
                </div>
                <div className="clientProfileViewFlexLeftDesc">
                  {singleuser[0].company !== null ? (
                    singleuser[0].company.company_name.length !== 0 ? (
                      <h1>{singleuser[0].company.company_name}</h1>
                    ) : null
                  ) : null}

                  <div className="clientProfileViewFlexLeftDescRole">
                    <h2>{singleuser[0].first_name} - </h2>
                    <h3>{singleuser[0].email}</h3>
                  </div>
                  <div className="clientProfileViewFlexLeftDescLocation">
                    {/* <img src={clientProfile} alt="" /> */}
                    <h2>{singleuser[0].current_place_of_residence}</h2>
                  </div>
                </div>
              </div>
              <div className="clientProfileViewFlexRight">
                {singleuser[0].dissabled == false ? (
                  loading === false ? (
                    <button
                      className="disableProfile"
                      onClick={() => {
                        disablebtn(singleuser[0]);
                      }}
                    >
                      Disable profile
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )
                ) : loading === false ? (
                  <button
                    className="disableProfile"
                    onClick={() => {
                      enablebtn(singleuser[0]);
                    }}
                  >
                    Activate profile
                  </button>
                ) : type !== "delete" ? (
                  <button className="save w-[10rem] flex justify-center items-center">
                    <FiLoader className="loadingIcon" />
                  </button>
                ) : (
                  <button
                    className="disableProfile"
                    onClick={() => {
                      enablebtn(singleuser[0]);
                    }}
                  >
                    Activate profile
                  </button>
                )}
                {loading === true ? (
                  type === "delete" ? (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  ) : (
                    <button
                      className="delete_btn"
                      onClick={() => {
                        deletebtn(singleuser[0]);
                      }}
                    >
                      Delete Profile
                    </button>
                  )
                ) : (
                  <button
                    className="delete_btn"
                    onClick={() => {
                      deletebtn(singleuser[0]);
                    }}
                  >
                    Delete Profile
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="clientProfileViewHeaderMob">
            <div className="ClientProfileBackButton">
              <span onClick={() => navigate("/customerProfile")}>
                <IoMdArrowBack />
              </span>

              <h5
                className="cursor-pointer"
                onClick={() => navigate("/customerProfile")}
              >
                Back to profile page
              </h5>
            </div>
            <div className="clientProfileViewFlex">
              <div className="clientProfileViewFlexLeft">
                <div className="clientProfileViewFlexLeftImg">
                  {singleuser[0].profile_picture.length !== 0 ? (
                    <img src={singleuser[0].profile_picture} alt="" />
                  ) : (
                    <Avatar
                      name={singleuser[0].first_name}
                      size={100}
                      round="50px"
                    />
                  )}
                </div>
                <div className="clientProfileViewFlexLeftDesc">
                  <div className="clientProfileViewFlexLeftDescHead">
                    <h1>{singleuser[0].first_name}</h1>
                    {/* {singleuser[0].apprual === false ? (
                      <span className="pendingApproval">Approval Pending</span>
                    ) : (
                      <img src={approvedTick} alt="" />
                    )} */}
                  </div>
                  {singleuser[0].preference_info !== null ? (
                    <div className="clientProfileViewFlexLeftDescRole">
                      <h2>{singleuser[0].preference_info.qualification}</h2>
                    </div>
                  ) : null}

                  <div className="clientProfileViewFlexLeftDescLocation">
                    {/* <img src={candidateProfile} alt="" /> */}
                    <h2>{singleuser[0].current_place_of_residence}</h2>
                    {singleuser[0].rate_card_info !== null ? (
                      <h2>
                        USD {singleuser[0].rate_card_info.remote_hourly}/hr
                      </h2>
                    ) : (
                      <h2>Not provided yet</h2>
                    )}
                  </div>
                </div>
              </div>
              <div className="clientProfileViewFlexRightMob">
                {/* <button onClick={overLayHandler} className="editRate">
                  <img src={editOutline} alt="" />
                  Edit Rate (Pricing)
                </button> */}
                <div className="clientProfileViewFlexRightButtonMobClient">
                  <button
                    // id="approveconformation"
                    onClick={editHandler1}
                    className="disableProfile"
                  >
                    Delete profile
                  </button>
                  <button className="disableProfile">Disable Profile</button>
                </div>

                {isPopUp == "approvedropdown" &&
                  (singleuser.length !== 0 ? (
                    <div className="approvalMenu">
                      {singleuser[0].apprual === false ? (
                        <h3
                          id="approveconformation"
                          onClick={editHandler1}
                          className="approvalMenuActive"
                        >
                          Disable profile
                        </h3>
                      ) : null}

                      <h3 className="approvalMenuDisable">Disable Profile</h3>
                    </div>
                  ) : null)}
              </div>
              {/* <div className="calendlyLink">
                <h4>Calendly Link (for interview)</h4>
                <input
                  type="text"
                  placeholder="https://calendly.com/meet/usernamelink"
                />
                <h5>Edit</h5>
              </div> */}
            </div>
          </div>
          <Onlywallet />
          <div className="clientViewTab">
            <h5
              onClick={toggleHandler}
              id="billing"
              className={
                isToggle === "billing"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Billing
            </h5>
            <h5
              onClick={toggleHandler}
              id="adminclientcontracts"
              className={
                isToggle === "adminclientcontracts"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Contracts
            </h5>
            <h5
              onClick={toggleHandler}
              id="Hirecandidate"
              className={
                isToggle === "Hirecandidate"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Hire Candidate
            </h5>
            <h5
              onClick={toggleHandler}
              id="Bookmarkcandidate"
              className={
                isToggle === "Bookmarkcandidate"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Bookmark Candidate
            </h5>
            <h5
              onClick={toggleHandler}
              id="Reservedcandidate"
              className={
                isToggle === "Reservedcandidate"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Reserved Candidate
            </h5>
            <h5
              onClick={toggleHandler}
              id="scheduleinterview"
              className={
                isToggle === "scheduleinterview"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Schedule Interview
            </h5>
          </div>

          {isToggle === "billing" && <BillinginfoNew />}
          {isToggle == "adminclientcontracts" && (
            <>
              <div className="paddingTop50">
                <div className="adminContractCard">
                  <ContractCard name="Non Disclosure Agreement (NDA)" />
                  <ContractCard name="Master Service Agreement (MSA)" />
                  {/* <ContractCard name="Statement of Work (SOW)" /> */}
                  {formdata.length !== 0
                    ? formdata.map((data, index) =>
                        data.name != "Non Disclosure Agreement (NDA)" &&
                        data.name != "Master Service Agreement (MSA)" &&
                        data.name == "Statement of Work (SOW)" ? (
                          <div className="contractCard" key={index}>
                            <div
                              className="contractInner"
                              onClick={() => {
                                window.open(`${data.file}`, "_blank");
                              }}
                            >
                              <div className="contractInnerImg">
                                <img src={contractCard} alt="" />
                              </div>
                              <div className="contractInnerDesc">
                                <h2>{data.name}</h2>
                                Updated on{" "}
                                {moment(data.uplaod_date).format("DD/MM/YYYY")}
                              </div>
                            </div>

                            <input
                              type="file"
                              ref={fileInputRef1}
                              style={{ display: "none" }}
                              name="uploadagain"
                              onChange={handleFileInputChange}
                            />
                            <button
                              title=""
                              onClick={() => {
                                showhandler1(data);
                              }}
                            >
                              Upload again
                            </button>
                          </div>
                        ) : null
                      )
                    : null}
                  <div className="addContractCard">
                    <p
                      className="pointer"
                      onClick={editHandler1}
                      id="addcontract"
                    >
                      + Add contract
                    </p>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      name="upload"
                      // onChange={handleFileInputChange}
                    />
                  </div>
                </div>
                {/* <p>Contract Uploaded Successfully</p> */}
                {isPopUp == "addcontract" && (
                  <>
                    <div className="adminEditOverlay">
                      <div className="adminEditOverlayHead">
                        <h1>Add New Contract</h1>
                        <RxCross1 onClick={editHandler1} />
                      </div>
                      <div className="adminEditOverlayBody">
                        <div className="adminEditOverlayContent">
                          <h2>Select Candidate</h2>
                          <Select
                            defaultValue={selectedOptionskill}
                            onChange={setSelectedOptionskill}
                            options={skilloption}
                          />
                        </div>
                        <div className="adminEditOverlayContent">
                          <h2>Contract Type</h2>
                          <select
                            name="contract_type"
                            onChange={handlechange_contract}
                            defaultValue={addcontractdata.contract_type}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="Non Disclosure Agreement (NDA)">
                              Non Disclosure Agreement (NDA)
                            </option>
                            <option value="Master Service Agreement (MSA)">
                              Master Service Agreement (MSA)
                            </option>
                            <option value="Statement of Work (SOW)">
                              Statement of Work (SOW)
                            </option>
                          </select>
                        </div>
                        <div className="adminEditOverlayContent">
                          <h2>Hired on</h2>
                          <input
                            type="date"
                            name="hired_on"
                            onChange={handlechange_contract}
                            defaultValue={addcontractdata.hired_on}
                            min="1000-01-01"
                            max="9999-12-31"
                          />
                        </div>
                        <div className="adminEditOverlayContent">
                          <h2>Contract duration</h2>
                          <select
                            name="contract_duration"
                            onChange={handlechange_contract}
                            defaultValue={addcontractdata.contract_duration}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="3 Months">3 Months</option>
                            <option value="6 Months">6 Months</option>
                            <option value="9 Months">9 Months</option>
                            <option value="12 Months">12 Months</option>
                          </select>
                        </div>
                        <div className="adminEditOverlayContent">
                          <h2>Billing cycle</h2>
                          <select
                            name="billing_cycle"
                            onChange={handlechange_contract}
                            defaultValue={addcontractdata.billing_cycle}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                          </select>
                        </div>
                        <div className="adminEditOverlayContent"></div>
                      </div>
                      <div className="px-12">
                        <div
                          onClick={showhandler}
                          className="uploadCertificate w-full"
                        >
                          <h2 className="drop">
                            Drag your fies here to{" "}
                            <span className="browser">Browse</span>
                          </h2>
                          <h3>PDF, JPEG and PNG accepted</h3>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          name="aadhaarfront"
                          onChange={handleFileInput_Change}
                        />
                        {uploadstatus && (
                          <p className="text-green-500 text-sm">
                            File Uploaded Successfully
                          </p>
                        )}
                      </div>

                      <div className="editOverlayButton">
                        <button
                          className="discard"
                          onClick={() => {
                            dispatch(storeAction.isPopUpHander());
                          }}
                        >
                          Cancel
                        </button>

                        {loading === false ? (
                          <button className="save" onClick={createbtn}>
                            Create
                          </button>
                        ) : (
                          <button className="save w-[10rem] flex justify-center items-center">
                            <FiLoader className="loadingIcon" />
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {isToggle == "Hirecandidate" && (
            <div className="">
              <div>
                <div className="innerTable">
                  <table className="table">
                    <thead>
                      <tr className="tableHead">
                        <th>Candidate Name</th>
                        <th>Qualification</th>
                        <th>Experience</th>
                        <th>Key Skills</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hireload == false ? (
                        allhireduserdata.length !== 0 ? (
                          allhireduserdata.map(
                            (data, index) =>
                              data.candidate_data !== null && (
                                <tr className="adminTableRow" key={index}>
                                  <td>
                                    <div className="tableName">
                                      {data.candidate_data.profile_picture
                                        .length !== 0 ? (
                                        <img
                                          src={
                                            data.candidate_data.profile_picture
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <Avatar
                                          name={data.candidate_data.first_name}
                                          size={30}
                                          round="50px"
                                        />
                                      )}
                                      <h1>{data.candidate_data.first_name}</h1>
                                    </div>
                                  </td>
                                  <td>
                                    {data.candidate_data.new_candidate_info !==
                                    null ? (
                                      <h1>
                                        {
                                          data.candidate_data.new_candidate_info
                                            .location
                                        }
                                      </h1>
                                    ) : (
                                      <h1>-</h1>
                                    )}
                                  </td>
                                  <td>
                                    <h1>{data.candidate_data.phone}</h1>
                                  </td>
                                  <td>
                                    <h1>
                                      {data.candidate_data.email.toLowerCase()}
                                    </h1>
                                  </td>
                                  <td>
                                    <div className="flex gap-2">
                                      <button
                                        className="viewbtn"
                                        onClick={() =>
                                          viewbtn(data.candidate_data)
                                        }
                                      >
                                        View
                                      </button>
                                      {data.candidate_data.hired_status ==
                                        "Applied" && (
                                        <button
                                          className="acceptbtn"
                                          onClick={() =>
                                            acceptbtn(data.candidate_data)
                                          }
                                        >
                                          Move to Hire
                                        </button>
                                      )}
                                      {data.candidate_data.hired_status ==
                                        "Approved" && (
                                        <button
                                          className="rejectbtn"
                                          onClick={() =>
                                            rejectbtn(data.candidate_data)
                                          }
                                        >
                                          Move to Bench
                                        </button>
                                      )}
                                      {data.candidate_data.hired_status !==
                                      "" ? (
                                        <button
                                          className="rejectbtn"
                                          onClick={() => {
                                            rejectbtnHire(data);
                                          }}
                                        >
                                          X
                                        </button>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              )
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className="flex justify-center py-5">
                                <h6>No data found...</h6>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="flex justify-center py-5">
                              <FiLoader className="loadingIconHAT" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {isToggle == "Bookmarkcandidate" && (
            <div className="">
              <div>
                <div className="innerTable">
                  <table className="table">
                    <thead>
                      <tr className="AdminTableHead">
                        <th>Candidate Name</th>
                        <th>Location</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allbookmarkuserdata.length !== 0
                        ? allbookmarkuserdata.map((data, index) =>
                            data.candidate_data !== null ? (
                              <tr className="adminTableRow" key={index}>
                                <td>
                                  <div className="tableName">
                                    {data.candidate_data.profile_picture
                                      .length !== 0 ? (
                                      <img
                                        src={
                                          data.candidate_data.profile_picture
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        name={data.candidate_data.first_name}
                                        size={30}
                                        round="50px"
                                      />
                                    )}
                                    <h1>{data.candidate_data.first_name}</h1>
                                  </div>
                                </td>
                                <td>
                                  {data.candidate_data.new_candidate_info !==
                                  null ? (
                                    <h1>
                                      {
                                        data.candidate_data.new_candidate_info
                                          .location
                                      }
                                    </h1>
                                  ) : (
                                    <h1>-</h1>
                                  )}
                                </td>
                                <td>
                                  <h1>{data.candidate_data.phone}</h1>
                                </td>
                                <td>
                                  <h1>
                                    {data.candidate_data.email.toLowerCase()}
                                  </h1>
                                </td>

                                <td>
                                  <div className="flex gap-2">
                                    <button
                                      className="viewbtn"
                                      onClick={() =>
                                        viewbtn(data.candidate_data)
                                      }
                                    >
                                      View
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        : !bookmarkload && (
                            <tr>
                              <td colSpan={7}>
                                <div className="flex justify-center py-5">
                                  <h6>No data found...</h6>
                                </div>
                              </td>
                            </tr>
                          )}
                      {bookmarkload && (
                        <tr>
                          <td colSpan={7}>
                            <div className="flex justify-center py-5">
                              <FiLoader className="loadingIconHAT" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {isToggle == "Reservedcandidate" && (
            <div className="">
              <div>
                <div className="innerTable">
                  <table className="table">
                    <thead>
                      <tr className="AdminTableHead">
                        <th>Candidate Name</th>
                        <th>Location</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservedload == false ? (
                        allreservedduserdata.length !== 0 ? (
                          allreservedduserdata.map((data, index) =>
                            data.candidate_data !== null ? (
                              <tr className="adminTableRow" key={index}>
                                <td>
                                  <div className="tableName">
                                    {data.candidate_data.profile_picture
                                      .length !== 0 ? (
                                      <img
                                        src={
                                          data.candidate_data.profile_picture
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        name={data.candidate_data.first_name}
                                        size={30}
                                        round="50px"
                                      />
                                    )}
                                    <h1>{data.candidate_data.first_name}</h1>
                                  </div>
                                </td>
                                <td>
                                  {data.candidate_data.new_candidate_info !==
                                  null ? (
                                    <h1>
                                      {
                                        data.candidate_data.new_candidate_info
                                          .location
                                      }
                                    </h1>
                                  ) : (
                                    <h1>-</h1>
                                  )}
                                </td>
                                <td>
                                  <h1>{data.candidate_data.phone}</h1>
                                </td>
                                <td>
                                  <h1>
                                    {data.candidate_data.email.toLowerCase()}
                                  </h1>
                                </td>

                                <td>
                                  <div className="flex gap-2">
                                    <button
                                      className="viewbtn"
                                      onClick={() =>
                                        viewbtn(data.candidate_data)
                                      }
                                    >
                                      View
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className="flex justify-center py-5">
                                <h6>No data found...</h6>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="flex justify-center py-5">
                              <FiLoader className="loadingIconHAT" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {isToggle == "scheduleinterview" && (
            <div className="">
              <div>
                <ScheduleInterview isToggle={isToggle} />
                <h2>Hire a Team Schedule Interview</h2>
                <HatScheduleInterview isToggle={isToggle} />
              </div>
            </div>
          )}
        </div>
      ) : null}

      {isPopUp == "Hireconformation" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Hire Candidate</h1>
              <span onClick={CloseOverlay}>
                <RxCross2 />
              </span>
            </div>
            <div className="approveCandidateOverlayBody">
              <p>Are you sure you want to proceed with hiring?</p>
              <div className="approveCandidateOverlayButton">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Cancel
                </button>
                {loadingpopup == true ? (
                  <button className="save">Please Wait...</button>
                ) : (
                  <button onClick={yeshire} className="save">
                    Yes, Hire
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUp == "HireReject" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Hire Candidate</h1>
              <span onClick={CloseOverlay}>
                <RxCross2 />
              </span>
            </div>
            <div className="approveCandidateOverlayBody">
              <p>Are you sure you want to move this candidate to bench ?</p>
              <div className="approveCandidateOverlayButton">
                <button
                  className="discard"
                  onClick={() => {
                    dispatch(storeAction.isPopUpHander());
                  }}
                >
                  Cancel
                </button>
                {loadingpopup == true ? (
                  <button className="save">Please Wait...</button>
                ) : (
                  <button onClick={yesMove} className="save">
                    Yes, Move
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AClientProfileView;

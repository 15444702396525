/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "../NewCandidateFlow/Pagination";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { FiLoader } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Vendorlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customToastStyle = {
    background: "#14B8A6",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    color: "white",
    "--toastify-icon-color-success": "white",
  };
  const vendorlist = useSelector((store) => store.vendorlist);
  const token = useSelector((store) => store.token);
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [recordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);
  const [selectid, setselectid] = useState(null);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = alldata.filter((skill) => {
        return skill.first_name.toLowerCase().includes(e.toLowerCase());
      });
      setalldata(matchingSkills);
    } else {
      setalldata(totaldata);
    }
  };
  useEffect(() => {
    Getcandidate();
  }, []);
  const Getcandidate = async () => {
    if (vendorlist.length !== 0) {
      setalldata(vendorlist);
      setloading(false);
      settotaldata(vendorlist);
      var allfacility = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties/vendors/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility !== undefined) {
        if (allfacility.length !== 0) {
          setloading(false);
          setalldata(allfacility);
          settotaldata(allfacility);
          dispatch(storeAction.vendorlistHander({ vendorlist: allfacility }));
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      var allfacility1 = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties/vendors`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility1 !== undefined) {
        if (allfacility1.length !== 0) {
          setloading(false);
          setalldata(allfacility1);
          settotaldata(allfacility1);
          dispatch(storeAction.vendorlistHander({ vendorlist: allfacility1 }));
        }
      } else {
        setloading(false);
      }
    }
    setloading(false);
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = alldata.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(alldata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const acceptbtn = async (data) => {
    setloadingnew(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Welcome to Hirein5: Set Up Your Account Today!",
      message: `
        <p>Dear ${data.first_name},</p>
        
        <p>Thank you for registering with Hirein5! To get started, please set up your password by clicking the link below:</p>
        
        <p><a href="https://app.hirein5.com/#/createpassword/${data.email}" target="_blank" style="color: #007bff; text-decoration: none;">Set Up Your Password</a></p>
        
        <p>If you have any questions, feel free to contact us at <a href="mailto:careers@hirein5.com" style="color: #007bff; text-decoration: none;">careers@hirein5.com</a>.</p>
        
        <p>We are excited to have you onboard and look forward to helping you achieve your career goals!</p>
        
        <p>Best regards,<br>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties/vendors`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1 !== undefined) {
      if (allfacility1.length !== 0) {
        setloading(false);
        setalldata(allfacility1);
        settotaldata(allfacility1);
        dispatch(storeAction.vendorlistHander({ vendorlist: allfacility1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const rejectbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      from_email: "connect@hirein5.com",
      subject: "Application Update!",
      message: `<p>Hi ${data.first_name},</p>
                <p>Thank you for your interest in Hirein5.</p>
                <p>We regret to inform you that we are unable to process your application at this stage. We shall be in touch for other openings.</p>
                <p>All the best,</p>
                <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  const pendingbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Pending",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Status Updated Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  const changestatus = async (e, data) => {
    if (e == "Accept") {
      acceptbtn(data);
    } else if (e == "Reject") {
      rejectbtn(data);
    } else {
      pendingbtn(data);
    }
  };
  return (
    <div className="adminProfile paddingLeft100 paddingRight100">
      <DashHead
        head="Vendors"
        desc="View and update profiles of Vendors"
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => {
              searchvalue(e.target.value);
            }}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>Vendor Name</th>
                <th>Location</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        {data.vendor_info !== null ? (
                          <h1>{data.vendor_info.location}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>

                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>
                      <td>
                        {data.status == "Success" ? (
                          <p className="hiringActive px-2 text-sm rounded-full text-center">
                            Processing
                          </p>
                        ) : data.status == "Reject" ? (
                          <p className="rejectstaus px-2 text-sm rounded-full text-center">
                            On Hold
                          </p>
                        ) : data.status == "" || data.status == "Pending" ? (
                          <p className="contracted px-2 text-sm rounded-full text-center">
                            Pending
                          </p>
                        ) : data.status == "Mail send" ? (
                          <p className="processingstaus px-2 text-sm rounded-full text-center">
                            Reviewed
                          </p>
                        ) : (
                          <p className="hiringActive px-2 rounded-full">-</p>
                        )}
                      </td>
                      <td>
                        {/* <select
                          className="profileselect"
                          onClick={(e) => {
                            changestatus(e.target.value, data);
                          }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Accept">Accept</option>
                          <option value="Reject">Reject</option>
                        </select> */}
                        <div className="flex gap-2 items-center">
                          <button
                            className="saveinterview"
                            onClick={() => {
                              dispatch(
                                storeAction.singlevendorHander({
                                  singlevendor: [data],
                                })
                              );
                              navigate("/candidatelist");
                            }}
                          >
                            Candidate
                          </button>
                          {data.status == "Success" ||
                          data.status == "Mail send" ? null : loadingnew ==
                            false ? (
                            <button
                              className="acceptbtn"
                              onClick={() => {
                                acceptbtn(data);
                              }}
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            selectid == data.id && (
                              <button className="acceptbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {loadingnew1 === false ? (
                            <button
                              className="rejectbtn"
                              onClick={() => {
                                rejectbtn(data);
                              }}
                            >
                              X
                            </button>
                          ) : (
                            selectid == data.id && (
                              <button className="rejectbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          <button
                            className="saveprofile"
                            onClick={() => {
                              dispatch(
                                storeAction.singlevendorHander({
                                  singlevendor: [data],
                                })
                              );
                              navigate("/adminvendorview");
                            }}
                          >
                            Profile
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default Vendorlist;

/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login";
import OnBoard from "../pages/OnBoard";
import Signup from "../pages/Signup";
import Verification from "../pages/Verification";
import Pricing from "../pages/Pricing";
import Registration from "../pages/Registration";
import OnBoardMeeting from "../pages/OnBoardMeeting";
import ForgotPassword from "../pages/ForgotPassword";
import DashBoard from "../pages/DashBoard";
import Discover from "../pages/Discover";
import Interview from "../pages/Interview";
import Contract from "../pages/Contract";
import Billing from "../pages/Billing";
import { Resetpassword } from "../pages/Resetpassword";
import Profile from "../pages/Profile";
import Emailverification from "../Components/Reusable/Verification/Emailverification";
import Upgrade from "../pages/Upgrade";
import AdminCustomerProfile from "../pages/AdminCustomerProfile";
import AClientProfile from "../pages/AClientProfile";
import ACandidateProfile from "../pages/ACandidateProfile";
import VendorCandidateprofile from "../pages/VendorCandidateprofile";
import AdminContractCards from "../pages/AdminContractCards";
import AdminTeamMember from "../pages/AdminTeamMember";
import AdminHomePage from "../pages/AdminHomePage";
import AdminSignUp from "../pages/AdminSignUp";
import { useSelector } from "react-redux";
import ErrorPage from "../pages/Error";
import Preview from "../pages/Preview";
import NewClientFlow from "../Components/NewClientFlow/NewClientFlow";
import ShareProfile from "../pages/ShareProfile";
import Onboardcandidate from "../pages/Onboardcandidate";
import NewCandidateFlow from "../Components/NewCandidateFlow/NewCandidateFlow";
import NewflowLogin from "../Components/AdminScreen/AdminLogin/NewflowLogin";
import NewClientFlowtable from "../Components/NewClientFlow/NewClientFlowtable";
import Contactlist from "../pages/Contactlist";
import Newclientpassword from "../Components/AdminScreen/AdminLogin/Newclientpassword";
import Register from "../Components/Vendor/Register";
import Dashboard from "../Components/Vendor/Dashboard";
import CircularChart from "../Components/Vendor/Progressbar";
import Vendorlist from "../Components/Vendor/Vendorlist";
import CreatePassword from "../Components/Vendor/CreatePassword";
import Candidatelist from "../Components/Vendor/Table/Candidatelist";
import VendorResumeUploadComp from "../Components/Vendor/VendorResumeUpload/VendorResumeUploadComp";
import Candidatedetails from "../Components/Vendor/Table/Candidatedetails";
import Candidatefaq from "../pages/Candidatefaq";
import Clientfaq from "../pages/Clientfaq";
import Bulkemail from "../pages/Bulkemail";
import Test from "../pages/Test";
import Seacrh from "../pages/Search";
import VideoUpload from "../pages/VideoUpload";
import VendorCandidate from "../Components/Vendor/VendorCandidate";
import CandidateInterview from "../pages/CandidateInterview";
import Construction from "../pages/Construction";
import HireMainComponent from "../Components/HireTeamModule/HireMainComponent/HireMainComponent";
import Wallet from "../pages/Wallet";
import WalletModule from "../Components/Vendor/Wallet/WalletModule";
import AVendorProfileview from "../Components/Vendor/AVendorProfileview/AVendorProfileview";
import Ats from "../Components/AdminScreen/ATS/Ats";
import Chatgpt from "../pages/Chatgpt";
import Techassessment from "../pages/Techassessment";
import CoursesPage from "../pages/CoursesPage";
import Sendmail from "../pages/Sendmail";

const Routing = () => {
  const loginrole = useSelector((store) => store.loginrole);
  return (
    <div>
      <Routes>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/test" element={<Test />} />
        <Route path="/sendmail" element={<Sendmail />} />
        <Route path="/chatgpt" element={<Chatgpt />} />
        <Route path="/search" element={<Seacrh />} />
        <Route path="/videoupload" element={<VideoUpload />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/vendorwallet" element={<WalletModule />} />
        <Route path="/construction2" element={<Construction />} />
        <Route path="/construction3" element={<Construction />} />
        <Route path="/construction4" element={<Construction />} />

        {loginrole == "2" && (
          <>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/meeting" element={<OnBoardMeeting />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/interview" element={<Interview />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/clientfaq" element={<Clientfaq />} />
            <Route path="/hireTeam" element={<HireMainComponent />} />
            <Route path="/wallet" element={<Wallet />} />
          </>
        )}
        {loginrole == "3" && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/preview" element={<Preview />} />
            <Route path="/candidatefaq" element={<Candidatefaq />} />
            <Route
              path="/candidateinterview"
              element={<CandidateInterview />}
            />
            <Route path="/techassessment" element={<Techassessment />} />
            <Route path="/courses" element={<CoursesPage />} />
          </>
        )}

        {loginrole == "1" && (
          <>
            <Route path="/adminteam" element={<AdminTeamMember />} />
            <Route path="/admincontracts" element={<AdminContractCards />} />
            <Route path="/adminclientview" element={<AClientProfile />} />
            <Route path="/adminvendorview" element={<AVendorProfileview />} />

            <Route path="/admincandidateview" element={<ACandidateProfile />} />
            <Route
              path="/vendorcandidateprofile"
              element={<VendorCandidateprofile />}
            />
            <Route path="/adminhome" element={<AdminHomePage />} />
            <Route path="/customerProfile" element={<AdminCustomerProfile />} />
            <Route path="/onboardcandidate" element={<Onboardcandidate />} />
            <Route path="/onboardclient" element={<NewClientFlowtable />} />
            <Route path="/contact" element={<Contactlist />} />
            <Route path="/vendorlist" element={<Vendorlist />} />
            <Route path="/bulkemail" element={<Bulkemail />} />
            <Route path="/candidatelist" element={<VendorCandidate />} />
            <Route path="/ats" element={<Ats />} />
          </>
        )}
        {loginrole == "7" && (
          <>
            <Route path="/onboardcandidate" element={<Onboardcandidate />} />
            <Route path="/ats" element={<Ats />} />
            <Route path="/admincandidateview" element={<ACandidateProfile />} />
            <Route path="/customerProfile" element={<AdminCustomerProfile />} />
          </>
        )}
        {loginrole == "5" && (
          <>
            <Route path="/adminteam" element={<AdminTeamMember />} />
            <Route path="/admincontracts" element={<AdminContractCards />} />
            <Route path="/adminclientview" element={<AClientProfile />} />
            <Route path="/admincandidateview" element={<ACandidateProfile />} />
            <Route
              path="/vendorcandidateprofile"
              element={<VendorCandidateprofile />}
            />
            <Route path="/bulkemail" element={<Bulkemail />} />
            <Route path="/adminhome" element={<AdminHomePage />} />
            <Route path="/customerProfile" element={<AdminCustomerProfile />} />
            <Route path="/onboardcandidate" element={<Onboardcandidate />} />
            <Route path="/onboardclient" element={<NewClientFlowtable />} />
            <Route path="/contact" element={<Contactlist />} />
          </>
        )}
        {loginrole == "6" && (
          <>
            <Route path="/vendordashboard" element={<Dashboard />} />
            <Route path="/vendorcandidatelist" element={<Candidatelist />} />

            <Route
              path="/vendoruploadresume"
              element={<VendorResumeUploadComp />}
            />
            <Route path="/candidatedetails" element={<Candidatedetails />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/error" replace />} />
        {/* <Route path="*" element={<Navigate to="/error" replace />} /> */}
        <Route path="/" element={<OnBoard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/verification/:id" element={<Verification />} /> */}
        {/* <Route path="/emailverification" element={<Emailverification />} /> */}
        <Route path="/registration" element={<Registration />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/adminsignUp/:email" element={<AdminSignUp />} />
        <Route path="/createpassword/:email" element={<NewflowLogin />} />
        <Route path="/clientpassword/:email" element={<Newclientpassword />} />
        <Route path="/vendorpassword/:email" element={<CreatePassword />} />
        <Route path="/clientregister" element={<NewClientFlow />} />
        <Route path="/candidateregister" element={<NewCandidateFlow />} />
        <Route path="/vendorregister" element={<Register />} />
        <Route path="/share/:token" element={<ShareProfile />} />
        <Route path="/CircularChart" element={<CircularChart />} />
      </Routes>
    </div>
  );
};

export default Routing;

/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashHead from "../Components/Reusable/DashBoardReusable/DashHead/DashHead";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { storeAction } from "../Store/Store";
import back from "../assests/billingX.png";
import moment from "moment";
import { FiLoader } from "react-icons/fi";

const CandidateInterview = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const userdata = useSelector((store) => store.userdata);
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const [interviewdata, setinterviewdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [singledata, setsingledata] = useState([]);
  const [selecttime, setselecttime] = useState("");
  const [interviewloader, setinterviewloader] = useState(false);
  const [errormsg, seterrormsg] = useState(false);

  useEffect(() => {
    GetcandidateInterview();
  }, []);

  const GetcandidateInterview = async () => {
    setloading(true);
    try {
      const [firstResponse, secondResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/get/client/interview/${userid}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/client/${userid}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        ),
      ]);

      const firstAPIData = firstResponse.data.map((item) => ({
        ...item,
        source: "client_interview",
      }));

      const secondAPIData = secondResponse.data.map((item) => ({
        ...item,
        source: "hat_interview",
      }));

      const interviewdata = [...firstAPIData, ...secondAPIData];

      setinterviewdata(interviewdata);
    } catch (error) {
      setinterviewdata([]);
    } finally {
      setloading(false);
    }
  };

  const viewinterview = (data) => {
    if (data.status == "Accepted") {
      setselecttime(data.time);
    } else {
      setselecttime("");
    }
    dispatch(storeAction.isPopUpHander("ViewInterview"));
    setsingledata([data]);
  };

  const acceptbtn = async () => {
    if (selecttime.length === 0) {
      seterrormsg(true);
      return;
    }

    seterrormsg(false);
    setinterviewloader(true);

    const obj = {
      status: "Accepted",
      time: selecttime,
    };

    const newobj = {
      status: "false",
      on_type: "Interview Accepted",
      message: `The Interview Date & Time accepted by Candidate ${userdata[0].first_name}`,
    };

    try {
      if (singledata[0].source === "client_interview") {
        await axios.put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/update/interview/${singledata[0].id}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
      } else if (singledata[0].source === "hat_interview") {
        await axios.put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/hat-interview/${singledata[0].id}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userdata[0].id}/`,
          newobj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Error sending notification:", error.response || error);
      }

      await GetcandidateInterview();

      setTimeout(() => {
        setinterviewloader(false);
        setselecttime("");
        dispatch(storeAction.isPopUpHander());
      }, 3000);
    } catch (error) {
      setinterviewloader(false);
    }
  };

  return (
    <>
      <div className="adminProfile paddingLeft50 paddingRight50">
        <DashHead
          head="Interview"
          desc="View and update clients Interview"
          descClass="dashBoardMainHeadDescBetween"
        />
        <div>
          <div className="AdminClientProfileComp">
            <div className="AdminClientProfileCompTable">
              <table className="AdminClientTable">
                <thead>
                  <tr className="AdminTableHead">
                    <th>Name</th>
                    <th>Comapany Name</th>
                    <th>Preferred Date & Time</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading == false ? (
                    interviewdata.length !== 0 ? (
                      interviewdata
                        .map((data) => {
                          const now = new Date();
                          const dateTimes = data.datetime
                            .split(";")
                            .flatMap((item) => item.split(", "));
                          const futureDates = dateTimes.filter(
                            (dateTimeStr) => new Date(dateTimeStr) > now
                          );
                          if (futureDates.length > 0) {
                            return {
                              ...data,
                              datetime: futureDates.join(", "),
                            };
                          } else {
                            return null;
                          }
                        })
                        .filter((data) => data !== null)
                        .map((data, index) =>
                          data.client_data !== undefined &&
                          data.status !== "false" ? (
                            <tr className="adminTableRow" key={index}>
                              <td>
                                <div className="tableName">
                                  {data.client_data.profile_picture.length !==
                                  0 ? (
                                    <img
                                      src={data.client_data.profile_picture}
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      name={data.client_data.first_name}
                                      size={30}
                                      round="50px"
                                    />
                                  )}
                                  <h1>{data.client_data.first_name}</h1>
                                </div>
                              </td>
                              {data.client_data.company !== null ? (
                                <td>{data.client_data.company.company_name}</td>
                              ) : (
                                <td>-</td>
                              )}
                              <td>
                                {data.time.length !== 0
                                  ? `${moment(data.time).format(
                                      "DD-MM-YYYY"
                                    )} ${moment(data.time).format("h:mm A")}`
                                  : "-"}
                              </td>
                              <td>
                                {data.status !== "Accepted" ? (
                                  <p className="status rejectstaus">
                                    Not Accepted
                                  </p>
                                ) : (
                                  <p className="status hiringActive">
                                    Accepted
                                  </p>
                                )}
                              </td>

                              <td>
                                <button
                                  className="viewButton"
                                  onClick={() => {
                                    viewinterview(data);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ) : null
                        )
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className="flex justify-center py-5">
                            <h6>No data found...</h6>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="flex justify-center py-5">
                          <FiLoader className="loadingIconHAT" />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isPopUp == "ViewInterview" && (
        <div className="interViewDetailOverlay">
          <div className="interViewDetailOverlayHead">
            {singledata.length !== 0 ? (
              singledata[0].status !== "Accepted" ? (
                <h1>Select Preferred Date & Time</h1>
              ) : (
                <h1>Selected Date & Time</h1>
              )
            ) : null}
            <img
              src={back}
              alt=""
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
                setselecttime("");
              }}
            />
          </div>
          {singledata.length !== 0 ? (
            <div className="interViewDetailOverlayContent">
              <div className="flex gap-4 items-center justify-start py-5 px-4">
                {singledata[0].datetime.length !== 0 &&
                  singledata[0].datetime.split(",").length !== 0 &&
                  singledata[0].datetime.split(",").map((data, index) => {
                    const formattedData = data.trim();
                    const isSelected = selecttime === formattedData;

                    return (
                      <div
                        key={index}
                        className={`p-2 border text-sm font-medium rounded cursor-pointer ${
                          isSelected
                            ? "border-2 text-[#1D37E7] border-[#1D37E7]"
                            : ""
                        }`}
                        onClick={
                          singledata[0].status !== "Accepted"
                            ? () => setselecttime(formattedData)
                            : null
                        }
                      >
                        {moment(formattedData).format("DD-MM-YYYY")} -{" "}
                        {moment(formattedData).format("h:mm A")}
                      </div>
                    );
                  })}
              </div>

              {singledata[0].time.length !== 0 ? (
                <div className="flex gap-4 items-center justify-start px-4">
                  <h2>Preferred Date & Time : </h2>
                  <h3>{singledata[0].time}</h3>
                </div>
              ) : null}

              <div className="flex gap-4 items-center justify-start px-4">
                <h2>Time Zone : </h2>
                <h3>{singledata[0].time_zone}</h3>
              </div>

              <div className="flex gap-4 items-center justify-start px-4">
                <h2>Meeting link : </h2>
                {singledata[0].meeting_url.length ? (
                  <h3
                    onClick={() => {
                      window.open(
                        `${
                          singledata[0].meeting_url.length !== 0
                            ? singledata[0].meeting_url
                            : null
                        }`,
                        "_blank"
                      );
                    }}
                    className="cursor-pointer"
                  >
                    {singledata[0].meeting_url.length !== 0
                      ? singledata[0].meeting_url
                      : "Not Provided"}
                  </h3>
                ) : (
                  <h3>Not Provided</h3>
                )}
              </div>
              {errormsg && (
                <p className="text-red-500 py-2 font-medium px-4">
                  Please Select Preferred Date & Time
                </p>
              )}
              {singledata[0].status !== "Accepted" ? (
                <div className="mt-5 flex gap-4 justify-end">
                  <button
                    className="savecancel"
                    onClick={() => {
                      dispatch(storeAction.isPopUpHander());
                      setselecttime("");
                    }}
                  >
                    Cancel
                  </button>
                  {interviewloader == true ? (
                    <button className="save">Please wait...</button>
                  ) : (
                    <button className="save" onClick={acceptbtn}>
                      Accept
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default CandidateInterview;
